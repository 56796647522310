import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBenefits, BenefitsResponse } from '@sentara/sentara-api-hooks-core';
import { errorhandler } from 'common';
import { Headings, Loader, Table } from '@sentaraui/optimahealth_web';

const Benefits = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<any>(false);
  const { BenefitsType } = useBenefits();
  const [generalVisit, setGeneralVisit] = useState<BenefitsResponse>();

  async function asyncCall() {
    const result = await BenefitsType();
    if (result?.errorCode) {
      errorhandler(result?.errorCode);
    }else if (result?.data !== null) {
      setGeneralVisit(result.data);
    }
    setLoading(false);
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      await asyncCall();
    })();
  }, []);

  interface BenefitItem {
    key: string;
    value: string;
  }

  const BenefitsValue = (generalVisit: any) => {
    return generalVisit?.map((item: BenefitItem) => (
      <>
        <tr key={`${item.key}`}>
          <td
            data-testid={`${
              item.key.charAt(0).toLowerCase() + item.key.slice(1)
            }`.replace(/\s/g, '')}
          >
            <strong>{item.key}</strong>
          </td>
          <td
            data-testid={`${
              item.key.charAt(0).toLowerCase() + item.key.slice(1)
            }Value`.replace(/\s/g, '')}
          >
            {item.value}
          </td>
        </tr>
      </>
    ));
  };

  const generalVisitsHeader = [
    { text: t('yourPlan.label.generalVisits') },
  ]
  const generalVisitsRows = [
    [{ text: BenefitsValue(generalVisit?.generalVisits) }],
  ]

  const discountedNetworkHeader = [
    { text:  t('yourPlan.label.discountedNetwork')},
  ]
  const discountedNetworkRows = [
    [{ text: BenefitsValue(generalVisit?.discountedNetwork) }],
  ]

  return (
    <>
      {loading && <Loader />}
      <div className="benefits_provider">
        <div>
          <div className="heading-one-semibold mb-4">
            <Headings
              level={1}
              text={t('navigation.label.benefits')}
              dataTestId="benefitsHeader"
            />
          </div>
          {!generalVisit && !loading && (
              <p>{t('noRecords')}</p>
            )}
          {generalVisit?.generalVisits && generalVisit?.generalVisits?.length > 0 ? (
            <div className="benefits-table">
              <Table header={generalVisitsHeader} rows={generalVisitsRows} />
            </div>
          ): null}
          {generalVisit?.discountedNetwork && generalVisit?.discountedNetwork?.length > 0 ? (
            <div className="benefits-table">
            <Table header={discountedNetworkHeader} rows={discountedNetworkRows} />
            </div>
           ): null}
        </div>
      </div>
    </>
  );
};

export default Benefits;