import {
  PROXY_EXPIRED_URL,
  getProxyDetails,
  caregiverEndPoint,

} from 'common';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FooterDisclaimer } from 'organisms';
import {ProxyHeader} from 'templates';
import { GlobalContext } from 'context';
import { PageBox, Auth } from '@sentara/caregiver';

const ProxyAuth = (): React.JSX.Element => {
  const {  getAuthToken } = useContext(GlobalContext);
  const navigate = useNavigate();
  //find url
  let search = window.location.search;

  let newParams = new URLSearchParams(search);

  let keyValueUrl = newParams.get(getProxyDetails.key);

  useEffect(() => {
    sessionStorage.removeItem(getProxyDetails.proxyAuthorizationData);
    if (!keyValueUrl) {
      navigate(PROXY_EXPIRED_URL);
    }
  }, []);
  return (
    <>
      <ProxyHeader />
      <div className="container-fluid">
        <div className="proxy_form_wrapper">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <PageBox className="mb-6" border={false}>
                {keyValueUrl && (
                  <Auth
                    KEY={keyValueUrl}
                    caregiverBaseURL={caregiverEndPoint}
                    authToken={
                      getAuthToken || ''
                    }
                    OcpApimSubscriptionKey={
                      process.env.REACT_APP_SUBSCRIPTION_KEY || ""
                    }
                    callBack={(type: any, result: any) => {
                      if(type === getProxyDetails.expiredMessage){
                        navigate(PROXY_EXPIRED_URL);
                      } else {
                        navigate(`/${type}`);
                        sessionStorage.setItem(
                          getProxyDetails.proxyAuthorizationData,
                          btoa(JSON.stringify(result))
                        );
                      }
                    }}
                  />
                )}
              </PageBox>
            </div>
          </div>
        </div>
      </div>
      <hr className="border-w-2 mt-5 mb-2" />
      <footer className="d-none d-lg-block d-md-block mt-auto pt-4">
        {/* Component to display the footer disclaimer */}
        <FooterDisclaimer />
      </footer>
    </>
  );
};

export default ProxyAuth;
