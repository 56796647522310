import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from 'context';
import { useUpdatePlan, useContactus } from '@sentara/sentara-api-hooks-core';
import {HamburgerMenu} from 'organisms';
import LogoImg from '../../assets/images/sentara_logo.svg';
import {
  Navigate,
  memberHome,
  Proxy,
  setLocalStorageBoolean,
  getLocalStorageBoolean,
  apiResponseSafeCheck,
  setEncryptedValue,
  errorhandler,
  isGuest,
  contactUsAnonymous,
} from 'common';
import {
  ButtonLink,
  Image,
  MailIcon,
  PlanTypeDropdown,
  TopNav,
  SearchIcon,
  Link,
  InputField
} from '@sentaraui/optimahealth_web';

export const HeaderTopNav = () => {
  const [guestValue, setGuestValue] = useState<boolean>(false);

  const { t } = useTranslation();
  useEffect(() => {
    (async () => {
      const guestValue: boolean = await isGuest();
      setGuestValue(guestValue);
    })();
  }, []);

  return (
    <div>
      <TopNav
        topLinks={[
          {
            dataTestId: 'navLinkProfile',
            label: t('header.label.myOptimaAccount'),
            url: `${!guestValue ? '/dashboard' : ''}`,
          },
          {
            dataTestId: 'navLinkMembers',
            label: t('header.label.memberHome'),
            url: memberHome,
          },
        ]}
      />
    </div>
  );
};

interface ChoosePlanTypeProps {
  selectedplanType: string;
  planList: any;
  setSelectedplanType: (response: any) => void;
  memberID: string;
}

export const ChoosePlanType = ({
  selectedplanType,
  planList,
  setSelectedplanType,
  memberID,
}: ChoosePlanTypeProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setIsDesignatedRepresentative, isDesignatedRepresentative, setUserEncodedData,setIsGuestSwitch, proxyMemberId, setProxyMemberId } = useContext(GlobalContext);
  const DesignatedDropdownLink: boolean = getLocalStorageBoolean(Proxy?.DesignatedDropdownLink);
  let proxyFor = proxyMemberId || '';
  let updatedPlanList:Array<string> =[];
  if(proxyFor !== '' || isDesignatedRepresentative) {
    setSelectedplanType(`${t('yourPlan.label.proxyAuthorization')}`?.toUpperCase());
    setLocalStorageBoolean(Proxy?.DesignatedRepresentative, true);
  }
 updatedPlanList = DesignatedDropdownLink ?  [...planList, {planName: `${t('yourPlan.label.proxyAuthorization')}`?.toUpperCase(), planID: "", memberID:""}] : [...planList];
  const updatePlan = (e: { planName: string; planID: string | number, memberID: string | number }) => {
  setLocalStorageBoolean(Proxy?.DesignatedRepresentative, false)
  setEncryptedValue(Proxy.MemberId,"");
   setProxyMemberId('')
   setUserEncodedData([]);
    setEncryptedValue(Proxy.memberProxy,"");
    setIsGuestSwitch(false);
    setSelectedplanType(e?.planName);
    if (
      e?.planID === '' &&
      e?.memberID === '' &&
      e?.planName === `${t('yourPlan.label.proxyAuthorization')}`?.toUpperCase()
    ) {
      setIsDesignatedRepresentative(true);
      setLocalStorageBoolean(Proxy?.DesignatedRepresentative, true);
      navigate(Navigate.dashboard);
    } else if (
      e?.planID === '' &&
      e?.memberID === '' &&
      e?.planName === t('yourPlan.label.guest')
    ) {
      setIsGuestSwitch(true);
      setIsDesignatedRepresentative(false);
      setLocalStorageBoolean(Proxy?.DesignatedRepresentative, false);
      navigate(Navigate.enrollBenefits);
    } else {
      setIsDesignatedRepresentative(false);
      setLocalStorageBoolean(Proxy?.DesignatedRepresentative, false);
      let postDate = {
        planId: e?.planID,
        Id: memberID,
      };
      updateplan(postDate);
    }
  };
  const { UpdatePlanType } = useUpdatePlan();
  const updateplan = async (postDate: any) => {
    const resp = await UpdatePlanType(postDate);
    setEncryptedValue(Proxy.MemberId,"");
    setProxyMemberId('')
    if (resp?.data?.memberId !== '') window.location.href = Navigate.dashboard;
  };

  return (
    <PlanTypeDropdown
      labeltext={t('header.label.currentPlan')}
      buttonlabel={selectedplanType}
      dropdownlistitems={updatedPlanList}
      onSelect={(e: any) => updatePlan(e)}
      url="#"
    />
  );
};

interface ContactUsLinkProps {
  showIcon: boolean;
  memberID?: boolean;
  onItemClick?: () => void;
}

export const ContactUsLink = ({
  showIcon,
  memberID,
  onItemClick,
}: ContactUsLinkProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { ContactusType } = useContactus();
  const [contactUsResponse, setContactUsResponse] = useState<any>({});
  const [memberIdValue, setMemberIdValue] = useState<string>('');
  const [emailIdValue, setEmailIdValue] = useState<string>('');
  const { getFeature, getAuthToken } = useContext(GlobalContext);

  useEffect(() => {
    (async () => {
      const isGuestUser: boolean = await isGuest();
      if (!isGuestUser && isGuestUser !== undefined && apiResponseSafeCheck(getAuthToken)) {
        contactUs();
      }
    })();
  }, [getAuthToken]);

  const contactUs: () => Promise<void> = async () => {
    const response = await ContactusType(false);
    if (response?.data) {
      setContactUsResponse(response?.data || '');
      setMemberIdValue(contactUsResponse?.memberId || ''); // Initialize memberIdValue
      setEmailIdValue(contactUsResponse?.emailId || ''); // Initialize emailIdValue
    } else {
      errorhandler(response?.errorCode);
      setContactUsResponse('');
    }
  };

  // Redirect to Oracle contact us page
  const redirectToContact = (e: Event) => {
    // Prevent the default action of the event
    e.preventDefault();

    // Check if ContactusSalesForce property of getFeature is falsy
    if (!getFeature?.ContactusSalesForce) {
      // If it's falsy, find the form with id 'contactUS' and submit it
      const form = document.getElementById('contactUS') as HTMLFormElement;
      form.submit();
    } else {
      navigate(Navigate.homeContactUs);
      onItemClick?.();
    }
  };

  return (
    <>
      <div className="dark-gray-color">
        {!memberID ? (
          <Link
            to={contactUsAnonymous}
            className="link-text mw-auto py-2 btn-links text-decoration-none d-flex align-items-center gap-2"
            data-testid="contactUsButton"
            target="_blank"
            rel="noreferrer"
            onClick={() => onItemClick && onItemClick()}
          >
            <span className="me-2 d-flex align-items-">
              {showIcon ? <MailIcon width={16} height={16} /> : null}
            </span>
            {t('header.label.contactUs')}
          </Link>
        ) : (
          <ButtonLink
            buttonText={t('header.label.contactUs')}
            dataTestId="contactUsButton"
            isHeader
            icon={showIcon ? <MailIcon width={16} height={16} /> : null}
            onClick={redirectToContact}
          />
        )}
      </div>
      {/* contact us form*/}
      <form
        action={contactUsResponse?.url}
        method="post"
        id="contactUS"
        target="_blank"
        data-testid="contactUS"
      >
        <InputField type="hidden" name="memberid" value={memberIdValue} />
        <InputField type="hidden" name="email" value={emailIdValue} />
        <InputField type="hidden" value="submit" />
      </form>
    </>
  );
};

export const SearchLink = () => {
  return <SearchIcon />;
};

interface HamburgerMenuLinkProps {
  showLogout: boolean;
}
export const HamburgerMenuLink = ({ showLogout }: HamburgerMenuLinkProps) => {
  return <HamburgerMenu showLogout={showLogout} />;
};
export const HeaderLogo = () => {
  const {  getAuthToken } = useContext(GlobalContext);  
  const { t } = useTranslation();
  return (
    <Link
      className="logo-img"
      to={
        getAuthToken === ''
          ? Navigate.memberHome
          : Navigate.dashboard
      }
      onClick={()=>{
       setEncryptedValue(Proxy.MemberId,"");
      }}
      data-testid="optimaLogoLink"
    >
      <Image
        dataTestId={'optimaLogoImage'}
        path={LogoImg}
        alttext={t('optimaHealthLogo')}
      />
    </Link>
  );
};