import React from 'react';
import {
  HeaderLogo,
  ContactUsLink,
  SearchLink,
  HamburgerMenuLink,
} from 'organisms';

const ProxyHeader = (): React.JSX.Element => {
  return (
    <>
      <header>
        <div className="">
          <div className="header-wrapper">
            <nav className="header-inner">
              <div>
                <HeaderLogo />
              </div>
              <div>
                <div className="d-flex flex-no-wrap align-items-center">
                  <div className="d-none d-xl-block d-lg-block d-md-none ms-4">
                    <ContactUsLink showIcon />
                  </div>
                  <div className="d-lg-none d-md-block d-sm-block">
                    <SearchLink />
                  </div>
                  <div className="d-lg-none d-md-block d-sm-block">
                    <HamburgerMenuLink showLogout />
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
        <hr className="border-w-2 mt-0" />
      </header>
    </>
  );
};

export default ProxyHeader;
