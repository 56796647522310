import { useTranslation } from 'react-i18next';

function SummaryTable({ summaryDetails }: any) {
  const { t } = useTranslation();
  return (
    <table className="table table-custom-style mb-3">
      <tbody>
        <tr className="rows">
          <td data-testid="memberName" className="redhat_bold fw-700">
            {t('claimsDetails.label.memberName')}
          </td>
          <td data-testid="memberNameValue">{summaryDetails?.memberName}</td>
        </tr>
        <tr className="rows">
          <td data-testid="memberId" className="redhat_bold fw-700">
            {t('claimsDetails.label.memberId')}
          </td>
          <td data-testid="memberIdValue">{summaryDetails?.memberId}</td>
        </tr>
        <tr className="rows">
          <td data-testid="totalCharges" className="redhat_bold fw-700">
            {t('claimsDetails.label.totalCharges')}
          </td>
          <td data-testid="totalChargesValue">
            {summaryDetails?.totalRequirement &&
              `$${summaryDetails?.totalRequirement}`}
          </td>
        </tr>
        <tr className="rows">
          <td className="redhat_bold fw-700" data-testid="totalCovered">
            {t('claimsDetails.label.totalCovered')}
          </td>
          <td data-testid="totalCoveredValue">
            {summaryDetails?.totalCovered && `$${summaryDetails?.totalCovered}`}
          </td>
        </tr>
        <tr className="rows">
          <td className="redhat_bold fw-700" data-testid="totalNotCovered">
            {t('claimsDetails.label.totalNotCovered')}
          </td>
          <td data-testid="totalNotCoveredValue">
            {summaryDetails?.totalNotCovered &&
              `$${summaryDetails?.totalNotCovered}`}
          </td>
        </tr>
        <tr className="rows">
          <td className="redhat_bold fw-700" data-testid="totalDeductible">
            {t('claimsDetails.label.totalDeductible')}
          </td>
          <td data-testid="totalDeductibleValue">
            {summaryDetails?.totalDeduction &&
              `$${summaryDetails?.totalDeduction}`}
          </td>
        </tr>
        <tr className="rows">
          <td
            className="redhat_bold fw-700"
            data-testid="totalCopayCoinsurance"
          >
            {t('claimsDetails.label.totalCopayCoinsurance')}
          </td>
          <td data-testid="totalCopayCoinsuranceValue">
            {summaryDetails?.totalCopay && `$${summaryDetails?.totalCopay}`}
          </td>
        </tr>
        <tr className="rows">
          <td className="redhat_bold fw-700" data-testid="totalPaidByPlan">
            {t('claimsDetails.label.totalPaidByPlan')}
          </td>
          <td data-testid="totalPaidByPlanValue">
            {summaryDetails?.totalPaid && `$${summaryDetails?.totalPaid}`}
          </td>
        </tr>
        <tr className="rows">
          <td className="redhat_bold fw-700" data-testid="memberResponsibility">
            {' '}
            {t('claimsDetails.label.memberResponsibility')}
          </td>
          <td data-testid="memberResponsibilityValue">
            {summaryDetails?.totalResponsibility &&
              `$${summaryDetails?.totalResponsibility}`}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default SummaryTable;
