import {OpenKitBuilder, OpenKit }  from '@dynatrace/openkit-js';
import { Device } from '@capacitor/device';

// Helper function to handle session and action
const handleSessionAndAction = (openKit: OpenKit): void => {
const session = openKit.createSession();
  const actionName = window.location.pathname;
  const action = session.enterAction(actionName);
  action.leaveAction();
  session.end();
  openKit.shutdown();
}

// function to initialize dynatrace call
export const DynaTraceInit = ()=>{
   type DeviceInfo = {
    operatingSystem: string;
    model: string;
    osVersion: string;
  };

  // get device info from local storage
  const deviceInfo = JSON.parse(localStorage.getItem('deviceInfo') as string);
  const logDeviceInfo = async () => {
    const response: DeviceInfo  = await Device.getInfo();
    localStorage.setItem('deviceInfo', JSON.stringify(response));
  };
  if(deviceInfo == null){
    logDeviceInfo();
  }
  // get device id from local storage
  let deviceId:string = localStorage.getItem('deviceid') ?? '';
  if(localStorage.getItem('deviceid') === null){
      localStorage.setItem('deviceid', (Math.floor(Math.random() * (9999999999 - 111111111 + 1)) + 111111111).toString());
  }

  const endpointURL:string = "https://rum.sentara.com:9999/mbeacon/4b8fe120-53d1-4ba2-960a-a28270dcab7b"; // the endpointURL can be found in the Dynatrace UI
    const applicationID:string = process.env.REACT_APP_DYNATRACE_APPID ?? ''; // the application id can be found in the Dynatrace UI process.env.REACT_APP_Dynatrace_applicationId
    const deviceID:string =  deviceId; // an ID that uniquely identifies the device
     if(deviceInfo !== null){
      const openKit = new OpenKitBuilder(endpointURL, applicationID, deviceID)
      .withOperatingSystem(deviceInfo?.operatingSystem)
      .withScreenResolution(window.screen.width, window.screen.height)
      .withManufacturer(deviceInfo?.model)
      .withModelId(deviceInfo?.osVersion)
      .build();
      const timeoutInMilliseconds = 10 * 1000;

      openKit.waitForInit((success) => {
          if (success) {
              handleSessionAndAction(openKit);
          }
      }, timeoutInMilliseconds);
  }
}