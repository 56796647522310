import { UserDetails, signIn, deleteEncryptedValue } from 'common';
import { useContext } from 'react';
import { GlobalContext } from 'context';
import { useUserLogout } from '@sentara/sentara-api-hooks-core';

const SignOutHandler = () => {
const { clearContextValues }   = useContext(GlobalContext);
const { userLogout } = useUserLogout();
const signOut = async () => {  
   await userLogout();
    deleteEncryptedValue();
    document.cookie = UserDetails.optimaPersonCookie + '=';
    document.cookie = UserDetails.optimaRoleCookie + '=';
    localStorage.clear();
    clearContextValues();
    window.location.href = signIn;
};

  return signOut;
};

export default SignOutHandler;
