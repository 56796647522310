import React, { useContext,  useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { caregiverEndPoint, PROXY_AUTHENTICATION, PROXY_EXPIRED_URL, PROXY_REGISTER_URL, getProxyDetails } from 'common';
import ProxyHeader from './ProxyHeader';
import { FooterDisclaimer } from 'organisms';
import { GlobalContext } from 'context';
import {PageBox, CSRProxy } from '@sentara/caregiver';


const ProxyApprove = (): React.JSX.Element => {
  const [memberDetails, setMemberDetails] = useState<any>(null);
  const {getAuthToken}   = useContext(GlobalContext);
  let serverMemberDetails: any;
  const expirePage = PROXY_EXPIRED_URL;
  const navigate = useNavigate();
  const sessionData = sessionStorage.getItem(getProxyDetails.proxyAuthorizationData);
  useEffect(() => {
    if (sessionData) {
      serverMemberDetails = JSON.parse(atob(sessionData));
      setMemberDetails(serverMemberDetails);
      if (serverMemberDetails.CurrentStatus === '2') {
        navigate(PROXY_REGISTER_URL);
        return;
      } else if (serverMemberDetails.CurrentStatus !== '1') {
        navigate(expirePage);
        return;
      }
    } else {
      navigate(expirePage);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div className="d-flex flex-column h-100">
      <ProxyHeader />
      <div className="flex-1">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-8 col-sm-12 col-12">
              <div className="proxy_form proxy_form_disabled proxy_revoke_wrapper">
                <PageBox border={false}>
                  {memberDetails && (
                    <CSRProxy
                      KEY={memberDetails.ID}
                      callBack={(type: string) => {
                        if ([getProxyDetails.updated, getProxyDetails.deny].includes(type)) {
                          sessionStorage.removeItem(getProxyDetails.proxyAuthorizationData);
                        } else if (type === getProxyDetails.canceled) {
                          sessionStorage.removeItem(getProxyDetails.proxyAuthorizationData);
                          navigate(`${PROXY_AUTHENTICATION}?${getProxyDetails.key}=${memberDetails.ID}`);
                        }
                      }}
                      caregiverBaseURL={caregiverEndPoint}
                      authToken={
                        getAuthToken || ''
                      }
                      baseURL={process.env.REACT_APP_BASE_URL || ""}
                      OcpApimSubscriptionKey={
                        process.env.REACT_APP_SUBSCRIPTION_KEY || ""
                      }
                    />
                  )}
                </PageBox>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer section */}
      <footer className="d-none d-lg-block d-md-block mt-auto pt-4">
        {/* Component to display the footer disclaimer */}
        <FooterDisclaimer />
      </footer>
    </div>
  );
};
export default ProxyApprove;
