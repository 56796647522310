import React from 'react';
export const HamburgerIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 4.75H17M3 10H17M3 15.25H17"
      stroke="#333131"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SearchIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 17L12.3333 12.3333M13.8889 8.44444C13.8889 11.4513 11.4513 13.8889 8.44444 13.8889C5.43756 13.8889 3 11.4513 3 8.44444C3 5.43756 5.43756 3 8.44444 3C11.4513 3 13.8889 5.43756 13.8889 8.44444Z"
      stroke="#333131"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const UserIconBlack = () => (
  <svg
    width="11"
    height="14"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.1111 4.11111C10.1111 5.82933 8.71822 7.22222 7 7.22222C5.28178 7.22222 3.88889 5.82933 3.88889 
      4.11111C3.88889 2.39289 5.28178 1 7 1C8.71822 1 10.1111 2.39289 10.1111 4.11111Z"
      stroke="#171717"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 9.55556C3.99312 9.55556 1.55556 11.9931 1.55556 15H12.4444C12.4444 11.9931 10.0069 9.55556 7 9.55556Z"
      stroke="#171717"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const UserIconWhite = () => (
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 8C4.78125 8 3 6.21875 3 4C3 1.8125 4.78125 0 7 0C9.1875 0 11 1.8125 11 4C11 6.21875 9.1875 
      8 7 8ZM5.5625 9.5H8.40625C11.5 9.5 14 12 14 15.0938C14 15.5938 13.5625 16 13.0625 
      16H0.90625C0.40625 16 0 15.5938 0 15.0938C0 12 2.46875 9.5 5.5625 9.5Z"
      fill="white"
    />
  </svg>
);
export const CloseIconBlock = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.341709 0.341709C0.79732 -0.113903 1.53601 -0.113903 1.99162 0.341709L7 5.35008L12.0084 
      0.341709C12.464 -0.113903 13.2027 -0.113903 13.6583 0.341709C14.1139 0.79732 14.1139 
      1.53601 13.6583 1.99162L8.64991 7L13.6583 12.0084C14.1139 12.464 14.1139 13.2027 13.6583 
      13.6583C13.2027 14.1139 12.464 14.1139 12.0084 13.6583L7 8.64991L1.99162 13.6583C1.53601 14.1139 
      0.79732 14.1139 0.341709 13.6583C-0.113903 13.2027 -0.113903 12.464 0.341709 12.0084L5.35008 7L0.341709 
      1.99162C-0.113903 1.53601 -0.113903 0.79732 0.341709 0.341709Z"
      fill="#333131"
    />
  </svg>
);
export const MailIcon = () => (
  <svg
  width={18}
  height={18}
  viewBox="0 0 48 37"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  >
    <path
     d="M0 6.5C0 3.21875 2.625 0.5 6 0.5H42C45.2812 0.5 48 3.21875 48 6.5V30.5C48 33.875 45.2812 36.5 42 36.5H6C2.625 36.5 0 33.875 0 30.5V6.5ZM4.5 6.5V8.65625L20.625 21.875C22.5938 23.4688 25.3125 23.4688 27.2812 21.875L43.5 8.65625V6.5C43.5 5.75 42.75 5 42 5H6C5.15625 5 4.5 5.75 4.5 6.5ZM4.5 14.4688V30.5C4.5 31.3438 5.15625 32 6 32H42C42.75 32 43.5 31.3438 43.5 30.5V14.4688L30.1875 25.3438C26.5312 28.3438 21.375 28.3438 17.7188 25.3438L4.5 14.4688Z"
      fill="#991b1b"
    />
  </svg>
);


export const HelpIcon = () => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 0c6.61 0 12 5.39 12 12 0 6.656-5.39 12-12 12-6.656 0-12-5.344-12-12C0 5.39 5.344 0 12 0zm0 
      21.75c5.344 0 9.75-4.36 9.75-9.75 0-5.344-4.406-9.75-9.75-9.75-5.39 0-9.75 4.406-9.75 9.75 0 5.39 4.36 9.75 
      9.75 9.75zm0-6c.797 0 1.5.656 1.5 1.5s-.703 1.5-1.5 1.5c-.89 0-1.5-.656-1.5-1.5s.656-1.5 1.5-1.5zM13.547 
      6c1.875 0 3.328 1.453 3.281 3.281 0 1.125-.61 2.203-1.594 2.813l-2.109 1.312v.094A1.14 1.14 0 0112 
      14.625a1.14 1.14 0 01-1.125-1.125v-.75c0-.375.188-.75.563-.984l2.671-1.594c.329-.188.516-.516.516-.89 0-.563-.516-1.032-1.125-1.032h-2.39c-.563 0-.985.469-.985 
      1.031A1.14 1.14 0 019 10.406a1.14 1.14 0 01-1.125-1.125A3.261 3.261 0 0111.156 6h2.39z"
      fill="#333131"
    />
  </svg>
);

export const CalendarIcon = () => (
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.5 0C3.75 0 4 0.25 4 0.5V2H10V0.5C10 0.25 10.2188 0 10.5 0C10.75 0 11 0.25 11 0.5V2H12C13.0938 
      2 14 2.90625 14 4V14C14 15.125 13.0938 16 12 16H2C0.875 16 0 15.125 0 14V4C0 2.90625 0.875 2 2 2H3V0.5C3
       0.25 3.21875 0 3.5 0ZM13 6H1V14C1 14.5625 1.4375 15 2 15H12C12.5312 15 13 14.5625 13 14V6ZM12 
       3H2C1.4375 3 1 3.46875 1 4V5H13V4C13 3.46875 12.5312 3 12 3Z"
      fill="#333131"
    />
  </svg>
);
export const DatePicketIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.88889 6.11111V3M13.1111 6.11111V3M6.11111 9.22222H13.8889M4.55556 17H15.4444C16.3036 17 17 16.3036 17 15.4444V6.11111C17 5.252 16.3036 4.55556 15.4444 4.55556H4.55556C3.69645 4.55556 3 5.252 3 6.11111V15.4444C3 16.3036 3.69645 17 4.55556 17Z"
      stroke="#696363"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const EyeIconClose = () => (
  <svg
    width={22}
    height={18}
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.688 3.906C7.093 2.844 8.844 2 11 2c2.5 0 4.531 1.156 6 
      2.531 1.469 1.344 2.438 2.969 2.906 4.094.094.25.094.531 0 .781-.406 1-1.25 2.438-2.5 
      3.688l3.282 2.594c.343.25.406.718.125 1.03-.25.345-.72.407-1.032.126l-18.5-14.5c-.343-.25-.406-.719-.125-1.031.25-.344.719-.407 
      1.032-.125l3.5 2.718zm1.218.969L8.344 6C9.063 5.375 9.969 5 11 5c2.188 0 4 1.813 4 4 0 .688-.188 1.313-.469 1.844l1.688 
      1.312A11.525 11.525 0 0018.437 9a11.177 11.177 0 00-2.468-3.375C14.688 4.437 13.03 3.5 11 3.5c-1.594 0-2.969.563-4.094 
      1.375zm6.407 5.031c.124-.281.187-.594.187-.906 0-1.375-1.125-2.5-2.5-2.5h-.094c.063.188.094.344.094.5 0 
      .344-.094.625-.219.906l2.531 2zm.312 4.063L14.938 15A8.18 8.18 0 0111 16c-2.531 0-4.563-1.125-6.031-2.5-1.469-1.375-2.438-3-2.907-4.094a1.12 
      1.12 0 010-.781 12.48 12.48 0 011.532-2.563L4.75 7a9.801 9.801 0 00-1.219 2c.438.938 1.25 2.281 2.469 3.406 1.281 1.188 2.938 2.094 5 2.094.938 
      0 1.813-.188 2.625-.531zM7 9v-.25l1.75 1.375a2.421 2.421 0 001.688 1.313l1.75 1.406a5.003 5.003 0 01-1.22.156c-2.187 0-4-1.781-4-4H7z"
      fill="#333131"
    />
  </svg>
);

export const EyeIconOpen = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="#333131"
    viewBox="0 0 16 16"
  >
    <path
      d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 011.66-2.043C4.12 
    4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0114.828 
    8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 
    12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 011.172 8z"
    />
    <path d="M8 5.5a2.5 2.5 0 100 5 2.5 2.5 0 000-5zM4.5 8a3.5 3.5 0 117 0 3.5 3.5 0 01-7 0z" />
  </svg>
);
export const PhoneIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="#212529"
    viewBox="0 0 122.88 122.27"
  >
    <path
      d="M33.84 50.25c4.13 7.45 8.89 14.6 15.07 21.12C55.11 77.93 62.82 83.9 72.8 89c.74.36 1.44.36 2.07.11.95-.36 
    1.92-1.15 2.87-2.1.74-.74 1.66-1.92 2.62-3.21 3.84-5.05 8.59-11.32 15.3-8.18.15.07.26.15.41.21l22.38 
    12.87c.07.04.15.11.21.15 2.95 2.03 4.17 5.16 4.2 8.71 0 3.61-1.33 7.67-3.28 11.1-2.58 4.53-6.38 7.53-10.76 
    9.51-4.17 1.92-8.81 2.95-13.27 3.61-7 
    1.03-13.56.37-20.27-1.69-6.56-2.03-13.17-5.38-20.39-9.84l-.53-.34c-3.31-2.07-6.89-4.28-10.4-6.89-12.84-9.7-25.93-23.71-34.46-39.13C2.35 
    50.95-1.55 36.98.58 23.67c1.18-7.3 4.31-13.94 9.77-18.32C15.11 1.51 21.52-.59 29.82.15c.95.07 
    1.8.62 2.25 1.44l14.35 24.26c2.1 2.72 2.36 5.42 1.21 8.12-.95 2.21-2.87 4.25-5.49 6.15-.77.66-1.69 1.33-2.66 2.03-3.21 2.33-6.86 5.02-5.61 8.18l-.03-.08z"
    />
  </svg>
);
export const CheckIconBig = () => (
  <svg
    width="97"
    height="96"
    viewBox="0 0 97 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M46.0625 63.75C44 65.8125 40.8125 65.8125 38.75 63.75L26.75 51.75C24.6875 49.6875 24.6875 46.5 26.75 
      44.4375C28.8125 42.375 32 42.375 34.0625 44.4375L42.5 52.6875L62.75 32.4375C64.8125 30.375 68 30.375 
      70.0625 32.4375C72.125 34.5 72.125 37.6875 70.0625 39.75L46.0625 63.75ZM96.5 48C96.5 74.625 74.9375 
      96 48.5 96C21.875 96 0.5 74.625 0.5 48C0.5 21.5625 21.875 0 48.5 0C74.9375 0 96.5 21.5625 96.5 48ZM48.5 
      9C26.9375 9 9.5 26.625 9.5 48C9.5 69.5625 26.9375 87 48.5 87C69.875 87 87.5 69.5625 87.5 48C87.5 26.625 
      69.875 9 48.5 9Z"
      fill="#171717"
    />
  </svg>
);

export const CheckIconSmall = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 97 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M46.0625 63.75C44 65.8125 40.8125 65.8125 38.75 63.75L26.75 51.75C24.6875 49.6875 24.6875 46.5 26.75 
      44.4375C28.8125 42.375 32 42.375 34.0625 44.4375L42.5 52.6875L62.75 32.4375C64.8125 30.375 68 30.375 
      70.0625 32.4375C72.125 34.5 72.125 37.6875 70.0625 39.75L46.0625 63.75ZM96.5 48C96.5 74.625 74.9375 
      96 48.5 96C21.875 96 0.5 74.625 0.5 48C0.5 21.5625 21.875 0 48.5 0C74.9375 0 96.5 21.5625 96.5 
      48ZM48.5 9C26.9375 9 9.5 26.625 9.5 48C9.5 69.5625 26.9375 87 48.5 87C69.875 87 87.5 69.5625 87.5 
      48C87.5 26.625 69.875 9 48.5 9Z"
      fill="#171717"
    />
  </svg>
);

export const DownArrowIcon = ({ color }: any) => (
  <svg
    width={16}
    height={10}
    viewBox="0 0 16 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.281 8.719l-6-6a.964.964 0 010-1.406.964.964 0 011.407 0L8 6.593l5.281-5.28a.964.964 0 011.406 
      0 .964.964 0 010 1.406l-6 6a.964.964 0 01-1.406 0z"
      fill={color}
    />
  </svg>
);
export const ChatIcon = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.96484 5.5625C5.45703 5.5625 5.8125 5.97266 5.8125 6.4375C5.8125 6.92969 5.42969 7.3125 4.96484 
      7.3125C4.5 7.3125 4.11719 6.92969 4.11719 6.4375C4.11719 5.97266 4.47266 5.5625 4.96484 5.5625ZM8.02734 
      5.5625C8.51953 5.5625 8.875 5.97266 8.875 6.4375C8.875 6.92969 8.51953 7.3125 8.02734 7.3125C7.53516 
      7.3125 7.15234 6.92969 7.15234 6.4375C7.15234 5.97266 7.53516 5.5625 8.02734 5.5625ZM11.0898 
      5.5625C11.582 5.5625 11.9375 5.97266 11.9375 6.4375C11.9375 6.92969 11.5547 7.3125 11.0898 7.3125C10.625 
      7.3125 10.2422 6.92969 10.2422 6.4375C10.2422 5.97266 10.5977 5.5625 11.0898 5.5625ZM13.2227 0.75C14.207 
      0.75 14.9727 1.54297 14.9727 2.5V10.3203C14.9727 11.25 14.1797 12.043 13.2227 12.043H9.28516L5.86719 
      14.6133C5.64844 14.75 5.34766 14.6133 5.34766 14.3398V12.0703H2.72266C1.73828 12.0703 0.972656 11.3047 
      0.972656 10.3477V2.5C0.972656 1.54297 1.73828 0.75 2.72266 0.75H13.2227ZM13.6875 10.375V2.5C13.6875 2.28125 
      13.4688 2.0625 13.25 2.0625H2.75C2.50391 2.0625 2.3125 2.28125 2.3125 2.5V10.375C2.3125 10.6211 2.50391 
      10.8125 2.75 10.8125H6.6875V12.4531L8.875 10.8125H13.25C13.4688 10.8125 13.6875 10.6211 13.6875 10.375Z"
      fill="#262626"
    />
  </svg>
);
export const InsuranceIcon = () => (
  <svg
    width={28}
    height={37}
    viewBox="0 0 28 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.164 9.36c.844.843 1.336 1.968 1.336 3.163V32c0 2.531-2.04 4.5-4.5 4.5H5C2.469 36.5.5 34.531.5 32V5C.5 
      2.54 2.469.5 5 .5h10.477c1.195 0 2.32.492 3.164 1.336l7.523 7.523zm-9.914-6.4v7.665a1.11 1.11 0 001.125 
      1.125h7.664a1.77 1.77 0 00-.492-.844l-7.453-7.453a1.77 1.77 0 00-.844-.492zm9 29.04V14h-7.875C15.477 
      14 14 12.523 14 10.625V2.75H5C3.734 2.75 2.75 3.805 2.75 5v27c0 1.266.984 2.25 2.25 2.25h18c1.195 
      0 2.25-.984 2.25-2.25zm-9-14.625v3.375h3.375c.563 0 1.125.563 1.125 1.125v2.39c0 .563-.563 1.055-1.125 
      1.055H16.25v3.305c0 .633-.563 1.125-1.125 1.125h-2.25c-.633 0-1.125-.492-1.125-1.195V25.25H8.375a1.11 
      1.11 0 01-1.125-1.125v-2.25c0-.563.492-1.125 1.125-1.125h3.375v-3.375c0-.563.492-1.125 1.125-1.125h2.25c.563 
      0 1.125.563 1.125 1.125z"
      fill="#333131"
    />
  </svg>
);

export const TaxFormIcon = () => (
  <svg
    width={28}
    height={37}
    viewBox="0 0 28 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.164 9.36c.844.843 1.336 1.968 1.336 3.163V32c0 2.531-2.04 4.5-4.5 4.5H5C2.469 36.5.5 34.531.5 
      32V5C.5 2.54 2.469.5 5 .5h10.477c1.195 0 2.32.492 3.164 1.336l7.523 7.523zm-9.914-6.4v7.665a1.11 
      1.11 0 001.125 1.125h7.664a1.77 1.77 0 00-.492-.844l-7.453-7.453a1.77 
      1.77 0 00-.844-.492zm9 29.04V14h-7.875C15.477 14 14 12.523 14 10.625V2.75H5C3.734 2.75 2.75 3.805 
      2.75 5v27c0 1.266.984 2.25 2.25 2.25h18c1.195 0 2.25-.984 2.25-2.25zM6.125 7.25A1.11 1.11 0 015 
      6.125C5 5.562 5.492 5 6.125 5h4.5c.563 0 1.125.563 1.125 1.125 0 .633-.563 1.125-1.125 1.125h-4.5zm5.625 
      3.375c0 .633-.563 1.125-1.125 1.125h-4.5A1.11 1.11 0 015 10.625c0-.563.492-1.125 1.125-1.125h4.5c.563 
      0 1.125.563 1.125 1.125zm2.953 10.195c-1.547-.21-2.812.14-2.953.844-.07.352-.14.703 
      2.04 1.266l.42.14c1.9.492 4.712 1.196 4.22 4.008-.282 1.477-1.547 2.39-3.375 2.602 0 .07.07.07.07.07v1.125c0 
      .633-.563 1.125-1.125 1.125a1.11 1.11 0 01-1.125-1.125V29.68a21.042 21.042 0 01-2.25-.563l-.352-.14A1.172 1.172 
      0 019.5 27.57c.21-.562.844-.914 1.406-.703l.352.07c.633.211 1.406.422 1.969.563 
      1.546.21 2.812-.14 2.953-.844.14-.562-.211-.843-2.461-1.406l-.492-.14c-1.407-.352-4.22-1.055-3.727-3.868.281-1.476 
      1.547-2.39 3.375-2.672v-1.195c0-.563.492-1.125 1.125-1.125.563 0 1.125.563 1.125 1.125V18.5c0 
      .07-.07.07-.07.14.492.07 1.054.212 1.758.422.632.211.914.844.773 1.407-.211.562-.844.914-1.406.773a6.782 
      6.782 0 00-1.477-.422z"
      fill="#333131"
    />
  </svg>
);

export const RightArrowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M7 5l5 5-5 5"
      stroke="#333131"
      strokeWidth={1.67}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const DownloadIcon = () => (
  <svg
    width={16}
    height={14}
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 2c1.094 0 2 .906 2 2v8c0 1.125-.906 2-2 2H2c-1.125 0-2-.875-2-2V2C0 .906.875 0 2 0h3.656c.532 
      0 1.032.219 1.407.594L8.592 2H14zm.5 10V4c0-.25-.25-.5-.5-.5H8L6 1.656a.502.502 0 00-.344-.156H2c-.281 
      0-.5.25-.5.5v10c0 .281.219.5.5.5h12c.25 0 .5-.219.5-.5zM9.969 7.469A.71.71 0 0111 7.5a.684.684 
      0 010 1.031l-2.5 2.5a.622.622 0 01-.5.219.753.753 0 01-.563-.219l-2.5-2.5c-.312-.281-.312-.75 
      0-1.062.282-.282.75-.282 1.063 0l1.25 1.25V5.5A.74.74 0 018 4.75a.76.76 0 01.75.75v3.219l1.219-1.25z"
      fill="#227066"
    />
  </svg>
);
export const DocumentIcon = () => (
  <svg
    width={48}
    height={48}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5 11c0-2.46 1.969-4.5 4.5-4.5h10.969c.843 0 1.758.422 2.39 1.055l8.086 8.086c.633.632 1.055 
      1.546 1.055 2.39V38c0 2.531-2.04 4.5-4.5 4.5H15c-2.531 0-4.5-1.969-4.5-4.5V11zm24.75 9h-7.875C25.477 
      20 24 18.523 24 16.625V8.75h-9c-1.266 0-2.25 1.055-2.25 2.25v27c0 1.266.984 2.25 2.25 2.25h18c1.195
       0 2.25-.984 2.25-2.25V20zm-.352-2.742l-8.156-8.156c-.14-.141-.351-.211-.492-.282v7.805a1.11 1.11 
       0 001.125 1.125h7.805c-.07-.14-.14-.352-.282-.492z"
      fill="#333131"
    />
  </svg>
);

export const FileIcon = () => (
  <svg
      xmlns="http://www.w3.org/2000/svg"
      width={14}
      height={16}
      viewBox="0 0 14 16"
      fill="none"
    >
      <path
        d="M4.667 8h4.666m-4.666 3.111h4.666M10.89 15H3.11c-.859 0-1.555-.696-1.555-1.556V2.556C1.556 1.696 2.252 1 3.11 1h4.345c.206 0 .404.082.55.228l4.21 4.21a.778.778 0 01.228.55v7.456c0 .86-.696 1.556-1.555 1.556z"
        stroke="#227066"
        strokeWidth={1.67}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
)

export const ExpiredIcon = () => (
  <svg
    width={37}
    height={37}
    viewBox="0 0 37 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.57 19.625c1.266 0 2.25 1.055 2.25 2.25 0 1.266-.984 2.25-2.25 2.25a2.221 2.221 0 01-2.25-2.25c0-1.195.985-2.25 2.25-2.25zm0-2.25c-.914 0-1.687-.703-1.687-1.688V7.814c0-.915.773-1.688 1.687-1.688s1.688.773 1.688 1.688v7.875c0 .984-.774 1.687-1.688 1.687zM31.93.5c2.53 0 4.5 2.04 4.5 4.5v20.11c0 2.39-2.04 4.43-4.5 4.43H21.805l-8.79 6.608c-.562.352-1.335 0-1.335-.703V29.61H4.93c-2.532 0-4.5-1.968-4.5-4.43V5C.43 2.54 2.398.5 4.93.5h27zm1.195 24.75V5c0-.563-.563-1.125-1.125-1.125H5c-.633 0-1.125.563-1.125 1.125v20.25A1.11 1.11 0 005 26.375h10.125v4.219l5.625-4.219H32c.563 0 1.125-.492 1.125-1.125z"
      fill="#333131"
    />
  </svg>
);
export const CheckBoxTickIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" rx="4" fill="#0E406A" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.3801 7.08529C18.6091 7.29521 18.6245 7.65103 18.4146 7.88004L10.1646 16.88C10.0609 16.9931 9.91558 17.059 9.76219 17.0623C9.6088 17.0656 9.46071 17.0062 9.35222 16.8977L5.60222 13.1477C5.38255 12.928 5.38255 12.5719 5.60222 12.3522C5.82189 12.1325 6.17805 12.1325 6.39772 12.3522L9.7323 15.6868L17.5853 7.11985C17.7952 6.89084 18.1511 6.87537 18.3801 7.08529Z"
      fill="white"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
