import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getProxyDetails,
  DeviceTypes,
  useDeviceType,
  convertStringToDate,
  convertToDate,
} from 'common';
import { IProxyData } from './IProxyApproval';
import { AccordionTable } from '@sentaraui/optimahealth_web';

//Interface for Proxy Pending Data Binding in the table
interface IPendingData {
  ProxyFirstName: string;
  ProxyLastName: string;
  InitiatedDate: Date;
  ID: number | string;
}

const ProxyPending = ({ proxyData }: IProxyData) => {
  // Initialize translation
  const { t } = useTranslation();
  const [PendingProxy, setPendingProxy] = useState([]);
  const deviceType = useDeviceType();
  const isMobile = deviceType === DeviceTypes.MOBILE;

  // To get the pending data from the ProxyData variable
  useEffect(() => {
    if(proxyData?.Result) {
      setPendingProxy(
        proxyData?.Result?.filter(
          (item: { CurrentStatus: string }) =>
            item.CurrentStatus === getProxyDetails.PENDING_FOR_APPROVAL
        )
      );
    }
  }, [proxyData]);

  return (
    <>
      {!isMobile && <> { PendingProxy.length>0 ? (
        <div className="table-overflow-scroll">
          <table
            className="table table-custom-style approved-representative-table black-color mb-4"
            id={'viewauthorizationDetails'}
          >
            <thead>
              <tr>
                <th data-testid="claimIdTab">
                  {t('proxy.label.designatedrepresentative')}
                </th>
                <th data-testid="serviceDateTab">
                  {t('proxy.label.initiateddate')}
                </th>
                <th data-testid="statusTab">{t('proxy.label.status')}</th>
                <th data-testid="providerNameTab">
                  {t('proxy.label.pendingreasons')}
                </th>
              </tr>
            </thead>

            <tbody>
              {PendingProxy &&
                PendingProxy?.length > 0 &&
                PendingProxy?.map((item: IPendingData, i: any) => {
                  return [
                    <tr key={item.ID}>
                      <td>{item.ProxyFirstName + ' ' + item.ProxyLastName}</td>
                      <td>
                        {convertToDate(item.InitiatedDate)}
                      </td>
                      <td>{t('proxy.label.pending')}</td>
                      <td>{t('proxy.label.pendingApproval')}</td>
                    </tr>,
                  ];
                })}
              
            </tbody>
          </table>
        </div>
      ) :
        <div className="text-center">
            {t('proxy.label.noPending')}
        </div>}
        </>
      }

      {isMobile &&
        (PendingProxy &&
        PendingProxy?.length > 0 &&
        PendingProxy !== undefined ? (
          <div className="accordion_table_odd_bg mb-4">
            <AccordionTable
              id="proxy_pending_status_table"
              initialActiveIndex={0}
              items={
                PendingProxy &&
                PendingProxy?.length > 0 &&
                PendingProxy !== undefined &&
                PendingProxy?.map((item: any, i: any) => ({
                  title: (
                    <div>
                      <div data-testid={`authNumberTab${i}`}>
                        <span className="font-sixteen">
                          {t('proxy.label.designatedrepresentative')}
                        </span>
                      </div>
                      <div data-testid={`initiatedLabel${i}`}>
                        {item.ProxyFirstName + ' ' + item.ProxyLastName}
                      </div>
                    </div>
                  ),
                  id: 'proxy_pending_status_table_data',
                  content: (
                    <div>
                      <div
                        data-testid={`initiateDdate${i}`}
                        className="redhat_bold"
                      >
                        {t('proxy.label.initiateddate')}
                      </div>
                      <div
                        data-testid={`initiatedLabelValue${i}`}
                        className="mb-3"
                      >
                        {convertStringToDate(item.InitiatedDate)}
                      </div>
                      <div
                        data-testid={`pendingStatus${i}`}
                        className="redhat_bold"
                      >
                        {t('proxy.label.status')}
                      </div>
                      <div data-testid={`pending${i}`} className="mb-3">
                        {t('proxy.label.pending')}
                      </div>
                      <div
                        data-testid={`pendingApproval${i}`}
                        className="redhat_bold"
                      >
                        {t('proxy.label.pendingApproval')}
                      </div>
                      <div data-testid={`pendingApproval${i}`} className="pb-3">
                        {t('proxy.label.pendingApproval')}
                      </div>
                    </div>
                  ),
                }))
              }
            />
          </div>
        ) : (
          <div className="mb-4">
            <div className="text-center">{t('proxy.label.noPending')}</div>
          </div>
        ))}
    </>
  );
};

export default ProxyPending;
