import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EyeIconClose,
  EyeIconOpen,
  PasswordRegex,
  UserDetails,
  getDecryptedValue,
  getMaxLength,
  HelpIcon,
  ModalCustomStyle,
} from 'common';
import { useFormContext } from 'react-hook-form';
import { useUserPassword } from '@sentara/sentara-api-hooks-core';
import {
  Button,
  Label,
  ErrorMessage,
  InputField,
} from '@sentaraui/optimahealth_web';
import PasswordInfoOverlay from '../pages/forms/PasswordInfoOverlay';
import Modal from 'react-modal';

// Define the interface for the props passed to PasswordInputPage component
export interface ChangePasswordProps {
  // Handler for onKeyDown event
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  // Password length indicator
  setValue: (value: string) => void;
  setNoContent?: (value: boolean) => void;
  setUnauthError?: (value: boolean) => void;
  setPassCriteria?: (value: boolean) => void;
  setServerError?: (value: boolean) => void;
  showPasswordPlaceholder?: string;
  state?: any;
  setPasswordError?: (value: boolean) => void;
  errorPassword?: boolean;
  showIcon?: boolean;
  inputStrengthClass?: string;
}

// Define the PasswordInputPage component
const ChangePassword: React.FC<ChangePasswordProps> = ({
  onKeyDown,
  setValue,
  setNoContent,
  setUnauthError,
  setPassCriteria,
  setServerError,
  showPasswordPlaceholder,
  state,
  setPasswordError,
  errorPassword,
  showIcon,
  inputStrengthClass
}) => {
  const { t } = useTranslation();
  const acceptableStrengthClasses = ['strength-bar-strong', 'strength-bar-medium'];
  const methods = useFormContext();
  const {
    getValues,
    formState: { errors },
  } = methods;
  const { userPassword } = useUserPassword();
  // State to manage password visibility
  const [passwordOpen, setPasswordOpen] = useState<boolean>(false);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  //To validate the password Criteria
  const checkPasswordCriteria = async () => {
    const userId: string = await getDecryptedValue(UserDetails.usedId);
    const data: any = {
      firstName: state?.firstName,
      lastName: state?.lastName,
      passwordNew: getValues('password'),
      userId: userId,
    };
    if (getValues('password')) {
      const response = await userPassword(data);
      if (response?.errorCode) {
        handlePasswordError(response?.errorCode);
      } else {
        setPasswordError && setPasswordError(false);
      }
    }
  };

  // Handle password error
  const handlePasswordError = (errorCode: string): void => {
    const errorStatusCode: string[] = [
      '441',
      '400',
      '442',
      '445',
      '439',
      '443',
      '448',
    ];
    const checkError = errorStatusCode.includes(errorCode.toString());
    if (checkError) {
      setPasswordError && setPasswordError(true);
    } else {
      setPasswordError && setPasswordError(false);
    }
  };
  function openModal(e: { preventDefault: () => void }) {
    e.preventDefault();
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <div className="mb-3 pos-rel">
        {/* Label for the input */}
        <Label
          dataTestId={'passwordInputLabel'}
          htmlFor="password"
          className="form-label redhat_medium"
          children={t('settings.label.newPassword')}
        />
        {/* Display asterisk if password length is less than 2 */}
        <span className="text-danger px-1">*</span>
        {/* Container for input and icon */}
        <div className="d-flex align-items-center singin-input pos-rel">
          <div className="col">
            <div className="d-flex align-items-center pe-0 pe-lg-2 pe-md-2">
              <div className="pos-rel flex-1">
                {/* Input field */}
                <InputField
                  className={
                    errors?.password
                      ? 'error-border form-input psw-input'
                      : 'form-input psw-input'
                  }
                  autoComplete="password"
                  placeholder={
                    showPasswordPlaceholder
                      ? showPasswordPlaceholder
                      : '********'
                  }
                  dataTestId="passwordInput"
                  maxLength={getMaxLength.password}
                  type={!passwordOpen ? 'password' : 'text'}
                  onKeyDown={onKeyDown}
                  onFocus={() => {
                    if (setNoContent) setNoContent(false);
                    if (setUnauthError) setUnauthError(false);
                    if (setPassCriteria) setPassCriteria(false);
                    if (setServerError) setServerError(false);
                    setPasswordError && setPasswordError(false);
                  }}
                  {...methods.register('password', {
                    required: true,
                    pattern: {
                      value: PasswordRegex,
                      message: 'Password criteria not match',
                    },
                    onBlur: () => {
                      if (window.location.pathname.includes('profile')) {
                        if (acceptableStrengthClasses.includes(inputStrengthClass ?? '')) {
                            checkPasswordCriteria();
                        } 
                      }
                    },
                    onChange: (e) => {
                      setValue(e.target.value);
                    },
                  })}
                />
                {/* Button to toggle password visibility */}
                <Button
                  dataTestId="passwordInputEyeIcon"
                  className="eye_icon"
                  onClick={(e: { preventDefault: () => void }) => {
                    e.preventDefault();
                    setPasswordOpen(!passwordOpen);
                  }}
                >
                  {/* Toggle eye icon based on password visibility */}
                  {!passwordOpen ? (
                    <span
                      className="visually-hiiden"
                      aria-label="Eye icon button click to show the password"
                    >
                      <EyeIconClose />
                    </span>
                  ) : (
                    <span
                      className="visually-hiiden"
                      aria-label="Eye icon button click to hide the password"
                    >
                      <EyeIconOpen />
                    </span>
                  )}
                </Button>
              </div>
              {showIcon ? (
                <div className="ms-2">
                  <Button
                    dataTestId="memIdHelpInfo"
                    onClick={openModal}
                    aria-label="Information icon click to view"
                    variant="border-0 bg-transparent pe-0"
                    type={'button'}
                  >
                    <HelpIcon />
                  </Button>
                </div>
              ) : null}
            </div>

            <Modal
              isOpen={isOpen}
              onRequestClose={closeModal}
              data-testid="userNameInputModal"
              contentLabel="Username Information Overlay"
              style={ModalCustomStyle}
            >
              <div
                className="w-member-overlay"
                data-testid="userNameInputModalModal"
              >
                <div className="text-right">
                  <Button
                    type="button"
                    data-testid="userNameInputModalClose"
                    className="btn-close"
                    onClick={closeModal}
                    aria-label="Close"
                  >
                    <span aria-hidden="true" className="visually-hidden">
                      {t('appointments.buttonLableTwoClose')}
                    </span>
                  </Button>
                </div>
                <PasswordInfoOverlay />
              </div>
            </Modal>

            {/* Display error messages */}
            {errors?.password?.type === 'required' && (
              <div className="mt-1">
                <ErrorMessage
                  dataTestId={'passwordRequireError'}
                  children={
                    window.location.pathname.includes('activation')
                      ? t('activation.errors.passwordrequired')
                      : t('settings.errors.newpassword')
                  }
                />
              </div>
            )}
            {(errors?.password?.type === 'pattern' ||  (window.location.pathname.includes('profile') && errorPassword)) ? (
              <div className="mt-1">
                <ErrorMessage
                  dataTestId={'passwordRequireError'}
                  children={t('activation.errors.passwordrequired')}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
