import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from 'context';
import {
  getProxyDetails,
  MyPlanTabs,
  REQUEST,
  myPlanUrl,
  DeviceTypes,
  useDeviceType,
  convertToDate,
} from 'common';
import ViewDesignated from './ViewDesignated';
import { IApprovalData, IProxyData } from './IProxyApproval';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  AccordionTable,
  Input,
} from '@sentaraui/optimahealth_web';

const ProxyApproval = ({ proxyData }: IProxyData) => {
  const {
    setEnableView,
    enableView,
    setProxySelected,
    setUpdateProxy,
    setEnableDelete,
    proxySelected,
    setIsDesignatedTabShow
  } = useContext(GlobalContext);
  // Initialize translation
  const { t } = useTranslation();
  const [approvalProxy, setApprovalProxy] = useState([]);
  const deviceType = useDeviceType();
  const isMobile = deviceType === DeviceTypes.MOBILE;
  const navigate = useNavigate();

  // Define an asynchronous function
  useEffect(() => {
    setProxySelected([]);
    if(proxyData?.Result) {
      setApprovalProxy(
        proxyData?.Result?.filter(
          (item: { CurrentStatus: string }) =>
            item.CurrentStatus === getProxyDetails.confirmed
        )
      );
    }
  }, [proxyData]);

  // handle button click for update and terminate
  const handleButtonClick = (updateProxyValue: boolean, enableDeleteValue: boolean) => (e: React.MouseEvent<HTMLButtonElement>, item: IApprovalData) => {
    e.preventDefault();
    setProxySelected(item);
    navigate(`${myPlanUrl + MyPlanTabs.designatedID}`);
    setUpdateProxy(updateProxyValue);
    setEnableDelete(enableDeleteValue);
  };

  // Usage
  // For update button
  const updateButton = handleButtonClick(true, false);

  // For terminate button
  const terminateButton = handleButtonClick(false, true);

  const renderButton = (dataTestId: string, labelKey: string, variant: string, onClick: (e: React.MouseEvent<HTMLButtonElement>) => void) => (
    <Button
      dataTestId={dataTestId}
      variant={variant}
      type="button"
      onClick={onClick}
    >{t(`proxy.label.${labelKey}`)}</Button>
  );

  return (
    <>
      {!enableView && (
        <div>
          {!isMobile && <> { approvalProxy?.length>0  ? (
            <div className="table-overflow table-responsive">
              <table
                className="table table-custom-style approved-representative-table black-color mb-4"
                id={'viewauthorizationDetails'}
              >
                <thead>
                  <tr>
                    <th data-testid="claimIdTab">
                      {t('proxy.label.designatedrepresentative')}
                    </th>
                    <th data-testid="serviceDateTab">
                      {t('proxy.label.infoShared')}
                    </th>
                    <th data-testid="statusTab">
                      {t('proxy.label.dateApproved')}
                    </th>
                    <th data-testid="providerNameTab">
                      {t('proxy.label.status')}
                    </th>
                    <th data-testid="providerNameTab">
                      {t('proxy.label.disclosure')}
                    </th>
                    <th data-testid="providerNameTab">
                      {t('proxy.label.action')}
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {approvalProxy &&
                    approvalProxy?.length > 0 &&
                    approvalProxy?.map((item: IApprovalData, i: any) => {
                     
                      return [
                        <tr key={item.ID}>
                          <td>
                            <div className="d-flex align-items-center">
                              <Input
                                type="radio"
                                id={`'member${i + 1}'`}
                                className="form-check-input mt-0 me-2"
                                data-testid={`'medicalClaimCheckbox${i + 1}'`}
                                name={'members'}
                                value={item.ID}
                                checked={proxySelected.ID === item.ID}
                                onChange={() => {
                                  setProxySelected(item);
                                }}
                              />{' '}
                              {item.ProxyFirstName + ' ' + item.ProxyLastName}
                            </div>
                          </td>
                          <td>
                            {(item.Consents.MedicalClaims &&
                             !(item.Consents.PrimaryPhysician && item.Consents.AccountInformation &&
                              item.Consents.MedicalAuthorization))
                              ? t('proxy.label.medicalclaims')
                              : ''}
                            {/* medical calims and MedicalAuthorization will come together show with plus symbol */}
                            {(item.Consents.MedicalAuthorization &&
                              !(item.Consents.MedicalClaims && item.Consents.PrimaryPhysician &&
                                item.Consents.AccountInformation))
                              ? ('+ '+t('proxy.label.medicalAuthorizations'))
                              : ''}
                            {(item.Consents.PrimaryPhysician &&
                             !(item.Consents.MedicalClaims && item.Consents.AccountInformation &&
                                item.Consents.MedicalAuthorization))
                              ? t('proxy.label.PCP')
                              : ''}

                            {(item.Consents.AccountInformation &&
                              !(item.Consents.MedicalClaims && item.Consents.PrimaryPhysician &&
                              item.Consents.MedicalAuthorization))
                              ? t('proxy.label.accountInfo')
                              : ''}
                            {item.Consents.MedicalClaims && item.Consents.PrimaryPhysician && item.Consents.AccountInformation &&
                              item.Consents.MedicalAuthorization  ? t('proxy.label.allAbove') : ''} 
                          </td>

                          <td>
                            {convertToDate(item.InitiatedDate)}
                          </td>
                          <td>{t('proxy.label.active')}</td>
                          <td>{item.DisclosureOption === REQUEST ? t('proxy.label.atMyRequest') :(item.DisclosureText || t('yourPlan.label.otherlbl'))}</td>
                          <td>
                            <div className="d-flex flex-column flex-md-row gap-2">
                              <Button
                                dataTestId={'updateButton'}
                                variant={'secondary_btn btn-medium btn_block_mob'}
                                type="button"
                                onClick={(e: any) => {
                                  e.preventDefault();
                                  setProxySelected(item);
                                  setIsDesignatedTabShow(true);
                                  navigate(
                                    `${myPlanUrl + MyPlanTabs.designatedID}`
                                  );
                                  setUpdateProxy(true);
                                }}
                              >{t('proxy.label.update')}</Button>
                              <Button
                                dataTestId={'terminateButton'}
                                variant={'primary_btn btn-medium btn_block_mob'}
                                type="button"
                                onClick={(e: any) => {
                                  e.preventDefault();
                                  setProxySelected(item);
                                  setIsDesignatedTabShow(true);
                                  navigate(
                                    `${myPlanUrl + MyPlanTabs.designatedID}`
                                  );
                                  setEnableDelete(true);
                                }}
                              >{t('proxy.label.terminate')}</Button>
                            </div>
                          </td>
                        </tr>,
                      ];
                    })}
                </tbody>
              </table>
            </div>
          ):
          <div className="text-center">
              {t('proxy.label.noApproved')}
          </div>}
          </>
          }
          {isMobile &&
            (approvalProxy &&
            approvalProxy?.length > 0 &&
            approvalProxy !== undefined ? (
              <div className="accordion_table_odd_bg mb-4">
                <AccordionTable
                  id="proxy_approval_status_table"
                  initialActiveIndex={0}
                  items={
                    approvalProxy &&
                    approvalProxy?.length > 0 &&
                    approvalProxy !== undefined &&
                    approvalProxy?.map((item: IApprovalData, i: any) => ({
                      title: (
                        <div
                          className="d-flex align-items-center"
                          key={item.ID}
                          data-testid={`memberName${i + 1}`}
                        >
                          <Input
                            type="radio"
                            id={`'member${i + 1}'`}
                            className="form-check-input mt-0 me-2"
                            data-testid={`'medicalClaimCheckbox${i + 1}'`}
                            name={'members'}
                            value={item.ID}
                            onChange={() => {
                              setProxySelected(item);
                            }}
                            checked={proxySelected.ID === item.ID}
                          />{' '}
                          <div>
                            <div>
                              {t('proxy.label.designatedrepresentative')}
                            </div>
                            <div className="redhat_regular">
                              {item.ProxyFirstName + ' ' + item.ProxyLastName}
                            </div>
                          </div>
                        </div>
                      ),
                      id: 'proxy_approval_status_table_data',
                      content: (
                        <div key={item.ID}>
                          <div>
                            <div
                              data-testid={`initiateDdate${i}`}
                              className="redhat_bold"
                            >
                              {t('proxy.label.infoShared')}
                            </div>
                            <div
                              data-testid={`initiatedLabelValue${i}`}
                              className="mb-3"
                            >
                            {(item.Consents.MedicalClaims &&
                             !(item.Consents.PrimaryPhysician && item.Consents.AccountInformation &&
                              item.Consents.MedicalAuthorization))
                              ? t('proxy.label.medicalclaims')
                              : ''}
                            {/* medical calims and MedicalAuthorization will come together show with plus symbol */}
                            {(item.Consents.MedicalAuthorization &&
                              !(item.Consents.MedicalClaims && item.Consents.PrimaryPhysician &&
                                item.Consents.AccountInformation))
                              ? ('+ ' + t('proxy.label.medicalAuthorizations'))
                              : ''}
                            
                            {(item.Consents.PrimaryPhysician &&
                              !(item.Consents.MedicalClaims && item.Consents.AccountInformation &&
                                item.Consents.MedicalAuthorization))
                              ? t('proxy.label.PCP')
                              : ''}

                            {(item.Consents.AccountInformation &&
                              !(item.Consents.MedicalClaims && item.Consents.PrimaryPhysician &&
                              item.Consents.MedicalAuthorization))
                              ? t('proxy.label.accountInfo')
                              : ''}
                            
                            {item.Consents.MedicalClaims && item.Consents.PrimaryPhysician && item.Consents.AccountInformation &&
                              item.Consents.MedicalAuthorization  ? t('proxy.label.allAbove') : ''}
                            </div>
                            <div
                              data-testid={`dateApprovedLabel${i}`}
                              className="redhat_bold"
                            >
                              {t('proxy.label.dateApproved')}
                            </div>
                            <div
                              data-testid={`dateApprovedValue${i}`}
                              className="mb-3"
                            >
                              {convertToDate(item.InitiatedDate)}
                            </div>
                            <div
                              data-testid={`statusLabel${i}`}
                              className="redhat_bold"
                            >
                              {t('proxy.label.status')}
                            </div>
                            <div
                              data-testid={`statusValue${i}`}
                              className="mb-3"
                            >
                              {t('proxy.label.active')}
                            </div>
                            <div
                              data-testid={`disclosureLabel${i}`}
                              className="redhat_bold"
                            >
                              {t('proxy.label.disclosure')}
                            </div>
                            <div
                              data-testid={`disclosureValue${i}`}
                              className="mb-3"
                            >
                              {item.DisclosureOption}
                            </div>
                          </div>
                          <div className="d-flex flex-column gap-3 mb-3">
                            {renderButton('updateButton', 'update', 'secondary_btn btn_block_mob', (e) => updateButton(e, item))}
                            {renderButton('terminateButton', 'terminate', 'primary_btn btn_block_mob', (e) => terminateButton(e, item))}
                          </div>
                        </div>
                      ),
                    }))
                  }
                />
              </div>
            ) : (
              <div className="mb-4">
                <div className="text-center">{t('proxy.label.noApproved')}</div>
              </div>
            ))}
          {approvalProxy && approvalProxy?.length !== 0 && (
            <div className="text-end">
              <Button
                dataTestId={'continueButton'}
                variant={
                  proxySelected.ID !== undefined
                    ? 'primary_btn btn_block_mob'
                    : ' btn_block_mob disabled_btn'
                }
                type="button"
                onClick={(e: any) => {
                  e.preventDefault();
                  if (proxySelected.ID !== undefined) {
                    setEnableView(true);
                  }
                }}
              >{t('proxy.label.viewDesingated')}</Button>
            </div>
          )}
        </div>
      )}
      {enableView && <ViewDesignated {...proxySelected} />}
    </>
  );
};
export default ProxyApproval;
