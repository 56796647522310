import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { FooterDisclaimer } from 'organisms';
import ProxyHeader from './ProxyHeader';
import { caregiverEndPoint,
  PROXY_AUTHENTICATION,
  PROXY_EXPIRED_URL,
  PROXY_REGISTER_URL,
  getProxyDetails
} from 'common';
import { IProps } from './Interface';
import { GlobalContext } from 'context';
import {PageBox, CSRProxyRevoke } from '@sentara/caregiver';

interface ProxyDetails {
  callBack: any; // Adjust the type of callBack based on your needs
  KEY: string; // Assuming KEY is of type string, you can adjust it based on your actual type
  caregiverBaseURL: string; // Assuming caregiverBaseURL is of type string, adjust it based on your actual type
  baseURL: string; // Assuming baseURL is of type string, adjust it based on your actual type
  OcpApimSubscriptionKey: string; // Assuming OcpApimSubscriptionKey is of type string, adjust it based on your actual type
}

export const getProxyDetailsCommon = (callBackFunc: any, memberDetails: any): ProxyDetails => ({
  callBack: callBackFunc,
  KEY: memberDetails.ID || "",
  caregiverBaseURL: caregiverEndPoint || "",
  baseURL: process.env.REACT_APP_BASE_URL || '',
  OcpApimSubscriptionKey: process.env.REACT_APP_SUBSCRIPTION_KEY || '',
});

const CSRGuardianRevoke = () => {
  const [memberDetails, setMemberDetails] = useState<IProps>({});
  const  getAuthToken  = useContext(GlobalContext);
  const navigate = useNavigate();
  let serverMemberDetails: IProps;
  const expirePage = PROXY_EXPIRED_URL;
  const sessionData = sessionStorage.getItem(getProxyDetails.proxyAuthorizationData);

  useEffect(() => {
    if (sessionData) {
      serverMemberDetails = JSON.parse(atob(sessionData));
      if (
        serverMemberDetails.CurrentStatus !== getProxyDetails.confirmed &&
        serverMemberDetails.RequestFor !== getProxyDetails.delete
      ) {
        navigate(PROXY_REGISTER_URL);
        return;
      }
      setMemberDetails(serverMemberDetails);
    } else {
      navigate(expirePage);
    }
  }, []);

  const callBackFunc = (type: string) => {
    if ([getProxyDetails.delete, getProxyDetails.canceled].includes(type)) {
      sessionStorage.removeItem(getProxyDetails.proxyAuthorizationData);
    }
    if (type === getProxyDetails.canceled) {
      navigate(
        `${PROXY_AUTHENTICATION}?${getProxyDetails.key}=${memberDetails.ID}`
      );
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="d-flex flex-column h-100">
      <ProxyHeader />
      <div className="flex-1">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-8 col-sm-12 col-12">
              <div className="proxy_form proxy_form_disabled proxy_revoke_wrapper">
                <PageBox border={false}>
                  {memberDetails &&
                    memberDetails.RequestFor === getProxyDetails.delete && (
                      <CSRProxyRevoke
                      {...getProxyDetailsCommon(callBackFunc, memberDetails)} authToken={getAuthToken}
                      />
                    )}
                </PageBox>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer section */}
      <footer className="d-none d-lg-block d-md-block mt-auto pt-4">
        {/* Component to display the footer disclaimer */}
        <FooterDisclaimer />
      </footer>
    </div>
  );
};
export default CSRGuardianRevoke;
