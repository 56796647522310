import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMyPlan, MyPlanResponse } from '@sentara/sentara-api-hooks-core';
import {
  errorhandler,
} from 'common';
import { Table, Loader } from '@sentaraui/optimahealth_web';
import { GlobalContext } from 'context';

function MemberDetails() {
  //Initialize translation hook
  const { t } = useTranslation();
  const [loading, setLoading] = useState<any>(false);
  const { getFeature } = useContext(GlobalContext);

  // Initialize MyPlanType hook and state for response data
  const { MyPlanType } = useMyPlan();
  const [myPlan, setMyPlan] = useState<MyPlanResponse>();

  // Asynchronously fetch the data and set MyPlanType(result) data to setState method
  async function asyncCall() {
    const result = await MyPlanType();
    if (result?.errorCode) {
      errorhandler(result?.errorCode);
    } else if (result?.data) {
      setMyPlan(result.data);
    } 
    setLoading(false);
  }
  

  // Fetch MyPlanType data on component mount
  useEffect(() => {
    setLoading(true);
    asyncCall();
  }, []);

  return (
    <>
      {loading && <Loader />}
      <div className="my_plan_provider member_details">
        <Table
          rows={[
            [
              {
                text: (
                  <div className="redhat_bold fw-700" data-testid="memberName">
                    {t('yourPlan.label.memberName')}:
                  </div>
                ),
              },
              {
                text: (
                  <div data-testid="MemberNameValue">
                    {myPlan?.memberName}
                  </div>
                ),
              },
            ],
            [
              {
                text: (
                  <div className="redhat_bold fw-700" data-testid="memberId">
                    {t('yourPlan.label.memberId')}:
                  </div>
                ),
              },
              {
                text: (
                  <div data-testid="memberIdValue">{myPlan?.memberId}</div>
                ),
              },
            ],
            [
              {
                text: (
                  <div className="redhat_bold fw-700" data-testid="memberDob">
                    {t('yourPlan.label.memberDob')}:
                  </div>
                ),
              },
              {
                text: (
                  <div data-testid="memberDobValue">
                    {myPlan?.memberDob.split(' ')[0]}
                  </div>
                ),
              },
            ],
            [
              {
                text: (
                  <div className="redhat_bold fw-700" data-testid="effectiveDate">
                    {myPlan?.enrollmentCode === 'D'
                      ? t('yourPlan.label.disenrolledDate')
                      : t('yourPlan.label.effectiveDate')}
                    {':'}
                  </div>
                ),
              },
              {
                text: (
                  <div data-testid="effectiveDateValue">
                    {myPlan?.enrollmentCode === 'D'
                      ? myPlan?.enrolledDate.split(' ')[0]
                      : myPlan?.effectiveDate.split(' ')[0]}
                  </div>
                ),
              },
            ],
           getFeature?.ProfilePCP && myPlan?.pcpName !== '' ? [
              {
                text: (
                  <div className="redhat_bold fw-700" data-testid="pcpName">
                    {t('yourPlan.label.primaryCareProvider')}
                  </div>
                ),
              },
              {
                text: (
                  <div data-testid="pcpNameValue">{myPlan?.pcpName}</div>
                ),
              },
            ] : [],
            getFeature?.ProfilePCP && myPlan?.pcpEffectiveaDate !== '' ? [
              {
                text: (
                  <div
                    className="redhat_bold fw-700"
                    data-testid="pcpEffectiveaDate"
                  >
                    {t('yourPlan.label.pCPEffectiveDate')}
                  </div>
                ),
              },
              {
                text: (
                  <div data-testid="pcpEffectiveaDateValue">
                    {myPlan?.pcpEffectiveaDate.split(' ')[0]}
                  </div>
                ),
              },
            ] : [],
          ]}
        />
      </div>
    </>
  );
}

export default MemberDetails;
