import React, { createContext, useContext, useState, useEffect } from 'react';

type DeviceTypeProps = 'desktop' | 'tablet' | 'mobile';

const DeviceTypeContext = createContext<DeviceTypeProps | ''>('');
export const DeviceTypes = {
  DESKTOP: 'desktop' as DeviceTypeProps,
  TABLET: 'tablet' as DeviceTypeProps,
  MOBILE: 'mobile' as DeviceTypeProps,
};
export const DeviceTypeProvider = ({ children }: { children: React.JSX.Element }) => {
  const [deviceType, setDeviceType] = useState<DeviceTypeProps | ''>('');

  const handleResize = () => {
    if (window.innerWidth >= 992) {
      setDeviceType(DeviceTypes.DESKTOP);
    } else if (window.innerWidth >= 768) {
      setDeviceType(DeviceTypes.TABLET);
    } else {
      setDeviceType(DeviceTypes.MOBILE);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <DeviceTypeContext.Provider value={deviceType}>
      {children}
    </DeviceTypeContext.Provider>
  );
}

export function useDeviceType() {
  return useContext(DeviceTypeContext);
}
