import { useTranslation } from 'react-i18next';
import { Table } from '@sentaraui/optimahealth_web';
import { formatISODateToMDY } from 'common';
const AuthorizationDetailsTable: React.FC<any> = ({ authDetails }) => {
  const { t } = useTranslation();
  return (
    <div className="pb-2">
      <Table
        className={'print'}
        rows={[
          [
            {
              text: (
                <strong data-testid="memberNameLabel">
                  {t('authorizationDetails.label.memberName')}
                </strong>
              ),
            },
            {
              text: (
                <span data-testid="memberName">
                  {authDetails?.memberFullName}
                </span>
              ),
            },
          ],
          [
            {
              text: (
                <strong data-testid="memberIdLabel">
                  {t('authorizationDetails.label.memberID')}
                </strong>
              ),
            },
            {
              text: <span data-testid="memberId">{authDetails?.memberId}</span>,
            },
          ],
          [
            {
              text: (
                <strong data-testid="authNumberLabel">
                  {t('authorizationDetails.label.authorizationNumber')}
                </strong>
              ),
            },
            {
              text: (
                <span data-testid="authNumber">
                  {authDetails?.authorizationNumber}
                </span>
              ),
            },
          ],
          [
            {
              text: (
                <strong data-testid="liabilityTypeLabel">
                  {t('authorizationDetails.label.liabilityType')}
                </strong>
              ),
            },
            {
              text: (
                <span data-testid="liabilityType">
                  {authDetails?.liability}
                </span>
              ),
            },
          ],
          [
            {
              text: (
                <strong data-testid="effectiveDateLabel">
                  {t('authorizationDetails.label.effectiveDate')}
                </strong>
              ),
            },
            {
              text: (
                <span data-testid="effectiveDate">
                  {authDetails?.effectiveDate
                    ? formatISODateToMDY(authDetails?.effectiveDate)
                    : ''}
                </span>
              ),
            },
          ],
          [
            {
              text: (
                <strong data-testid="expirationDateLabel">
                  {t('authorizationDetails.label.expirationDate')}
                </strong>
              ),
            },
            {
              text: (
                <span data-testid="expirationDate">
                  {authDetails?.expirationDate
                    ? formatISODateToMDY(authDetails?.expirationDate)
                    : ''}
                </span>
              ),
            },
          ],
          [
            {
              text: (
                <strong data-testid="doctorReferredToLabel">
                  {t('authorizationDetails.label.doctorReferredTo')}
                </strong>
              ),
            },
            {
              text: (
                <span data-testid="doctorReferredTo">
                  {authDetails?.referredToProviderName}
                </span>
              ),
            },
          ],
          [
            {
              text: (
                <strong data-testid="referredToPracticeLabel">
                  {t('authorizationDetails.label.referredToPractice')}
                </strong>
              ),
            },
            {
              text: (
                <span data-testid="referredToPractice">
                  {authDetails?.referredToPracticeName}
                </span>
              ),
            },
          ],
          [
            {
              text: (
                <strong data-testid="docReferredByLabel">
                  {t('authorizationDetails.label.doctorReferredBy')}
                </strong>
              ),
            },
            {
              text: (
                <span data-testid="docReferredBy">
                  {authDetails?.referredBy}
                </span>
              ),
            },
          ],
          [
            {
              text: (
                <strong data-testid="authorizedVisitsLabel">
                  {t('authorizationDetails.label.numberOfAuthorizedVisits')}
                </strong>
              ),
            },
            {
              text: (
                <span data-testid="authorizedVisits">
                  {authDetails?.authorizedVisits}
                </span>
              ),
            },
          ],
          [
            {
              text: (
                <strong data-testid="numberOfClaimedVisitsLabel">
                  {t('authorizationDetails.label.NumberOfClaimedVisits')}
                </strong>
              ),
            },
            {
              text: (
                <span data-testid="numberOfClaimedVisits">
                  {authDetails?.claimedVisits}
                </span>
              ),
            },
          ],
        ]}
      />
    </div>
  );
};

export default AuthorizationDetailsTable;
