import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useCoveredEligible } from '@sentara/sentara-api-hooks-core';
import { toSentancePascalCase, DeviceTypes, useDeviceType, internalServerErrorCode, CommonErrorMessage, formatISODateToMDY } from 'common';
import ApiWrapper from '../../common/ApiWrapper';
import {
  Headings,
  Accordion,
  ContactInfo,
  Table,
  Button
} from '@sentaraui/optimahealth_web';
import { GlobalContext } from 'context';


const CoveredEligibleMembers = () => {
  const { t } = useTranslation();
  const [coveredMembers, setCoveredMembers] = useState<any>();
  const { CoveredEligibleType } = useCoveredEligible();
  const [phone, setPhone] = useState<any>();
  const { data,  errorMessage, callApi } : any = ApiWrapper(CoveredEligibleType);
  const [showMessage, setShowMessage ] = useState('');
  const {
    serviceNumber
  } = useContext(GlobalContext);

  // getting the phone number from the local storage and CoverEligible api
   async function asyncCall() {
    callApi()
    if (serviceNumber) {
      setPhone(JSON.parse(serviceNumber ?? ''));
    }
  }


  // getting the data or error message from the api
  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      const filterEnrollmentCode = "E";
      type Member = {
        enrollmentCode: string;
     };
      const filteredData = data.members.filter((member: Member) => member['enrollmentCode'] === filterEnrollmentCode);
      if (filteredData.length > 0) {
        setCoveredMembers(filteredData);
      } else {
        setShowMessage(t('noResults') || '');
      }
    } else if(errorMessage === Number(internalServerErrorCode)){
     setShowMessage(t('internalServerError') || '')
    }
  }, [data, errorMessage]);
  

  useEffect(() => {
    (async () => {
      await asyncCall();
    })();
  }, []);

  const [sortColumn, setSortColumn] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

  const handleSort = (column: string) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }
  };

  const sortedMembers = coveredMembers?.sort((a: any, b: any) => {
    const valueA = a[sortColumn];
    const valueB = b[sortColumn];

    if (valueA < valueB) {
      return sortOrder === 'asc' ? -1 : 1;
    }
    if (valueA > valueB) {
      return sortOrder === 'asc' ? 1 : -1;
    }
    return 0;
  });
  const deviceType = useDeviceType();
  const isMobile = deviceType === DeviceTypes.MOBILE;

  interface Member {
    memberID: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined;
    fullName: string;
    effectiveDate: string;
    dob: string;
  }

  const SortedMembersRows = (sortedMembers: Member[]) => {
    return sortedMembers?.map((item: Member, index: number) => {
      return (
        <tr key={`${item.memberID}`}>
          <td data-testid={`coveredMemberName_${index}`}>
            <strong>{toSentancePascalCase(item.fullName)}</strong>
          </td>
          <td data-testid={`coveredMemberId_${index}`}>
            {item.memberID}
          </td>
          <td data-testid={`coveredMemberEffectiveDate_${index}`}>
            {formatISODateToMDY(item?.effectiveDate)}
          </td>
          <td data-testid={`coveredMemberdob_${index}`}>
            {formatISODateToMDY(item?.dob)}
          </td>
        </tr>
      );
    });
  }
 
  const headerRows = [
    { text: (
      <Button
        dataTestId="coveredMemberNameButton"
        variant="sort-by white-color bg-transparent border-0 ps-0 m-0"
        onClick={() => handleSort('fullName')}
      >{t('yourPlan.label.memberName')}</Button>
    )},
    { text: (
      <Button
        dataTestId="coveredMemberIdButton"
        variant="sort-by white-color bg-transparent border-0 ps-0 m-0"
        onClick={() => handleSort('memberID')}
      >{t('yourPlan.label.memberId')}</Button>
        
    )},
    { text: (
      <Button
        dataTestId="coveredMemberEffectiveDateButton"
        variant="sort-by white-color bg-transparent border-0 ps-0 m-0"
        onClick={() => handleSort('effectiveDate')}
       >{t('yourPlan.label.effectiveDate')}</Button>
    )},
    { text: (
      <Button
        dataTestId="coveredMemberDobButton"
        variant="sort-by white-color bg-transparent border-0 ps-0 m-0"
        onClick={() => handleSort('dob')}
     >{t('yourPlan.label.memberDob')}</Button>
    )},
  ];

  return (
    <div className="benefits_provider">
      <div>
        <div className="heading-one-semibold mb-4">
          <Headings
            level={1}
            text={t('navigation.label.coveredEligibleMembers')}
            dataTestId="coveredEligibleMembersHeader"
          />
        </div>
        {!isMobile && sortedMembers?.length > 0 && (
          <div className="covered-members-table pb-2">
            <Table header={headerRows} rowsChildren={SortedMembersRows(sortedMembers)} />
          </div>
        )}

      {/* To show the internalServerError message */}
      <CommonErrorMessage message={showMessage} />

        {isMobile &&
          (sortedMembers?.length > 0 ? (
            <div className="accordion-table mb-4">
              <Accordion
                initialActiveIndex={0}
                items={
                  sortedMembers?.length > 0 &&
                  sortedMembers?.map((item: any, i: any) => ({
                    title: (
                      <div data-testid={`authNumberTab${i}`}>
                        <span className="font-sixteen">
                          {toSentancePascalCase(item.fullName)}
                        </span>
                      </div>
                    ),
                    content: (
                      <div>
                        <div
                          data-testid={`coveredMemberId${i}`}
                          className="redhat_bold"
                        >
                          {t('yourPlan.label.memberId')}
                        </div>
                        <div
                          data-testid={`coveredMemberId_${i}`}
                          className="mb-3"
                        >
                          {item.memberID}
                        </div>
                        <div
                          data-testid={`coveredEffectiveDate${i}`}
                          className="redhat_bold"
                        >
                          {t('yourPlan.label.effectiveDate')}
                        </div>
                        <div
                          data-testid={`coveredMemberEffectiveDate_${i}`}
                          className="mb-3"
                        >
                          {formatISODateToMDY(item.effectiveDate)}
                        </div>
                        <div
                          data-testid={`coveredMemberDob${i}`}
                          className="redhat_bold"
                        >
                          {t('yourPlan.label.memberDob')}
                        </div>
                        <div
                          data-testid={`coveredMemberdob_${i}`}
                          className="mb-3"
                        >
                          {formatISODateToMDY(item.dob)}
                        </div>
                      </div>
                    ),
                  }))
                }
              />
            </div>
          ) : (
            <div className="mb-4">
              <div className="text-center">{t('noResults')}</div>
            </div>
          ))}

          
        {phone !== undefined && phone?.data !== '' &&(
          <div className="mb-5">
            {t('coverageDetails.message.instruction')}{' '}
            <ContactInfo
              phoneData={{
                phone1800: phone?.data?.benefitPhone1800,
                phoneLocal: phone?.data?.benefitPhoneLocal,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CoveredEligibleMembers;