import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from 'context';
import { Navigate, MemberProxyData, setEncryptedValue, isGuest, getProxyDetails, Proxy, setLocalStorageBoolean } from 'common';
import { useNavigate } from 'react-router-dom';
import { useUpdateCSR, useGetProxyDataFromMember } from '@sentara/sentara-api-hooks-core';
import  {
  Button,
  Headings,
  ModalOverlayWithoutClose,
  Loader
} from '@sentaraui/optimahealth_web';

export interface ProxyConfirmProps {
  MemberFirstName?: string,
  MemberLastName?: string,
  ID?: string
}

const ProxyModal = (viewModalData: ProxyConfirmProps[]) => {
  const navigate = useNavigate();
  const {updateCSRPhi} = useUpdateCSR();
  const {GetProxyDataFromMember} = useGetProxyDataFromMember();
  const { setProxyUpdate, setViewModal, confirmProxy, setDesignatedDropdownLink,  loginMemberId, setMemberProxyData, setIsDesignatedRepresentative, loginProxyId} = useContext(GlobalContext);
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(true); 
  const [loading, setLoading] = useState<boolean>(false);
 
  


  
  // Deny API call to cancel the ProxyApproval
  const deny = async ()=>{
    setViewModal(false);
    setLoading(true);
    let data  = {
      ...confirmProxy[0],
      session: {
        PendingPopupOff: true
      }
    }

  let res = await updateCSRPhi(btoa(JSON.stringify(data)));
  if (res?.data?.Status === '200') {
   fetchData()
  } else {
    setLoading(false);
  }
}

  const fetchData = async ()=>{
    const guestValue: boolean = await isGuest();
    let data = {
      MemberId: guestValue ? atob(loginProxyId) : String(loginMemberId),
    };
    setEncryptedValue(MemberProxyData,JSON.stringify({}));
    const getProxyDataResponse  = await GetProxyDataFromMember(btoa(JSON.stringify(data)));
    if (getProxyDataResponse?.data) {
      handleGetProxyDataResponse(getProxyDataResponse?.data)
    } else {
      setLoading(false);
    }
  }

 
  const handleGetProxyDataResponse = (response:string) => {
    if (typeof response === 'string') {
      let decodedDataModal = JSON.parse(atob(response));
      let pendingProxyCountModal = decodedDataModal?.Result?.filter(
        (value: any) =>
        value?.CurrentStatus === getProxyDetails.confirmed ||
        value?.CurrentStatus === getProxyDetails.PENDING_FOR_APPROVAL
      );
      if (pendingProxyCountModal.length > 0)  {
        setLocalStorageBoolean(Proxy.DesignatedDropdownLink, true);
        setDesignatedDropdownLink(true);
        setMemberProxyData(decodedDataModal);
        setEncryptedValue(MemberProxyData,JSON.stringify(decodedDataModal));
      } else {
        setLocalStorageBoolean(Proxy.DesignatedDropdownLink, false);
        setDesignatedDropdownLink(false);
        setMemberProxyData({});
        setEncryptedValue(MemberProxyData,JSON.stringify({}));
      }}
      setLoading(false);
  };
  
  return (
    <>
      {loading && <Loader />} 
      <div className="modal_popup_login">
        <ModalOverlayWithoutClose
          isOpen={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
          overlayInfo="Modal information"
        >
          <div data-testid="designated-modal-content">
            <Headings
              className="redhat_regular font-twenty fw-700 mb-3 me-4"
              level={3}
              text={t('proxy.label.authorizationRequest')}
            />
            <p className="mb-0" data-testid="designated-representative-heading">
              {t('proxy.label.completeAuthorization')+ " "+ viewModalData[0]?.MemberFirstName+ " "+ viewModalData[0]?.MemberLastName}
            </p>
            <div className="d-flex flex-column flex-lg-row flex-md-row  align-items-center justify-content-center mt-3 gap-3">
              <Button
                dataTestId="maybeLaiterButton"
                className="primary_btn btn_block_mob"
                onClick={deny}
              >{t('proxy.label.maybeLater')}</Button>
              <Button
                dataTestId="confirmButton"
                className="primary_btn btn_block_mob"
                onClick={() => {
                  setIsDesignatedRepresentative(true);
                  setProxyUpdate(true);
                  navigate(Navigate.dashboard);
                  setViewModal(false);
                }}
              >{t('proxy.label.confirm')}</Button>
            </div>
          </div>
        </ModalOverlayWithoutClose>
      </div>
    </>
  );
};
export default ProxyModal;
