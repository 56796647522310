import { useEffect, useState,useContext } from 'react';
import { useMemberIDCard } from '@sentara/sentara-api-hooks-core';
import MemberIdCardFax from './MemberIdCardFax';
import MemberIdCardTable from './MemberIdCardTable';
import { errorhandler } from 'common';
import { Loader } from '@sentaraui/optimahealth_web';
import { GlobalContext } from 'context';
// Define the MembreIdCard component
function MemberIdCard() {

  const { getFeature
  } = useContext(GlobalContext);
 //  Initialize useTranslation hook
  const [loading, setLoading] = useState<any>(false);
  // Initializing the memberIDCardType hook to fetch member ID card data
  const { memberIDCardType } = useMemberIDCard();
  //  idCard to store the member ID card data 
  const [idCard, setIdCard] = useState<any>();
  // to track whether the model is open or not
  const [isOpen, setIsOpen] = useState(false);
  // to track whether the fax modal is open or not
  const [isOpenFax, setIsOpenFax] = useState(false);
  // Manages the visibility of buttons
  const [showBtn, setShowBtn] = useState<any>([]);

  //Function to fetch member ID card data list
  async function asyncCall() {
    const result = await memberIDCardType('memberidcard');
    if (result?.data !== null) {
      setIdCard(result?.data);
    } else if (result?.errorCode) {
      errorhandler(result?.errorCode);
    }
    setLoading(false);
  }

  // Fetch member ID card data on component mount
  useEffect(() => {
    setLoading(true);
    asyncCall();
  }, []);

  // Render the MemberIdCard component
  return (
    <>
      {loading && <Loader />}
      <div className="my_plan_provider member_id_card">
        <MemberIdCardTable
          mapState={idCard?.members}
          setIsOpenFax={() => {
            setIsOpenFax(true);
            setIsOpen(true);
          }}
          setShowBtn={setShowBtn}
          showBtn={showBtn}
          OrderMemberIDCard={getFeature?.OrderMemberIDCard}
          faxIDCard={getFeature?.faxIDCard}
        />
        {isOpenFax && (
          <MemberIdCardFax
            isOpen={isOpen}
            setIsOpen={() => setIsOpen(false)}
          />
        )}
      </div>
    </>
  );
}
export default MemberIdCard;
