import React from 'react';
import {Link} from '@sentaraui/optimahealth_web';

const _Link: React.FC<{
  link: string;
  text: string;
  styleClass?: string;
  dataTestId: string;
}> = ({ link, text, styleClass, dataTestId }) => (
  <Link
    to={link}
    target="_blank"
    rel="noopener"
    className={`text-decoration-none doc-links ${styleClass}`}
    data-testid={dataTestId}
  >
    {text}
  </Link>
);

export default _Link;