export type MyFeatureAttributeResponse = {
    memberKey: string,
    memberships: IMembership[],
    enrollmentCode: string,
    relationshipCode: string,
    age: any,
    emergencyTravelEligibility: boolean,
    carePlan: boolean,
    hsaPlan: boolean,
    insuranceGroup: {
        groupLob: string,
        groupId: string,
        benefitPlanLabel: string,
        planType: string,
        individualPlan: boolean,
        mDlive: boolean,
        benType: string,
        webVisionFlag: boolean
    },
    paidFirstMonthPremium: boolean,
    isSMGProvider: boolean,
    securityQuestionsFlag: boolean,
    hasPlanDocs: boolean
};

export interface IMembership {
  PTL_OPTIMA: string;
  PTL_NO_PERMISSIONS:string;
}

// getFeatureAttribute response data to be used in the launch darkly APP
export const getFeatureAttribute =  (response:MyFeatureAttributeResponse) => {
        function getUserInfo(res: any) {
          return res ?? '';
        }
        const user = {
        key: response?.memberKey ?? '',
        custom: {
          age: getUserInfo(response?.age),
          carePlan: getUserInfo(response?.carePlan),
          emergencyTravelEligibility: getUserInfo(
            response?.emergencyTravelEligibility
          ),
          enrollmentCode: getUserInfo(response?.enrollmentCode),
          benefitPlanLabel: getUserInfo(
            response?.insuranceGroup?.benefitPlanLabel
          ),
          groupId: getUserInfo(response?.insuranceGroup?.groupId),
          groupLob: getUserInfo(response?.insuranceGroup?.groupLob),
          individualPlan: getUserInfo(
            response?.insuranceGroup?.individualPlan
          ),
          mDlive: getUserInfo(response?.insuranceGroup?.mDlive),
          planType: getUserInfo(response?.insuranceGroup?.planType),
          memberships: getUserInfo(response?.memberships),
          relationshipCode: getUserInfo(response?.relationshipCode),
          hsaPlan: getUserInfo(response?.hsaPlan),
          hasPlanDocs: getUserInfo(response?.hasPlanDocs),
          isSMGProvider: getUserInfo(response?.isSMGProvider),
          webVisionFlag: getUserInfo(
            response?.insuranceGroup?.webVisionFlag
          ),
          benType: getUserInfo(response?.insuranceGroup?.benType),
        },
      };
      const memberId = user ? JSON.stringify(user) : '';
      return memberId;
}