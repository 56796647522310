import React from 'react';
import { t } from 'i18next';
import { PasswordChange,SecurityAnswer } from 'templates';
import { Collapsible } from '@sentaraui/optimahealth_web';
import { ProfileInformations, SecurityQuestion, SecurityQuestionProps } from './interface';
import { DeviceTypes, useDeviceType } from 'common';


const SecurityAnswerWithPassword = ({
  state,
  setState,
  securityQuestion,
  questionAnswer,
  setQuestionAnswer,
  changePassword,
  setChangePassword,
  expand = true,
  onExpand,
  onCollapse,
}: {
  securityQuestion: SecurityQuestionProps[];
  questionAnswer: SecurityQuestion[];
  setState: React.Dispatch<React.SetStateAction<ProfileInformations>>;
  setQuestionAnswer: React.Dispatch<React.SetStateAction<SecurityQuestion[]>>;
  state: ProfileInformations;
  changePassword: boolean;
  setChangePassword: React.Dispatch<React.SetStateAction<boolean>>;
  expand?: boolean;
  onExpand?: () => void;
  onCollapse?: () => void;
}) => {

    const deviceType = useDeviceType();
    const isMobile = deviceType === DeviceTypes.MOBILE;

    
    return (
      <Collapsible
      title={t('passwordSecurity')}
      id={'phoneNotifications'}
      isMobile={isMobile}
      key={`${'phoneNotifications'}-${isMobile ? 'mobile' : 'desktop'}`}
      expand={expand}
      onExpand={onExpand}
      onCollapse={onCollapse}
      className="mb-2 pb-2"
    >
       <div className="set_my_preferences">
        <div className="border_wrapper">
          <div className="user_address_wrapper">
            <div className="row">
              <div className="mb-2">
                <PasswordChange
                  state={state}
                  changePassword={changePassword}
                  setChangePassword={setChangePassword}
                />
              </div>
            </div>
            <hr />
  
            <div className="pt-2">
              <SecurityAnswer
                state={state}
                setState={setState}
                securityQuestion={securityQuestion}
                questionAnswer={questionAnswer} 
                setQuestionAnswer={setQuestionAnswer}
              />
            </div>
          </div>
        </div>
      </div>
      </Collapsible>
    );
  };
  

  export default SecurityAnswerWithPassword;