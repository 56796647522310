export type LinkTypeToAPIUrl = {
  [key: string]: string;
};

export const linkTypeToAPIUrl: LinkTypeToAPIUrl = {
  '1': 'CalculateTreatmentCostSSOUrl',
  '2': 'claimsandbalances',
  '3': 'WellnessToolSSOUrl?source=pha',
  '4': 'WellnessToolSSOUrl?source=phr',
  '5': 'WellnessToolSSOUrl?source=wellness',
  '6': 'MDLiveSSOUrl',
  '7': 'WellsFargo',
  '8': 'ChoiceStrategiesSSOUrl',
  '9': 'SeibelSSOUrl',
  'findadoc': 'HelathSparqOneSSOUrl',
  'managemyhsa': 'HealthEquitySSOUrl',
};