import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMedicalClaimTaxSummary } from '@sentara/sentara-api-hooks-core';
import { useTranslation } from 'react-i18next';
import {  dropdownValue, getDecryptedValue, UserDetails, ApiWrapper } from 'common';
import { Select, Loader } from '@sentaraui/optimahealth_web';


// Define the main functional component for the yeardropdown
interface YearDropdownProps {
  setMemberValue: (value: any) => void; // replace 'any' with the type of the value
  yearValue: string;
  setYearValue: (value: number) => void;
  fromMedical?: boolean;
}

const YearDropdown = ({
  setMemberValue, //A function to set the selected member's value
  yearValue, // The value of the selected tax year
  setYearValue, //A function to set the selected tax year
  fromMedical, //A boolean flag indicating if the component is used for medical claim tax summary
}: YearDropdownProps) => {
  // Localization
  const { MedicalClaimTaxSummaryType } = useMedicalClaimTaxSummary();
  const { t } = useTranslation();
  const { data:taxSummaryTypeData, apiError:taxSummaryTypeError, callApi:CallTaxSummaryTypeError } = ApiWrapper(MedicalClaimTaxSummaryType);

  
  const selectedOption = t('localStorage.selectedOption');
  let callAPi = 0;

  const currentYear: number = new Date().getFullYear();
  const previousToYear: number = currentYear - 1;

  //it initializes various state variables:

  //A boolean state to indicate whether the component is loading data
  const [loader, setLoader] = useState(false);

  //An object containing the current year and the previous year
  const [selectedYear, setSelectedYear] = useState({
    currentYear: currentYear,
    previousToYear: previousToYear,
  });

  //A function from React Router to handle navigation to a specific page
  const navigate = useNavigate();

  //It updates the selectedYear state and performs API calls to fetch the medical claim tax summary data based on the selected year
  const handleYearChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setSelectedYear({ ...selectedYear, [name]: value });
    setYearValue(e?.target?.value);
    if (yearValue) {
      localStorage.setItem(selectedOption, e?.target?.value);
    }
    callAPi = 1;
    dataFormat(value);
    if (fromMedical) {
      navigate(dropdownValue);
      setYearValue(e.target.value);
    }
  };

  //The 'useMedicalClaimTaxSummary' hook is used to get the required medical claim tax summary type

  const dataFormat = async (yearValue: string) => {
    const memberID = await getDecryptedValue(UserDetails.memberIdData);
    type PostDataType = {
      memberId: string; 
      year: string; 
    };

    const postData: PostDataType = {
      memberId: memberID,
      year: yearValue ?? currentYear,
    };
    if (memberID) {
    setLoader(true);
     CallTaxSummaryTypeError(postData);
    }
    
  };

  useEffect(() => {
    if (taxSummaryTypeData) {
      setMemberValue(taxSummaryTypeData);
      setLoader(false);
    } else if (taxSummaryTypeError) {
      setLoader(false);
    }
  }, [taxSummaryTypeData,taxSummaryTypeError]);

  
  //The 'useEffect' hook is used to fetch the medical claim tax summary data when the component mounts
  useEffect(() => {
    if (callAPi === 0) {
      const item = localStorage.getItem(selectedOption);
        if (item !== null) {
          dataFormat(item);
        }
    }
  }, []);

  const yearData = localStorage.getItem(selectedOption);

  //The 'dropdownData' function returns a localized string based on the selected tax year, which is used as the default value for the dropdown
  const dropdownData = () => {
    if (yearData === String(previousToYear)) {
      return t('claims.label.previousYear');
    } else if (yearData === String(currentYear)) {
      return t('claims.label.currentYear');
    } else {
      return t('claims.label.taxYear');
    }
  };

  return (
    <div className="year_dropdown_container">
      {loader ? <Loader /> : null}

      <Select
        dataTestId="viewTaxYearSummaryDropdown"
        className={'form-select'}
        id="year-dropdown"
        onChange={handleYearChange}
      >
        <option value="value" disabled selected hidden>
          {dropdownData()}
        </option>
        <option
          className="currentYear"
          data-testid="currentYear"
          value={selectedYear?.currentYear}
        >
          {t('claims.label.currentYear')}
        </option>
        <option
          className="previousYear"
          data-testid="previousYear"
          value={selectedYear?.previousToYear}
        >
          {t('claims.label.previousYear')}
        </option>
      </Select>
    </div>
  );
};
export default YearDropdown;