import React, { useContext, useEffect, useState } from 'react';
import {
  HamburgerMenuLink,
  HeaderLogo,
  HeaderTopNav,
  SearchLink,
  ContactUsLink,
  ChoosePlanType,
} from 'organisms';
import {
  useMembersPlan,
  useMyFeatureAttribute,
  useGetCSR,
  useGetProxyDataFromMember,
  useUpdateCSR,
  useFeaturePermission,
  useMySSOUrl,
  useMemberService
} from '@sentara/sentara-api-hooks-core';
import { GlobalContext } from 'context';
import Logout from '../logout/Logout';
import ProxyModal from './ProxyModal';
import {
  UserDetails,
  errorhandler,
  getProxyDetails,
  Proxy,
  MemberProxyData,
  Navigate,
  setLocalStorageBoolean,
  getLocalStorageBoolean,
  API_STATUS_SUCCESS,
  signIn,
  setEncryptedValue,
  deleteEncryptedValue,
  isGuest,
  linkTypeToAPIUrl,
  DynaTraceInit,
  getCache,
  getDecryptedValue
} from 'common';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getFeatureAttribute, MyFeatureAttributeResponse } from 'pages';
import {
  Loader,
  Link
} from '@sentaraui/optimahealth_web';


const Header: React.FC<any> = () => {
  const designatedRepresentative: boolean = getLocalStorageBoolean(Proxy?.DesignatedRepresentative);
  const { MyFeatureAttributeType } = useMyFeatureAttribute();
  const { getFeaturePermission } = useFeaturePermission();
  const { t } = useTranslation();
  const { setServiceNumber, isGuestSwitch, getFeature,userEncodedData, setUserEncodedData, setAge, confirmProxy, setDesignatedDropdownLink, designatedDropdownLink, setIsDesignatedRepresentative,
    isBacktoDashBoard,loginMemberId, age, setViewModal, viewModal, setPrimaryUser,setProxyData, setConfirmProxy, ssoLink,
    setProxyDropDown, setMemberProxyData, memberProxyData, setPlanName, setLoginMID, setLoginMemberId, setLoginProxyId, loginMID, loginProxyId, proxyUniqueId,setProxyUniqueId,proxyMemberId
  } = useContext(GlobalContext);
  const { SSOUrlType } = useMySSOUrl();
  const { membersPlan } = useMembersPlan();
  const {updateCSRPhi} = useUpdateCSR();
  const { MemberServiceType } = useMemberService(); /* useMemberService API call */
  const [planList, setPlanList] = useState<{ planName: string, planID: string| number, memberID: string | number }[]>([]);
  const [selectedplanType, setSelectedplanType] = useState('');
  const [proxyPending,setProxyPending] =  useState([]);
  const [proxyPendingFlag, setProxyPendingFlag] = useState<boolean>(false);
  const [memberID, setMemberID] = useState('');
  const [loading, setLoading] = useState(false);
  const [guestValue, setGuestValue] = useState<boolean>(false);
  const { getCSRPhi } = useGetCSR();
  const {GetProxyDataFromMember} = useGetProxyDataFromMember();
  const navigate = useNavigate();
  // payload for getmemberproydata API
  

  // payload for display the confirm modalpopup
  
  let uniqueKey = atob(proxyUniqueId || '').split('|');
  let memberKey = {
    ID: uniqueKey[2]
  };

  //get the SSO link from the url and call the getSSOParams function
  useEffect(() => {
    if(ssoLink){
    const link = ssoLink.split("=");
    getSSOParams(link[1])
  }
  }, [ssoLink]);

  //get the SSO url based on the link type
  const getSSOParams = (link: string) => {
      const apiUrl = linkTypeToAPIUrl[link];
      if (apiUrl) {
        getSSOUrl(apiUrl);
      }
  };

  const openSSOUrl = (url: string | undefined) => {
    window.open(url, '_blank');
  };

  //SSO get API call
  const getSSOUrl = async (type: string) => {
    const res = await SSOUrlType(type);
    if (res?.data?.ssoUrl) {
      openSSOUrl(res?.data?.ssoUrl);
    } else if (res?.errorCode) {
      errorhandler(res?.errorCode);
    }
  };
  
  DynaTraceInit();


  useEffect(() => {
    if (designatedRepresentative) {
      setIsDesignatedRepresentative(true)
    }else{
      setIsDesignatedRepresentative(false)
    }
  }, [designatedRepresentative]);

  useEffect(() => {
    (async () => {
      const isGuestUser: boolean = await isGuest();
      //call api and show member services number if LD toggle on
      if(getFeature?.MemberServicesNumbers && !isGuestUser) {
          const phoneNumber = await MemberServiceType(getCache());
          if (phoneNumber?.data !== undefined) {
            setServiceNumber(
              JSON.stringify(phoneNumber)
            );
          }
      }
  })();
  }, [getFeature?.MemberServicesNumbers]);

  const getMembersPlan = async () => {
    const response: any = await membersPlan();
    if (response?.data) {
      setPlanList(response?.data);
      const currentObject = response?.data?.filter(
        (item: { isDefault: boolean }) => item.isDefault === true
      );
      setSelectedplanType(currentObject[0]?.planName);
      setMemberID(currentObject[0]?.mId);
      setPrimaryUser(currentObject[0]?.relationCode);
      setPlanName(currentObject[0]?.planName);
      
    } else if (response?.errorCode) {
      errorhandler(response?.errorCode);
    }
  };
  const getFeaturePermissionAPI = async () => {
    //Pass Proxy ID if present
    setLoading(true)
    const response = await getFeaturePermission();
    if (response?.error) {
      setLoading(false)
     return // This function handles displaying the error message to the user
    } else {
      setLoginMID(response?.data?.mId ?? '');
      setLoginMemberId(atob(response?.data?.memberId?? ''));
      setLoginProxyId(response?.data?.proxyId ?? '');
    }
  };

  useEffect(() => {
    (async () => {
      const guestValue: boolean = await isGuest();
    if((loginMID === '' || loginMID === undefined) && !guestValue){
      getFeaturePermissionAPI();
    }
  })();
  }, [loginMID]);
  
  useEffect(() => {
    (async () => {
    const guestValue: boolean = await isGuest();
    setGuestValue(guestValue);
    if (!guestValue) {
      getMembersPlan();
    } else{
      setPlanList([{ planName: t('yourPlan.label.guest'), planID: "", memberID:"" }]);
      setSelectedplanType(t('yourPlan.label.guest') || "");
    }
  })();
  }, []);

  
  //getLaunchDarkly api to navigate to the dashboard screen
  const getLaunchDarkly = async () => {
      const response = await MyFeatureAttributeType();
      if(response?.errorCode !== undefined) { 
        localStorage.clear();
        deleteEncryptedValue();
        window.location.href = signIn;
      } else {
        setUserEncodedData(getFeatureAttribute(response?.data as MyFeatureAttributeResponse));
        setAge(response?.data?.age);
      }
      setLoading(false)
    } 

  // To call getLaunchDarkly API once userencodedata is empty
  useEffect(() => {
    (async () => {
      const guestValue: boolean = await isGuest();
      if ((!guestValue && userEncodedData?.includes("anonymous")) || (guestValue && proxyMemberId)) {
        getLaunchDarkly();
      }
    })();
  }, []);

  const handleCSRPhiResponse = (response:String)=>{
    if (typeof response === 'string') {
      let decodedData = JSON.parse(atob(response));
      let PendingPopupOff = decodedData?.Result?.filter(
        (item: any) => item?.Session?.PendingPopupOff === false
      );
      let pendingProxyCount = decodedData?.Result?.filter(
        (item: any) =>
          item?.CurrentStatus === getProxyDetails.confirmed || item?.CurrentStatus === getProxyDetails.PENDING_FOR_APPROVAL
      );

      if (PendingPopupOff.length > 0) setViewModal(PendingPopupOff);
      if (pendingProxyCount.length > 0)  {
        setProxyDropDown(true);
        setProxyData(decodedData);
      } else {
        setProxyDropDown(false);
        setProxyData({});
      }
    }
    else {
      setProxyDropDown(false);
      setProxyData({});
    }
  }
  const PendingProxyCount = (pendingProxyCount: any) => {
    return (
      pendingProxyCount.length > 0 &&
      pendingProxyCount.filter(
        (item: { CurrentStatus: string; Session: null }) => {
          if (
            item?.Session == null &&
            item?.CurrentStatus === getProxyDetails.PENDING_FOR_APPROVAL
          ) {
            return item;
          }
        }
      )
    );
  };
  const handleGetProxyDataResponse = async (response: string) => {

    const guestValue: boolean = await isGuest();
    if (typeof response === 'string') {
      let decodedData = JSON.parse(atob(response));
      let pendingProxyCount = decodedData?.Result?.filter(
        (item: any) =>
          item?.CurrentStatus === getProxyDetails.confirmed ||
          item?.CurrentStatus === getProxyDetails.PENDING_FOR_APPROVAL
      );
      // to show the confirm modalpopup based on Session 
      const pendingApproval = await PendingProxyCount(pendingProxyCount);
          if(pendingApproval.length === 1){
              setConfirmProxy(pendingApproval);
              setViewModal(true);
              
          } else {
              setConfirmProxy([]);
          }
      if (pendingProxyCount.length > 0)  {
        setLocalStorageBoolean(Proxy.DesignatedDropdownLink, true)
        setMemberProxyData(decodedData);
        setEncryptedValue(MemberProxyData,JSON.stringify(decodedData));
        setProxyPending(pendingProxyCount);
        if(guestValue) {
          //setting guest default view as dashboard
          setIsDesignatedRepresentative(true);
          setSelectedplanType(t('yourPlan.label.proxyAuthorization') || "");
        }
      } else {
        if(guestValue) {
          setSelectedplanType(t('yourPlan.label.guest') || "");
          navigate(Navigate.enrollBenefits);
        }
        setDesignatedDropdownLink(false);
        setLocalStorageBoolean(Proxy.DesignatedDropdownLink, false);
        setEncryptedValue(MemberProxyData,JSON.stringify({}));
        setMemberProxyData({});
      }
    }
    else {
      setLocalStorageBoolean(Proxy.DesignatedDropdownLink, false)
      setMemberProxyData([]);
    }
    setProxyPendingFlag(true);
  };


  const fetchData = async () => {
    setProxyDropDown(false);
    setProxyData({});
    setDesignatedDropdownLink(false);
    setLocalStorageBoolean(Proxy.DesignatedDropdownLink, false);
    setIsDesignatedRepresentative(false);
    setLocalStorageBoolean(Proxy.DesignatedRepresentative, false);
    setMemberProxyData([]);
    setEncryptedValue(MemberProxyData,JSON.stringify({}));
    const isGuestUser: boolean = await isGuest();
    let proxyId = isGuestUser ? atob(loginProxyId) : loginMemberId;
    let proxyPayload = {
      MemberId: proxyId,
    };
     //For guest user dont call the api
    if(!isGuestUser) {
      let data = {
        MemberId: loginMemberId,
      };
      const response = await getCSRPhi(data);
      if (response) {
        handleCSRPhiResponse(response);
      }
    }
    if(proxyId !== ''){
          if(isGuestUser){
            setLoading(true)
          }
          const getProxyDataResponse = await GetProxyDataFromMember(btoa(JSON.stringify(proxyPayload)));
          if (getProxyDataResponse?.data) {
          handleGetProxyDataResponse(getProxyDataResponse?.data)
          }
          if(isGuestUser){
            setLoading(false)
          }
    }
  };
  useEffect(() => {
    // Call the asynchronous function
    (async () => {
      const guestValue: boolean = await isGuest();
      if(!window.location.pathname.includes('profile')){
        if(!guestValue){
          if (age > 18 && !proxyMemberId &&  loginMemberId !=="" && !isBacktoDashBoard) {
            fetchData();
          }
        } 
        else {
          if (!proxyMemberId && !isGuestSwitch && !isBacktoDashBoard) {
            fetchData();
          }
        }
      }
    })();
  }, [age,loginMemberId,proxyMemberId,loginProxyId ]);

  const getProxyID = async  () => {
      const response:String = await getCSRPhi(memberKey) || '';
      setProxyPendingFlag(false)
      memberKey.ID = "";
      const isGuestUser: boolean = await isGuest();
      let proxyId = isGuestUser ? atob(loginProxyId) : loginMemberId;
      // getting memberlist to show the confirm modal pop based on memberlist api and proxymemberAPI
      let memberList = typeof response == 'string' ? JSON.parse(atob(response)) : [];
          memberList  = memberList?.Result?.length > 0  && memberList?.Result[0] && memberList?.Result[0]?.CurrentStatus === getProxyDetails.PENDING_FOR_APPROVAL ? memberList : [];
          if(memberList?.Result?.length > 0){
          const pendingResult =  proxyPending?.filter((item: { MemberId: string; ProxyId: string }) => item?.MemberId === memberList?.Result[0]?.MemberId &&  item?.ProxyId === proxyId) || [];
          if(!pendingResult.length && String(loginMemberId) !== memberList?.Result[0]?.MemberId){
            const updateProxyId = {...memberList?.Result[0], ProxyId:proxyId }
              let memberListUpdate =  await updateCSRPhi(btoa(JSON.stringify(updateProxyId)))
              if(memberListUpdate?.data?.Status === API_STATUS_SUCCESS){
                let updatedData = [];
                  updatedData = [...(memberProxyData?.Result?memberProxyData?.Result:[]),...[updateProxyId]];
                  const result =  updatedData?.filter((item:{CurrentStatus:string, Session: null}) => {
                  if(item?.CurrentStatus === getProxyDetails.PENDING_FOR_APPROVAL &&  item?.Session == null){
                    return item
                  }
                })
                if(result?.length === 1){
                  setConfirmProxy(result);
                  setViewModal(true);
                  setProxyUniqueId('');
                } else {
                  setConfirmProxy([])
                }

            }
          } 
      }
     }

    //To call Memberlist API once memberkey and proxypending values updated
   useEffect(() => {
    if(proxyPendingFlag && memberKey.ID !== '' && memberKey.ID !== undefined){
      getProxyID();
    }
  }, [proxyPendingFlag, memberKey]);

  const shouldShowicon = true;

  const setCookie = () => {
    document.cookie = `auth_token=${localStorage.getItem(
      UserDetails.authToken
    )}`;
  };
  useEffect(() => {
    return setCookie();
  }, []);


  return (
    <>
    
      {loading && <Loader />}
      {/* Skip to Main Content link */}
      <Link to="#main-content" className="skip-link">
        {t('accessibility.message.skipToMainContent')}
      </Link>
      <header className="not-print">
        <div className="border-bottom d-none d-xl-block d-lg-block">
          <div className="container-fluid">
            <HeaderTopNav />
          </div>
        </div>
        <div className="">
          <div className="container-fluid">
            <div>
              <nav className="d-flex align-items-center justify-content-between pt-3 mb-4 mt-1 pb-2">
                <div>
                  <HeaderLogo />
                </div>
                <div>
                  <div className="d-flex flex-no-wrap align-items-center">
                    {getFeature?.ContactUs && !guestValue && (
                      <div className="d-none d-xl-block d-lg-block d-md-none ms-4">                        
                        <ContactUsLink memberID={loginMID} showIcon={shouldShowicon} />
                      </div>
                    )}

                    <div className="d-none d-xl-block d-lg-block d-md-none ms-4">
                      <Logout />
                    </div>
                    <div className="d-lg-none d-md-block d-sm-block">
                      <SearchLink />
                    </div>
                    <div className="d-lg-none d-md-block d-sm-block">
                      <HamburgerMenuLink showLogout />
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
          <hr className="border-w-2 d-block d-lg-none mb-2" />
          {guestValue && <hr className="d-block d-md-none d-lg-block mt-0" />}
          {(planList.length > 0 || designatedDropdownLink)&& (
            <>
              <div className="container-fluid pt-1 pb-1">
                <div className="d-flex justify-content-xl-end justify-content-lg-end justify-content-md-end justify-content-center mb-2">
                  <ChoosePlanType
                    selectedplanType={selectedplanType}
                    planList={planList}
                    setSelectedplanType={setSelectedplanType}
                    memberID={memberID}
                  />
                </div>
              </div>
              <hr className="d-block d-md-none d-lg-block mt-0" />
            </>
          )}
        </div>
      </header>
      {viewModal && <ProxyModal {...confirmProxy} />}
    </>
  );
};
export default Header;


