import { useEffect, useState, useContext } from 'react';
import {Header} from 'pages';
import {
  FooterNav,
  FooterDisclaimer,
  SideMenu,
  SideMenuRoutes,
  IdleTimeoutContainer
} from 'organisms';
import { useLocation } from 'react-router-dom';
import { Navigate, UserDetails, getDecryptedValue } from 'common';
import { GlobalContext } from 'context';
import Chatbot from '../chatbot/Chatbot';
import {useChattest } from '@sentara/sentara-api-hooks-core';
import { ChatIcon, Button, ChatCircleDotIcon, ChatDotIcon }from '@sentaraui/optimahealth_web';

interface SummaryProps {
  hasNewMessage: boolean;
  isSessionActive: boolean;
  isSessionEnded: boolean;
}
function HomePage() {
  let intervalHandlerId: NodeJS.Timer;
  // Extracts pathname property(key) from an object
  const { pathname } = useLocation();
  const { ChattestType } = useChattest();
  const { getFeature } = useContext(GlobalContext);
  const [showChat, setShowChat] = useState(false);
  const [reset, setReset] = useState(false);
  const [minimizeChat, setMinimizeChat] = useState(false);
  const [unreadCount, setUnreadCount] = useState('0');

  const [summary, setSummary] = useState<SummaryProps>({
    hasNewMessage: false,
    isSessionActive: false,
    isSessionEnded: false,
  });

  const unread = localStorage.getItem('unreadCount') || '0';
  const timerStarted = localStorage.getItem('timerstarted') ?? '';
  const authToken = localStorage.getItem('sessionId') || '';
  
  
  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);    
  }, [pathname]);

  const openChat = () => {
    if(minimizeChat){
      setMinimizeChat(false);
    }else{
      setShowChat(true);
    }
  };

  const closeChat = () => {
    setShowChat(false);
    setReset(false);
    setMinimizeChat(false);
  };

  const onMinimize = () => {
    setMinimizeChat(true);
  };
  const refreshBot = () => {
    setReset(true);
    setShowChat(false);
  };
  useEffect(() => {
    if (!showChat && authToken.length > 0) {
      intervalHandlerId = setInterval(() => {
        checkNewMessage();
      }, 5000);
    }
    return () => clearInterval(intervalHandlerId);
  }, [!showChat, authToken]);
  useEffect(() => {
    if (!showChat) {
      if (unread) {
        setUnreadCount(unread);
      }
    }
  }, [timerStarted, unread, !showChat]);

  const checkNewMessage = async () => {
    let timestamp = new Date().getTime();
    const userId = await getDecryptedValue(UserDetails.usedId) || '';

    let postData = {
      userId: btoa(userId),
      timestamp: timestamp,
      sessionId: authToken,
    };
    const result = await ChattestType(postData,"chat");
    if (result) {
      const {
        hasNewMessage = false,
        isSessionActive = false,
        isSessionEnded = false,
      } = result?.data || {};
      setSummary({ hasNewMessage, isSessionActive, isSessionEnded });
    }
  };
  return (
    <>
      <div className="d-flex flex-column h-100">
        <div>
          <Header />
          <div className="container-fluid">
            <div className="row">
              {pathname !== Navigate.profile ? (
                <>
                  {' '}
                  <div className="col-lg-4 col-md-4 col-sm-12 col-12 d-none d-lg-block mb-4 home_page">
                    <SideMenu />
                  </div>
                  <div
                    className="col-lg-8 col-md-12 col-sm-12 col-12 mt-3"
                    aria-live="polite"
                  >
                    <SideMenuRoutes />
                  </div>
                </>
              ) : (
                <div
                  className="col-lg-12 col-md-12 col-sm-12 col-12 mt-3 home_page"
                  aria-live="polite"
                >
                  <SideMenuRoutes />
                </div>
              )}
            </div>
          </div>
        </div>
        {getFeature?.OmniChannelLiveChat && (!showChat||minimizeChat) && (
          <div className="chatbot_icon">
            <Button onClick={openChat} data-testid={'openChatbot'} aria-label="Customer Care Healthbot">
              {!summary?.hasNewMessage && unreadCount === '0' && (
                <ChatIcon height={60} width={60} />
              )}
              {!summary?.hasNewMessage && unreadCount !== '0' && (
                <ChatCircleDotIcon height={60} width={60} />
              )}
              {summary?.hasNewMessage && unreadCount !== '0' && (
                <ChatDotIcon height={60} width={60} />
              )}
            </Button>
          </div>
        )}
        {showChat && (
          <div className={`${!minimizeChat? 'd-block' : 'd-none'}`}>
            <Chatbot
              setShowChat={closeChat}
              refreshBot={refreshBot}
              reset={reset}
              onMinimize={onMinimize}
              minimizeChat={minimizeChat}
            />
          </div>
        )}
        <footer className="mt-auto not-print">
          <FooterNav />
          <FooterDisclaimer />
        </footer>
      </div>
      <IdleTimeoutContainer></IdleTimeoutContainer>
      
    </>
  );
}
export default HomePage;