import { createContext, useEffect, useContext } from 'react';
import useLaunchDarkly, { clearLaunchDarklyCache } from "@platform/featuremanagement"
import { GlobalContext } from './ContextProvider';
import {
  Proxy,
  setLocalStorageBoolean
} from 'common';

export const FeatureToggleContext = createContext({} as any);

const FeatureToggleProvider = ({ children }: any) => {
  const { userEncodedData, getFeature, setFeature } = useContext(GlobalContext);
  const {apiData}=useLaunchDarkly(userEncodedData,process.env.REACT_APP_LAUNCH_DARKLY_KEY,1);
  clearLaunchDarklyCache();
  useEffect(() => {
    // get all features and respective flag for toggle from getAllFeatureFlags mock API
    const getFeaturesAndFlags = async () => {
      if (userEncodedData.length > 0 && Array.isArray(apiData)) {
        if (
          apiData &&
         apiData.length > 0 
        ) {
          let featureData: any = {};
          apiData.forEach((data:any) => {
            let key = Object.keys(data)[0];
            let values = Object.values(data)[0];
            featureData[key] = values || false;
          });
          setFeature((prev: any) => ({ ...prev, ...featureData }));
          setLocalStorageBoolean(Proxy.updateFeature, true)

        } else {
          // silently fallback to the initial state
        }
      }
    };
    getFeaturesAndFlags();
  }, [apiData, userEncodedData]);
  return (
    <FeatureToggleContext.Provider value={getFeature}>
      {children}
    </FeatureToggleContext.Provider>
  );
};

export default FeatureToggleProvider;
