import React from 'react';
import {
    TilesIds,
    Proxy,
    apiResponseSafeCheck
} from 'common';
import {
    DoctorIcon,
    ClaimsIcon,
    IdCardIcon,
    SnapShotIcon,
    ProfileIcon,
    VirtualVisitIcon,
    MailIcon,
    PayMyPremiumIcon,
    UrgentCareSearchIcon,
    HealthReimbursementIcon,
    MyPlanIcon,
    FormsAndDocsIcon,
    AuthorizationsIcon,
    HealthSavingsIcon,
  } from '@sentaraui/optimahealth_web';


interface Tile {
    name: string;
    icon: React.JSX.Element;
    id: string;
}





/*** Generate Tiles, return the array of  tiles which needs to be displayed in the dashboard based on launchDarkly*/
export const GenerateTiles = (homeTiles: string[], getFeature:Record<string, any>, t: (key:string)=>string): Tile[] => {
    let tilesArray: Tile[] = [];
    let proxyId = localStorage.getItem(Proxy.MemberId);
    //compare homeTiles with LaunchDarkly
    homeTiles.forEach((tileName: string) => {
      if (tileName === TilesIds.findDoctor && getFeature?.FindADoctor) {
        tilesArray.push({
          name: t('home.label.findDoctorsProviders'),
          icon: <DoctorIcon />,
          id: TilesIds.findDoctor,
        });
      } else if (
        tileName === TilesIds.viewClaims &&
        getFeature?.ViewClaims
      ) {
        tilesArray.push({
          name: t('home.label.viewRecentClaims'),
          icon: <ClaimsIcon />,
          id: TilesIds.viewClaims,
        });
      } else if (tileName === TilesIds.ViewIDCard && getFeature?.MemberidCard) {
        tilesArray.push({
          name: t('home.label.viewDigitalID'),
          icon: <IdCardIcon />,
          id: TilesIds.ViewIDCard,
        });
      } else if (
        tileName === TilesIds.BenefitsSnapshot &&
        getFeature?.Benefits
      ) {
        tilesArray.push({
          name: t('home.label.benefitsSnapshot'),
          icon: <SnapShotIcon />,
          id: TilesIds.BenefitsSnapshot,
        });
      } else if (tileName === TilesIds.MyProfile && getFeature?.MyProfile && !apiResponseSafeCheck(proxyId)) {
        tilesArray.push({
          name: t('home.label.viewProfile'),
          icon: <ProfileIcon />,
          id: TilesIds.MyProfile,
        });
      } else if (
        tileName === TilesIds.UrgentCareSearch &&
        getFeature?.UrgentCareSearch &&  !apiResponseSafeCheck(proxyId)
      ) {
        tilesArray.push({
          name: t('home.label.urgentCareSearch'),
          icon: <UrgentCareSearchIcon />,
          id: TilesIds.UrgentCareSearch,
        });
      } else if (tileName === TilesIds.ContactUs && getFeature?.ContactUs && !apiResponseSafeCheck(proxyId)) {
        tilesArray.push({
          name: t('home.label.customerSupportLink'),
          icon: <MailIcon width={40} height={34} />,
          id: TilesIds.ContactUs,
        });
      } else if (tileName === TilesIds.MyPlan && getFeature?.YourPlan) {
        tilesArray.push({
          name: t('home.label.myPlans'),
          icon: <MyPlanIcon />,
          id: TilesIds.MyPlan,
        });
      } else if (
        tileName === TilesIds.FormsAndDocuments &&
        getFeature?.FormsandDocuments
      ) {
        tilesArray.push({
          name: t('home.label.formsDocuments'),
          icon: <FormsAndDocsIcon />,
          id: TilesIds.FormsAndDocuments,
        });
      } else if (
        tileName === TilesIds.Authorizations &&
        getFeature?.Authorizations
      ) {
        tilesArray.push({
          name: t('home.label.authorizations'),
          icon: <AuthorizationsIcon />,
          id: TilesIds.Authorizations,
        });
      } else if (
        tileName === TilesIds.VirtualVisit &&
        getFeature?.MDLiveVirtualVisit && !apiResponseSafeCheck(proxyId)
      ) {
        tilesArray.push({
          name: t('home.label.virtualVisit'),
          icon: <VirtualVisitIcon />,
          id: TilesIds.VirtualVisit,
        });
      } else if (
        tileName === TilesIds.PayMonthlyPremium &&
        getFeature?.PayMonthlyPremium &&  !apiResponseSafeCheck(proxyId)
      ) {
        tilesArray.push({
          name: t('home.label.payMonthlyPremium'),
          icon: <PayMyPremiumIcon />,
          id: TilesIds.PayMonthlyPremium,
        });
      } else if (
        tileName === TilesIds.HealthReimbursementAccount &&
        getFeature?.ManageHealthReimbursementAccount && !apiResponseSafeCheck(proxyId)
      ) {
        tilesArray.push({
          name: t('home.label.healthReimbursementAccount'),
          icon: <HealthReimbursementIcon />,
          id: TilesIds.HealthReimbursementAccount,
        });
      } else if (
        tileName === TilesIds.HealthSavingsAccount &&
        getFeature?.ManageHSA && !apiResponseSafeCheck(proxyId)
      ) {
        tilesArray.push({
          name: t('home.label.healthSavingsAccountBalance'),
          icon: <HealthSavingsIcon />,
          id: TilesIds.HealthSavingsAccount,
        });
      }
    });
    tilesArray = tilesArray.slice(0, 6);
    return tilesArray;
  };

