import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { FooterDisclaimer } from 'organisms';
import ProxyHeader from './ProxyHeader';
import { PROXY_AUTHENTICATION,
  PROXY_EXPIRED_URL,
  PROXY_REGISTER_URL,
  getProxyDetails,
} from 'common';
import { IProps } from './Interface';
import {getProxyDetailsCommon} from "./CSRGuardianRevoke"
import {PageBox, CSRProxy } from '@sentara/caregiver';


const CSRGuardianUpdate = () => {
  const [memberDetails, setMemberDetails] = useState<IProps>({});
  const navigate = useNavigate();
  let serverMemberDetails: IProps;
  const expirePage = PROXY_EXPIRED_URL;
  const sessionData = sessionStorage.getItem(getProxyDetails.proxyAuthorizationData);

  useEffect(() => {
    if (sessionData) {
      serverMemberDetails = JSON.parse(atob(sessionData));
      if (
        serverMemberDetails.CurrentStatus !== getProxyDetails.confirmed &&
        serverMemberDetails.RequestFor !== getProxyDetails.update
      ) {
        navigate(PROXY_REGISTER_URL);
        return;
      }
      setMemberDetails(serverMemberDetails);
    } else {
      navigate(expirePage);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const callBackFunc = (type: string) => {
    if ([getProxyDetails.delete, getProxyDetails.canceled].includes(type)) {
      sessionStorage.removeItem(getProxyDetails.proxyAuthorizationData);
    }
    if (type === getProxyDetails.canceled) {
      navigate(
        `${PROXY_AUTHENTICATION}?${getProxyDetails.key}=${memberDetails.ID}`
      );
    }
  };

  return (
    <div className="d-flex flex-column h-100">
      <ProxyHeader />
      <div className="flex-1">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-8 col-sm-12 col-12">
              <div className="proxy_form proxy_form_disabled csr_update_wrapper pt-4 mt-3">
                <PageBox border={false}>
                  {memberDetails &&
                    memberDetails.RequestFor === getProxyDetails.update && (
                      <CSRProxy
                        flow={'csr-update'}
                        {...getProxyDetailsCommon(callBackFunc, memberDetails)}
                      />
                    )}
                </PageBox>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer section */}
      <footer className="d-none d-lg-block d-md-block mt-auto pt-4">
        {/* Component to display the footer disclaimer */}
        <FooterDisclaimer />
      </footer>
    </div>
  );
};
export default CSRGuardianUpdate;


