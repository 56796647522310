import { PrintIcon, DownloadIcon } from '@sentaraui/optimahealth_web';
import type React from 'react';
export type PDFViewerOverrideProps = {
  backButtonText: string;
  DownloadIcon?: () => React.JSX.Element | null;
  downloadLabel?: string | null;
  PrintIcon?: () => React.JSX.Element | null;
  printLabel?: string | null;
};

const Download = () => {
  return <DownloadIcon color="black" data-testid="download-icon"/>;
};

const Print = () => {
  return <PrintIcon data-testid="print-icon"/>;
};


export const pdfViewerDefaultProps = (
  t: (id: string) => string
): PDFViewerOverrideProps => {
  return {
    backButtonText: t('memberIDCard.idCardPdf.back'),
    DownloadIcon: Download,
    downloadLabel: t('memberIDCard.idCardPdf.download'),
    PrintIcon: Print,
    printLabel: t('memberIDCard.idCardPdf.print'),
  };
};
