import React from 'react';
import GetStarted from './GetStarted';
import PersonalInformation from './PersonalInformation';
import EmailVerification from './EmailVerification';
import UsernamePassword from './UsernamePassword';
import SecurityQuestion from './SecurityQuestion';
import ActivationSuccess from './ActivationSuccess';

// Define component array outside of the RegistrationSteps component
const formComponents = [
  GetStarted,
  PersonalInformation,
  EmailVerification,
  UsernamePassword,
  SecurityQuestion,
  ActivationSuccess,
];

interface RegistrationStepsProps {
  page: number;
  setPage: (page: number) => void;
  formData: any;
  setFormData: (data: any) => void;
  setPlantypes: (types: any) => void;
  plantypes?: string;
  item: any;
  guest: boolean;
  t: (key: string) => string; // Add proper typing for the translation function
  FormTitles: string[];
}

const RegistrationSteps = ({
  page,
  setPage,
  formData,
  setFormData,
  setPlantypes,
  plantypes,
  item,
  guest,
  t,
  FormTitles,
}: RegistrationStepsProps) => {
  return (
    <div aria-live="polite">
      {/* Render the RegistrationStepsComponent */}
      {
        <RegistrationStepsComponent
          page={page}
          setPage={setPage}
          formData={formData}
          setFormData={setFormData}
          setPlantypes={setPlantypes}
          plantypes={plantypes !== '' ? plantypes : 'guest'}
          item={item}
          guest={guest}
          t={t}
          FormTitles={FormTitles}
        />
      }
    </div>
  );
};

const RegistrationStepsComponent = ({
  page,
  setPage,
  formData,
  setFormData,
  setPlantypes,
  plantypes,
  item,
  guest,
}: RegistrationStepsProps) => {
  const CurrentComponent = formComponents[page];

  return (
    <div className="page_container">
      <CurrentComponent
        setPage={setPage}
        page={page}
        formData={formData}
        setFormData={setFormData}
        setPlantypes={setPlantypes}
        plantypes={plantypes}
        item={item}
        guest={guest}
      />
    </div>
  );
};

export default RegistrationSteps;
