import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import {
  ProfileImage,
  PhoneNotifications,
  LanguagePreference,
  EmailPaperless,
  DeleteProfile,
  SecurityAnswerWithPassword,
} from 'templates';

import { Button } from '@sentaraui/optimahealth_web';

import { ProfileDetailsProps } from './interface';
import { DeviceTypes, useDeviceType } from 'common';

function ProfileDetails({
  state,
  setState,
  getPhone,
  userAddress,
  childrenstate,
  userPhone,
  securityQuestion,
  questionAnswer,
  setQuestionAnswer,
  changePassword,
  setChangePassword,
  setUserAddress
}: ProfileDetailsProps) {
  const NUMBER_OF_COLLAPSIBLE_COMPONENTS = 4;
  // Default collapse all elements
  const [expandedState, setExpandedState] = useState<Array<boolean>>(
    new Array(NUMBER_OF_COLLAPSIBLE_COMPONENTS).fill(false)
  );

  const [expandAll, setExpandAll] = useState<boolean>(false);

  const deviceType = useDeviceType();
  const isMobile = deviceType === DeviceTypes.MOBILE;

  useEffect(() => {
    const shouldExpandAll = expandedState.every((value) => value === true);
    setExpandAll(shouldExpandAll);
  }, [expandedState]);

  const expandCollapse = () => {
    if (expandAll) {
      setExpandedState(new Array(NUMBER_OF_COLLAPSIBLE_COMPONENTS).fill(false));
    } else {
      setExpandedState(new Array(NUMBER_OF_COLLAPSIBLE_COMPONENTS).fill(true));
    }
  };

  const onExpand = (index: number) => {
    setExpandedState((expandedState) => {
      const temp = [...expandedState];
      temp[index] = true;
      return temp;
    });
  };

  const onCollapse = (index: number) => {
    setExpandedState((expandedState) => {
      const temp = [...expandedState];
      temp[index] = false;
      return temp;
    });
  };

  return (
    <>
      {/* Renders the profile image component */}
      <ProfileImage
        state={state}
        userAddress={userAddress}
        childrenstate={childrenstate}
        getPhone={getPhone}
        setUserAddress={setUserAddress}
      />

      {isMobile ? (
        <div className={'profile_expand_collapse_btn'}>
          <Button
            type="button"
            dataTestId={!expandAll ? 'expandAll' : 'collapseAll'}
            children={
              !expandAll
                ? t('settings.label.expandAll')
                : t('settings.label.collapseAll')
            }
            variant={'secondary_btn btn_block_mob'}
            onClick={expandCollapse}
          />
        </div>
      ) : null}

      {/* Renders the phone notifications component */}
      <PhoneNotifications
        key={`phoneNotifications-${expandAll ? 'expanded' : ''} `}
        userPhone={userPhone}
        smsConsent={state.smsConsent}
        setState={setState}
        state={state}
        expand={expandedState[0]}
        onExpand={() => onExpand(0)}
        onCollapse={() => onCollapse(0)}
      />

      {/* Renders the email paperless component */}
      
      <EmailPaperless
        key={`emailPaperless-${expandAll ? 'expanded' : ''} `}
        state={state}
        expand={expandedState[1]}
        setState={setState}
        onExpand={() => onExpand(1)}
        onCollapse={() => onCollapse(1)}
      />

      {/* Renders the language preference component */}
      <LanguagePreference
        key={`languagePreferences-${expandAll ? 'expanded' : ''} `}
        languagePreference={state.languagePreference ?? ''}
        expand={expandedState[2]}
        onExpand={() => onExpand(2)}
        onCollapse={() => onCollapse(2)}
        state={state}
        setState={setState}
      />
    
      {/* Renders the security answer with password component */}
      <SecurityAnswerWithPassword
        key={`securityQuestions-${expandAll ? 'expanded' : ''} `}
        state={state}
        setState={setState}
        securityQuestion={securityQuestion}
        questionAnswer={questionAnswer}
        setQuestionAnswer={setQuestionAnswer}
        changePassword={changePassword}
        setChangePassword={setChangePassword}
        expand={expandedState[3]}
        onExpand={() => onExpand(3)}
        onCollapse={() => onCollapse(3)}
      />

      {/* Renders the delete profile component */}
      <DeleteProfile />
    </>
  );
}

export default ProfileDetails;
