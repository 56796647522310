import { HasNumber, HasUpperCase, HasLowerCase, HasSpecialCart } from 'common';

//This function used to identify strength of the password. As per below illustration:
//M, m, 1, @, mm, MM --- 1
//Mm, m@, m2,  --- 2
//M@m, m2@, mM2, Member@, Member2  --- 3
//Member@2 --- 4

// This function sets the strength bar color based on the password value
export const setStrengthBarColor = (
  value: string,
  setPwdStrength: React.Dispatch<React.SetStateAction<number>>
) => {
  const strengthByCharacter = [
    HasNumber, // Number
    HasUpperCase, // Uppercase letter
    HasLowerCase, // Lowercase letter
    HasSpecialCart, // Special character
  ];

  let strength = 0;
  for (const regex of strengthByCharacter) {
    if (regex.test(value)) {
      strength++;
    }
  }

  if (strength === 4 && value.length < 8) {
    strength = 3;
  }

  setPwdStrength(strength);
};
