import React from 'react';
import Link from './link/Link';

const DocumentLink: React.FC<{
  link: string;
  text: string;
  styleClass?: string;
  dataTestId: string;
}> = ({ link, text, styleClass, dataTestId }) => {
  return (
    <li className={`redhat_bold fw-700 pb-4 pt-4 ${
      text === 'Contact Us' ? 'd-none' : ''
    }`}>
      <Link
        link={link}
        text={text}
        styleClass={styleClass}
        dataTestId={dataTestId}
      />
    </li>
  );
};
export default DocumentLink;