import { GlobalContext } from 'context';
import { UserDetails, getDecryptedValue, Proxy } from 'common';

import React, { useState, createContext, useContext,  useMemo, useCallback, useEffect } from 'react';

type AuthProviderProps =  {
  children: React.ReactNode;
  token?: string | null;
}

interface AuthContextType {
  user: string | null;
  login: (user: string | null) => void;
  logout: () => void;
}

export const HOCAuthToken = <P extends object>(AuthTokenWrapper: React.ComponentType<P>) => {
  return function SetAuthComponent(props:AuthProviderProps) {
    const [token, setToken] = useState<string>("")
    const [isCallMade, setIsCallMade] = useState(false)
    const {setAuthToken,  setProxyMemberId}   = useContext(GlobalContext);

    useEffect(() => { 
      const callApi = async() => {
        const authToken = await getDecryptedValue(UserDetails.authToken);
        const proxyId = await getDecryptedValue(Proxy.MemberId); 
          setToken(authToken)
          setAuthToken(authToken)
          setProxyMemberId(proxyId)
          setIsCallMade(true)
      }
      callApi()
    }, [])
      return isCallMade ? <AuthTokenWrapper {...{...props as P, token}}/> : null;
  }
}

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider = HOCAuthToken(({ children, token }: AuthProviderProps) => {
  const [user, setUser] = useState<string | null>(token ?? null);
  
  const login = useCallback((user: string | null) => {
    setUser(user);
  }, []);

  const logout = useCallback(() => {
    setUser(null);
  }, []);

  const authContextValue = useMemo(() => ({
    user,
    login,
    logout,
  }), [user, login, logout]);

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
});

export const useAuth = () => {
  return useContext(AuthContext);
};
