import ProxyHeader from './ProxyHeader';
import { FooterDisclaimer } from 'organisms';
import {ProxyExpiredForm } from '@sentara/caregiver';


const ProxyExpiredFormWrapper = (): React.JSX.Element => {
  return (
    <div className="d-flex flex-column h-100">
      <ProxyHeader />
      <div className="flex-1">
        <div className="proxy_form proxy_form_disabled proxy_expired_wrapper">
          <div data-testid="CSRProxy">
            <div>
              <div
                className="container-fluid mb-6 optima-health--proxy"
                id="proxyMainFrame"
              >
                <div className="row justify-content-center">
                  <div className="col-lg-6 col-md-8 col-sm-12 col-12">
                    <div className="proxy_form_wrapper">
                      <div className="expired_wrapper text-center">
                        <ProxyExpiredForm
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="mt-5">
        <FooterDisclaimer />
      </footer>{' '}
    </div>
  );
};

export default ProxyExpiredFormWrapper;
