import React, { useState, useEffect } from 'react';
import { t } from 'i18next';
import {
  Button,
  Collapsible,
  ModalOverlay,
  Paragraph,
} from '@sentaraui/optimahealth_web';
import {
  ProfileHeaderContent,
  EmailChange,
  ProfileInformations,
  PaperlessCommunications,
} from 'templates';
import { DeviceTypes, useDeviceType } from 'common';

const EmailPaperless = ({
  state,
  expand = true,
  setState,
  onExpand,
  onCollapse,
  memberView = false
}: {
  state: ProfileInformations;
  setState: React.Dispatch<React.SetStateAction<ProfileInformations>>;
  expand?: boolean;
  onExpand?: () => void;
  onCollapse?: () => void;
  memberView?: boolean;
}) => {
  const [showEdit, setShowEdit] = useState(true);
  const [showPaperlessEdit, setPaperlessEdit] = useState(true);
  const [showPaperless, setShowPaperless] = useState(true);
  const [showEmailSuccess, setShowEmailSuccess] = useState(false);
  const [email, setEmail] = useState<string>('');

  useEffect(() => {
    setEmail(state?.emailConsent?.email ?? '');
  }, [state?.emailConsent]);

  // Success Message for Email and Paperless Update
  const handleSuccess = (email: string) => {
    setShowEdit(true);
    setEmail(email);
    setShowEmailSuccess(true);
  };

  const deviceType = useDeviceType();
  const isMobile = deviceType === DeviceTypes.MOBILE;

  function closeEmailSuccessPopUp() {
    setShowEmailSuccess(false);
    setPaperlessEdit(true)
  }

  return (
    <Collapsible
      data-testid="email-paperless-component"
      title={memberView ? t('settings.label.emailAddress') : t('emailPaperless')}
      id={'emailPaperless'}
      isMobile={isMobile}
      key={`${'emailPaperless'}-${isMobile ? 'mobile' : 'desktop'}`}
      expand={expand}
      onExpand={onExpand}
      onCollapse={onCollapse}
      className="mb-2 pb-2"
    >
      <div className="set_my_preferences">
        <div className="border_wrapper">
          <div className="user_address_wrapper">
            {showEdit ? (
              <>
                <ProfileHeaderContent
                  data-testid="profile-header-component"
                  header={t('settings.label.emailAddress')}
                  contentOne={email ?? ''}
                  dataTestIdOne="profileName"
                  contentClassNameOne={'_disabled mb-2'}
                  disableButton={!state?.isMemberEnrolled}
                  showButton={true}
                  onClick={() => {
                    setShowEdit(!showEdit);
                    setShowEmailSuccess(false);
                  }}
                />
              </>
            ) : (
              <EmailChange
                emailId={email ?? ''}
                onClickCancel={() => setShowEdit(!showEdit)}
                handleSuccess={handleSuccess}
                memberView={memberView}
              />
            )}
           

            {!memberView ? 
            <>
             <hr />
            <div className={`col-sm-12 col-12 ${state?.paperlessConsent?.optInConsent && showPaperlessEdit ? "col-md-12 col-lg-12" : "col-md-8 col-lg-8"}`}>
              <ProfileHeaderContent
                header={t(
                  'setMyPreferences.description.paperlessCommunications'
                )}
                contentOne={state?.paperlessConsent?.optInConsent && showPaperless? "" : t('setMyPreferences.description.goPaperlessDesc')}
                dataTestIdOne="profileName"
                className="mt-3"
                showButton={state?.paperlessConsent?.optInConsent && showPaperlessEdit}
                onClick={() => {
                   setShowPaperless(!showPaperless);
                   setPaperlessEdit(!showPaperlessEdit);
                  }}
                contentClassNameOne={'pt-2'}
                disableButton={!state?.isMemberEnrolled}
              />
              {showPaperless && !state?.paperlessConsent?.optInConsent ? (
                <div className="col-lg-auto col-md-auto col-sm-12 col-12 mt-4">
                  <Button
                    type="button"
                    dataTestId={'cancelButtonLess'}
                    children={t('setMyPreferences.label.goPaperlessTitle')}
                    variant={'secondary_btn btn_block_mob go_paperless_btn'}
                    onClick={() => {
                      setShowPaperless(!showPaperless);
                    }}
                    disabled={!state?.isMemberEnrolled}
                  />
                </div>
              ) : null}
              {!showPaperless ? (
                <PaperlessCommunications
                  setState={setState}
                  state={state}
                  onClickCancel={() => {
                    setShowPaperless(!showPaperless);
                    setPaperlessEdit(true)
                  }}
                  handleSuccess={handleSuccess}
                />
              ) : null}
            </div> 
            </>
            : null}
            
          </div>
          {showEmailSuccess && (
            <ModalOverlay
              isOpen={showEmailSuccess}
              onClose={closeEmailSuccessPopUp}
              overlayInfo="Modal information"
            >
              <div data-testid="showEmailSuccessPopUp">
                <Paragraph className="mb-4 pb-2">
                  {t('settings.message.emailSuccess')}
                </Paragraph>
                <div className="d-flex flex-lg-row flex-md-row flex-column gap-lg-3 gap-md-3 gap-4 mb-2">
                  <Button
                    dataTestId={'okButton'}
                    children={t('settings.label.ok')}
                    variant={'primary_btn btn_block_mob'}
                    type="submit"
                    onClick={() => {
                      closeEmailSuccessPopUp();
                    }}
                  />
                </div>
              </div>
            </ModalOverlay>
          )}
        </div>
      </div>
    </Collapsible>
  );
};

export default EmailPaperless;
