import {MemberDetails} from 'pages';
import Overview from './Overview';
import { useTranslation } from 'react-i18next';
import CSRProxyForm from './CSRProxyForm';
import { Suspense, useContext, useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import {
  MyPlanTabs,
  getProxyDetails,
  caregiverEndPoint,
  approvalUrl,
  convertToDate,
  ApiWrapper
} from 'common';
import { GlobalContext } from 'context';

import {
  Headings,
  Disclaimer,
  ButtonTabs,
  Loader
} from '@sentaraui/optimahealth_web';
import { useGetCSR, useConsolidateSUmmary, MyConsolidatedResponse } from '@sentara/sentara-api-hooks-core';
import {CSRProxyRevoke, CSRProxy, PageBox} from '@sentara/caregiver';

 
function MyPlan() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    getFeature,
    age,
    proxySelected,
    enableDelete,
    updateProxy,
    setEnableDelete,
    setUpdateProxy,
    setProxyData,
    loginMemberId,
    getAuthToken,
    setIsMemberTabDefault,
    isDesignatedTabShow
  } = useContext(GlobalContext);
  const { getCSRPhi } = useGetCSR();
  const formattedVal = convertToDate(new Date());
  const [, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(false);
  const { ConsolidateSUmmary } = useConsolidateSUmmary();
  const { data: getConsolidatedData,error:getConsolidatedError, callApi:getConsolidated }: any = ApiWrapper(ConsolidateSUmmary);
  const [myConsResState, setMyConsResState] = useState<MyConsolidatedResponse|[]>([]);
 
  // Switching the tabs for memeberdetails and overview tabs
  const handleTabClick = (tabId: string) => {
    setEnableDelete(false);
    setUpdateProxy(false);
    if (tabId === MyPlanTabs.designatedTabs) {
      setSearchParams({ id: MyPlanTabs.designatedID });
    } else if (tabId === MyPlanTabs.memberDetailsTabs) {
      setSearchParams({ id: MyPlanTabs.memberDetailID });
    } else {
      setSearchParams({ id: MyPlanTabs.overViewID });
    }
  };
  
  useEffect(() => {
    (async () => {
      setLoading(true);
      getConsolidated("1");
    })();
  }, []);

  useEffect(()=>{
    if (getConsolidatedData === "") {
      setLoading(false);
      setIsMemberTabDefault(true);
      if(!isDesignatedTabShow) {
        setSearchParams({ id: MyPlanTabs.memberDetailID });
      }
    }
    else if(getConsolidatedData?.length) {
      setLoading(false);
      setIsMemberTabDefault(false);
      if(!isDesignatedTabShow) {
        setSearchParams({ id: MyPlanTabs.overViewID });
      }
      setMyConsResState(getConsolidatedData);
    }

    if(getConsolidatedError) {
      setLoading(false);
    }

  }, [getConsolidatedData,getConsolidatedError])
 
  // getting the params Id from the url
  const search = window.location.search;
  const newParams = new URLSearchParams(search);
  const param = newParams.get('id');
 
  // callback function to redirect to ProxyApproval screen when clicking the cancel button
  const handleCallback = (type: string) => {
    if (type === getProxyDetails.cancel) {
      setEnableDelete(false);
    setUpdateProxy(false);
      navigate(approvalUrl);
    } else if(type === getProxyDetails.updated || type === getProxyDetails.deleted) {
      fetchData();
      
    } 
  };
 
  const fetchData = async () => {
    setLoading(true);
    let data = {
      MemberId: String(loginMemberId),
    };
    setProxyData({});
    let response = await getCSRPhi(data);
    if (response) {
      handleCSRPhiResponse(response);
    } else {
      setLoading(false);
    }
  };
 
  const handleCSRPhiResponse = (response:String)=>{
    if (typeof response === 'string') {
      let decodedData = JSON.parse(atob(response));
      let pendingProxyCount = decodedData?.Result?.filter(
        (item: any) =>
          item?.CurrentStatus === getProxyDetails.confirmed || item?.CurrentStatus === getProxyDetails.PENDING_FOR_APPROVAL
      );
 
      if (pendingProxyCount.length > 0)  {
        setProxyData(decodedData);
      } else {
        setProxyData({});
      }
    }
    else {
      setProxyData({});
    }
    setLoading(false);
  }
 
  //passing props to the Update and Revoke component
  const updateProps = {
    callBack: handleCallback,
    KEY: proxySelected.ID,
    baseURL: process.env.REACT_APP_BASE_URL || "",
    caregiverBaseURL: caregiverEndPoint,
    authToken: getAuthToken || '',
    OcpApimSubscriptionKey: process.env.REACT_APP_SUBSCRIPTION_KEY || "",
  };
  
  //To display the overView and memberdetail tab
  const tabsData = [
    {
      content: (
        <Suspense fallback={<div>Loading...</div>}>
          {param === MyPlanTabs.overViewID && (
            <>
              <Overview myConsResState={myConsResState}/>
              <DisclaimerMessage t={t} formattedVal={formattedVal} />
            </>
          )}
        </Suspense>
      ),
      id: MyPlanTabs.overviewTabs,
      label: t('yourPlan.label.overview'),
    },
    {
      content: (
        <Suspense fallback={<div>Loading...</div>}>
          {param === MyPlanTabs.memberDetailID && (
            <>
              <MemberDetails />
              <DisclaimerMessage t={t} formattedVal={formattedVal} />
            </>
          )}
        </Suspense>
      ),
      id: MyPlanTabs.memberDetailsTabs,
      label: t('yourPlan.label.memberDetails'),
    },
  ];
  const tabsDataWithDesignative = [
    ...tabsData,
    {
      content: (
        <div className="proxy_form">
          {param === MyPlanTabs.designatedID && updateProxy && (
            <PageBox border={false}>
              <CSRProxy flow="member-update" {...updateProps} />
            </PageBox>
          )}
 
          {param === MyPlanTabs.designatedID && enableDelete && (
            <PageBox border={false}>
              <CSRProxyRevoke TerminatedBy={'member'} {...updateProps} />
            </PageBox>
          )}
 
          {param === MyPlanTabs.designatedID &&
            !updateProxy &&
            !enableDelete && (
              <PageBox border={false}>
                <CSRProxyForm />
              </PageBox>
          )}
        </div>
      ),
      id: MyPlanTabs.designatedTabs,
      label: t('yourPlan.label.proxyAuthorization'),
    }
  ];
 
  return (
    <>
      {loading && <Loader />}
      <div className="myplan-wrapper">
        <div className="heading-one-semibold pb-4">
          <Headings
            level={1}
            text={t('yourPlan.label.myPlan')}
            dataTestId="my_plan_header"
          />
        </div>
 
        {getFeature && Object.keys(getFeature).length > 0  && !loading &&
          <div className="homeAddress">
            <ButtonTabs tabIdHandler={handleTabClick} 
            tabs={(age > 18 && getFeature?.DesignatedRepresentativeTab) ? tabsDataWithDesignative : tabsData} 
            />
          </div>
        }
      </div>
    </>
  );
}

export default MyPlan;

const DisclaimerMessage = ({t, formattedVal}: any) => {
  return (
    <div className="pb-4 mb-2">
      <Disclaimer
        disclaimerTitle={t('yourPlan.label.readDisclaimer')}
        disclaimerContent={`${t(
          'yourPlan.message.disclaimerMsg1'
        )} ${formattedVal}. ${t('yourPlan.message.disclaimerMsg2')}`}
      />
    </div>
  );
};
