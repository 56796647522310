import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { PDFDocumentProxy, PDFPageProxy, RenderTask } from 'pdfjs-dist';
import { DeviceTypes, RightArrowIcon, useDeviceType } from 'common';
import { Button } from '@sentaraui/optimahealth_web';
import { BrowserWindow } from 'global';
import { PDFViewerOverrideProps } from './utils';

type PDFViewerProps = {
  data: string;
  onBack: () => void;
  onError: () => void;
} & PDFViewerOverrideProps;

export const PDFViewer = ({
  data,
  onBack,
  onError,
  backButtonText,
  DownloadIcon,
  downloadLabel,
  PrintIcon,
  printLabel,
}: PDFViewerProps) => {
  const SCALE = {
    [DeviceTypes.MOBILE]: 0.6,
    [DeviceTypes.TABLET]: 1.0,
    [DeviceTypes.DESKTOP]: 1.5,
  };
  let browserWindow = window as BrowserWindow;
  const url = (version: string) =>
    `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${version}/pdf.worker.mjs`;
  const deviceType = useDeviceType();
  const canvasPdfContainer = 'canvas-pdf-container';
  const currentRenderTask = useRef<RenderTask | null>(null);
  const downloadLink = useRef<HTMLAnchorElement | null>(null);
  const pdfjsLib = useRef<any>(browserWindow?.pdfjsLib || null);
  const [loading, setLoading] = useState(true);

  const updateFailureStatus = () => {
    setLoading(false);
    onError();
  };

  const updateSuccessStatus = () => {
    setLoading(false);
  };

  useEffect(() => {
    if (currentRenderTask.current) {
      return;
    }

    const renderPDF = async () => {
      try {
        if (!pdfjsLib.current) {
          await import('pdfjs-dist');
          pdfjsLib.current = browserWindow?.pdfjsLib;
          if (!pdfjsLib.current) {
            updateFailureStatus();
          }
          // pdfjsLib needs a worker to render the pdf in canvs.
          pdfjsLib.current.GlobalWorkerOptions.workerSrc = url(
            pdfjsLib.current.version
          );
        }
        const loadingTask = pdfjsLib.current.getDocument({ data: data });
        loadingTask.promise
          .then((pdf: PDFDocumentProxy) => {
            pdf.getPage(1).then((page: PDFPageProxy) => {
              let scale = SCALE[deviceType] || 1.5;
              let viewport = page.getViewport({ scale });
              let canvas = document.createElement('canvas');
              let context = canvas.getContext('2d');
              if (!context) {
                updateFailureStatus();
                return;
              }
              viewport = page.getViewport({ scale: scale });
              canvas.height = viewport.height;
              canvas.width = viewport.width;
              currentRenderTask.current = page.render({
                canvasContext: context,
                viewport: viewport,
              });
              currentRenderTask.current.promise
                .then(() => {
                  // ensure to remove existing canvas
                  removeCanvas();
                  document
                    .getElementById(canvasPdfContainer)
                    ?.appendChild(canvas);
                  updateSuccessStatus();
                })
                .catch(() => {
                  updateFailureStatus();
                })
                .finally(() => {
                  currentRenderTask.current = null;
                });
            });
          })
          .catch(() => {
            updateFailureStatus();
          });
      } catch (error) {
        updateFailureStatus();
      }
    };
    renderPDF();
    // dont not re-render on device type change as this is resource consuming step.
    // Reloading should load with proper scale
  }, []);

  // Creates a url object with blob data
  //that can be used to download the file
  const blobURL = useMemo(() => {
    const len = data.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < len; i++) {
      view[i] = data.charCodeAt(i);
    }
    const blob = new Blob([view], { type: 'application/pdf' });
    return URL.createObjectURL(blob);
  }, []);

  const handleDownload = useCallback(() => {
    if (!downloadLink?.current) {
      return;
    }
    downloadLink.current.href = blobURL;
  }, []);

  const handlePrint = useCallback(() => {
    const pdfWindow = window.open(blobURL, '_blank');
    if (!pdfWindow) {
      return;
    }
    pdfWindow.onload = () => pdfWindow?.print();
  }, []);

  const removeCanvas = () => {
    let pdfContainer = document.getElementById(canvasPdfContainer);
    pdfContainer?.replaceChildren();
  };

  const isMobile = deviceType === DeviceTypes.MOBILE;

  return (
    <div data-testid={'pdf-viewer'}>
      {loading ? <div className="loader" data-testid="loader" /> : null}
      <div
        className={`pdf_container ${isMobile ? 'pdf_container_mobile' : ''}`}
      >
        <Button onClick={onBack} className="back-button">
          <>
            {RightArrowIcon ? <RightArrowIcon /> : null}
            {backButtonText || null}
          </>
        </Button>
        <div className="action-buttons">
          <Button id="download" onClick={handlePrint} className="print-button">
            <>
              {PrintIcon ? <PrintIcon /> : null}
              {printLabel ?? null}
            </>
          </Button>
          <a
            id="download"
            ref={downloadLink}
            href={'void()'}
            download="MemberList.pdf"
            onClick={handleDownload}
          >
            {DownloadIcon ? <DownloadIcon /> : null}
            {downloadLabel ?? null}
          </a>
        </div>
      </div>

      <div id={canvasPdfContainer} data-testid={canvasPdfContainer}></div>
    </div>
  );
};
