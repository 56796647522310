import React from "react";

import Footer from "./Footer";
import { useTranslation } from 'react-i18next';
import {
    FooterContent
} from '@sentaraui/optimahealth_web';

export const FooterNav = () => {
    return (
        <Footer />
    )
}

export const FooterDisclaimer = () => {
    const { t } = useTranslation();
    return (
        <FooterContent
            copyrightText={t('footer.label.optimaCopyrightText1', { year: new Date().getFullYear() })}
            footerDescription={t('footer.label.footerText')}
        />
    )
}
