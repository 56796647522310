import { t } from 'i18next';
import { ContactInfo } from '@sentaraui/optimahealth_web';
import { PhoneResponse } from 'templates';

const AlternativeAddressDetails = (getPhone: PhoneResponse) => {
  return (
    <div data-testid="alternativeAddressDetails">
      {getPhone && (
        <div>
          {t('settings.message.addAlternate')}{' '}
          <ContactInfo
            phoneData={{
              phone1800: getPhone?.benefitPhone1800,
              phoneLocal: getPhone?.benefitPhoneLocal,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default AlternativeAddressDetails;
