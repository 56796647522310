import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import {
  getMaxLength,
  SpecialCharacter,
  OnlyNumbers,
  AlphabetAndNumberRegex,
  convertStringToDate,
  convertStrDateFormat,
  isAgeLessThanFourteen,
  signIn,
  forgotUsername,
  resetPassword,
  UserDetails,
  FormTitles,
} from 'common';
import {  usePersonalInformation, useValidateGuest } from '@sentara/sentara-api-hooks-core';
import { Buffer } from 'buffer';
import {  Link } from 'react-router-dom';
import {
  ErrorMessage,
  Button,
  Label,
  InputField,
  GlobalError,
  Headings,
  Loader,
} from '@sentaraui/optimahealth_web';
import MemberInfoModal from './MemberInfoModal';
import { useForm, FormProvider } from "react-hook-form";
import { DatePicker } from './DatePicker';



/** PersonInformation Component function*/
const PersonalInformation = ({
  formData,
  page,
  setFormData,
  setPage,
  plantypes,
  setPlantypes,
}: any) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const [noContent, setNoContent] = useState<boolean>(false);
  const { PersonalInformation } = usePersonalInformation();
  const {validateGuest} = useValidateGuest();
  const [loader, setLoader] = useState<boolean>(false);
  const [captchaValue, setCaptchaValue] = useState<string>('');
  const [captchaError, setCaptchaError] = useState<boolean>(true);
  const [memberErrorModal, setMemberErrorModal] =
    useState<boolean>(false); /** Default open value set to false*/
  const [memberErrorMessage, setMemberErrorMessage] = useState<string>('');
  const methods = useForm({
    defaultValues: {
      firstName: formData[page]?.firstName || '',
      lastName: formData[page]?.lastName || '',
      ssn: formData[page]?.ssn || '',
      dob: formData[page]?.dob || '',
      medicareId: formData[page]?.medicareId || '',
      medicaId: formData[page]?.medicaId || '',
      guest: formData[page]?.guest || '',
    },
  });
  const { handleSubmit, register, setValue, formState: { errors,  isValid, isSubmitted }, setError} = methods;
  const formFields:Record<string, any> = {
  Medicare: {
    id: 'medicareId',
    placeholder: t('activation.placeholder.medicareId'),
    label: t('activation.label.medicareId'),
    maxLength: getMaxLength.memberId,
    error: errors.medicareId,
    displayError: t('activation.errors.medicareId'),
    restrictInputValue: AlphabetAndNumberRegex,
  },
  Medicaid: {
    id: 'medicaId',
    placeholder: t('activation.placeholder.medicaId'),
    maxLength: getMaxLength.memberId,
    label: t('activation.label.medicaId'),
    error: errors.medicaId,
    displayError: t('activation.errors.medicaId'),
    restrictInputValue: AlphabetAndNumberRegex,
  },
  individual: {
    id: 'ssn',
    placeholder: t('activation.placeholder.ssn'),
    maxLength: getMaxLength.ssn,
    label: t('activation.label.ssn'),
    error: errors.ssn,
    displayError: t('activation.errors.ssn'),
    restrictInputValue: OnlyNumbers,
  },
  guest: {
    id: 'guest',
    placeholder: t('activation.placeholder.guest'),
    maxLength: getMaxLength.guestSSN,
    label: t('activation.label.guest'),
    error: errors.guest,
    displayError: t('activation.errors.guest'),
    restrictInputValue: OnlyNumbers,
  },  
};
const { id, placeholder, maxLength,label, error, displayError, restrictInputValue } = formFields[plantypes] || {};
  /** validation to diplay the plantypes*/
  const planTypes = (plantypes: string) => {
    if (plantypes === t('activation.label.getmedicaId')) {
      return t('activation.label.getmedicaId');
    } else if (plantypes === t('activation.label.getmedicareId')) {
      return t('activation.label.getmedicareId');
    } else {
      return t('activation.label.individual');
    }
  };

  /** onchange method to get the  captch value and validation*/
  const onVerifyCaptcha = (value: any) => {
    setCaptchaValue(value);
    setCaptchaError(false);
  };

  interface InputFieldProps {
    firstName: string;
    lastName: string;
    ssn: string;
    dob: string;
    medicareId: string;
    medicaId: string;
    guest: string;

  }

  /** function to call the API to validate the personal information data*/

  const onSubmit = async (data:InputFieldProps) => {
    captchaValue === '' ? setCaptchaError(true) : setCaptchaError(false);
     const formattedVal = convertStringToDate(data['dob']);
        const isMinimumAge = isAgeLessThanFourteen(new Date(data['dob']));
        if (isMinimumAge) {
          setError('dob', { type: 'lessAge'});
          return;
        } else if (formattedVal === 'Invalid Date') {
           setError('dob', { type: 'invalidDate'});
           return;
        }
    setFormData({ ...formData, [page]: data });
    const medicare = data?.medicareId
      ? data?.medicareId
      : data?.medicaId;
    const memberId = formData[0]?.memberId
      ? Buffer.from(formData[0]?.memberId).toString('base64')
      : '';
    if (captchaValue !== '') {
      setLoader(true);
      const dateString = convertStringToDate(data.dob);
      const formattedDate = convertStrDateFormat(dateString, true);
      const personalData = {
        firstName: data.firstName,
        lastName: data.lastName,
        ssn: data.ssn,
        dob: formattedDate,
        id: medicare ?? '',
        planType: planTypes(plantypes),
        memberId: memberId ?? "",
      };
      if ( plantypes === UserDetails.guest) 
           {
            let guestData = {
              lastName: data.lastName, 
              last4SSN: data.guest.slice(-4), 
              dob: formattedDate
            }
            const guestResponse = await validateGuest(guestData);
            if(guestResponse?.data?.guestExists === true) {
              setErrorMessage(true);
            } else if(guestResponse?.data?.guestExists === false) {              
              setPage((currPage: number) => currPage + 1);
            } 
           setLoader(false);
      }
      else {
        const response: any = await PersonalInformation(personalData);
        personalData.dob = convertStringToDate(data.dob);
        setFormData({
          ...formData,
          [page]: { ...data, ...personalData },
        });

        if (
          response?.errorCode === 403 &&
          response?.errorDetails?.detail
        ) {
          setLoader(false);
          setMemberErrorModal(true);
          setMemberErrorMessage(response?.errorDetails?.detail);
          return;
        } else if (
          response?.error ||
          response?.data?.isActivated === true
        ) {
          setErrorMessage(true);
          setLoader(false);
          return;
        } else if (response?.data === '') {
          setNoContent(true);
          setLoader(false);
          return;
        } else {
          setPage((currPage: number) => currPage + 1);
        }
      }
    }
  };

  const closeMemberErrorModal = () => {
    setMemberErrorModal(false);
    setMemberErrorMessage('');
  };

  return (
    <FormProvider {...methods}>
    <form data-testid="submit_form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
    {loader && <Loader />}
      <div className="row">
        <div className="col-xl-6 col-lg-6 col-md-8 col-sm-12 col-12">
          <div aria-live="polite">
          {errorMessage &&
              <div className='mb-1'> 
                <GlobalError data-testid="memberExistId"  variant="mb-4 d-flex">
                  {
                  <p className='mb-0'>{t('activation.errors.alreadyRegister')}
                    <Link to={signIn}> {t('account.label.sign')} </Link>
                    {t('activation.errors.alreadyRegister2')}
                    <Link to={forgotUsername}> {t('account.label.forgotUsername')} </Link>
                    {t('activation.errors.alreadyRegister3')}
                    <Link to={resetPassword}> {t('account.label.passwordLogin')} </Link>
                    {t('activation.errors.alreadyRegister4')}
                  </p>
                }
                  </GlobalError> 
              </div>
            }
            {noContent && (    
                <GlobalError variant="mb-3" dataTestId="memberExistId">
                      {t('requestUserId.message.serviceError')}
                </GlobalError> 
            )}

            {FormTitles[page] !== '' && (
              <>
                <span className="visually-hidden me-1">
                  {t('accessibility.message.registrationCurrentPage')} {[page + 1]}
                </span>
                <div className="pb-4 mb-4">
                  <Headings
                    dataTestId="registrationHeading"
                    level={1}
                    text={t(FormTitles[page])}
                  />
                </div>
              </>
            )}
          </div>

          <div className="mb-3 pos-rel">
            <Label
              className="pb-1"
              dataTestId={'firstNameLabel'}
              htmlFor={'firstName'}
              children={t('activation.label.firstName')}
            />
            <div className="align-items-center">
              <div className="pos-rel flex-1 d-flex">
                <InputField
                  id={'firstName'}
                  data-testid={'firstName'}
                  maxLength={getMaxLength.name}
                  placeholder={t('activation.placeholder.firstName')}
                  className={`form-input ${errors.firstName ? 'error-border' : ''}`}
                  {...register('firstName', {
                    required: true,
                    onChange: (e:React.ChangeEvent<HTMLInputElement>) => {
                      setValue('firstName', e.target.value.replace(SpecialCharacter, ''));
                    }
                  })}
                />
              </div>
              {errors.firstName && (
                <div className="mt-1">
                  <ErrorMessage children={t('activation.errors.firstName')} />
                </div>
              )}
            </div>
         </div>
         <div className="mb-3 pos-rel">
            <Label
              className="pb-1"
              dataTestId={'lastNameLabel'}
              htmlFor={'lastName'}
              children={t('activation.label.lastName')}
            />
            <div className="align-items-center">
              <div className="pos-rel flex-1 d-flex">
                <InputField
                  id={'lastName'}
                  data-testid={'lastName'}
                  maxLength={getMaxLength.name}
                  placeholder={t('activation.placeholder.lastName')}
                  className={`form-input ${errors.lastName ? 'error-border' : ''}`}
                  {...register('lastName', {
                    required: true,
                    onChange: (e:React.ChangeEvent<HTMLInputElement>) => {
                      setValue('lastName', e.target.value.replace(SpecialCharacter, ''));
                    }
                  })}
                />
              </div>
              {errors.lastName && (
                <div className="mt-1">
                  <ErrorMessage children={t('activation.errors.lastName')} />
                </div>
              )}
            </div>
         </div>
         
            <div className="mb-3 pos-rel">
                <Label
                className="pb-1"
                dataTestId="dateOfBirthInputLabel"
                htmlFor={"dob"}
                children={t('activation.label.dateOfBirth')}
              />
                <DatePicker dobValue={formData[1]?.dob} />
                <div className="mt-1">
                  {errors?.dob?.type === 'required' && <ErrorMessage children={t('activation.errors.dob')} />}
                  {errors?.dob?.type === 'lessAge' && <ErrorMessage children={t('activation.errors.lessAge')} />}
                  {errors?.dob?.type === 'invalidDate' && <ErrorMessage children={t('activation.errors.dobNotMatch')} />}
                </div>
            </div>
            
            <div className="mb-3 pos-rel">
              <Label
                className="pb-1"
                dataTestId="memberIdLabel"
                htmlFor={id}
                children={label}
              />
              <div className="align-items-center">
                <div className="pos-rel flex-1 d-flex">
                  <InputField
                    id={id}
                    data-testid={id}
                    maxLength={maxLength}
                    placeholder={placeholder}
                    className={`form-input ${error ? 'error-border' : ''}`}
                    {...register(id, {
                      required: true,                      
                      minLength: (id === 'guest' ? maxLength : undefined),
                      onChange: (e:React.ChangeEvent<HTMLInputElement>) => {
                        setValue(id, e.target.value.replace(restrictInputValue, ''));
                      }
                    })}
                  />
                </div>
                {error && (
                  <div className="mt-1">
                    <ErrorMessage children={displayError} />
                  </div>
                )}
              </div>
            </div>
        </div>
      </div>
      <hr />
      <div className="row pt-3 mb-3 captcha-error">
        <div className="col-xl-6 col-lg-6 col-md-8 col-sm-12 col-12">
          <div className="pos-rel">
            <ReCAPTCHA
              sitekey={process.env?.REACT_APP_CAPTCHA_SITE_KEY || ''}
              onChange={onVerifyCaptcha}
              data-testid={'captchaCheckBox'}
            />
            {isSubmitted && captchaError && (
              <div className="mt-1">
              <ErrorMessage children={t('activation.messages.captchaError')} />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row pt-4 pb-2">
        <div className="col-lg-auto col-md-auto col-sm12 col-12 d-grid gap-2 d-md-block mb-4">
          <Button
            dataTestId={'continueButton'}
            variant={
              isValid && captchaValue !== ''
                ? 'primary_btn btn_block_mob'
                : ' btn_block_mob disabled_btn'
            }
            type="submit"
          >{t('activation.label.continue')}</Button>
        </div>
        {plantypes !== 'guest' && (
          <div className="col-lg-auto col-md-auto col-sm12 col-12 d-grid gap-2 d-md-block mb-4">
            <Button
              dataTestId={'cancelButton'}
              variant={'secondary_btn'}
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.preventDefault();
                setFormData({
                  ...formData,
                  ...{
                    [page]: formData[page]
                      ? Object.keys(formData[page]).reduce((obj, item) => {
                          obj = { ...obj, [item]: '' };
                          return obj;
                        }, {})
                      : {},
                  },
                });
                setPage((currPage: number) => currPage - 1);
              }}
            >{t('activation.label.cancel')}</Button>
          </div>
        )}
      </div>
      {memberErrorMessage && (
        <MemberInfoModal
          closeMemberErrorModal={closeMemberErrorModal}
          memberErrorMessage={memberErrorMessage}
          memberErrorModal={memberErrorModal}
        />
      )}
    </form>
    </FormProvider>  
  );
};

export default PersonalInformation;
