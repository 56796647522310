import { useTranslation } from 'react-i18next';
import { dataIdDynamic,
  useDeviceType,
  DeviceTypes
} from 'common';
import { Accordion, Table, Input } from '@sentaraui/optimahealth_web';
export interface DocumentTableProps {
  updatevalue: Array<any>; // You should replace `any` with more specific types
  getDocumentValue: (value: any, item: any) => void; // You should replace `any` with more specific types
}

function DocumentTable({ updatevalue, getDocumentValue }: DocumentTableProps) {
  // Initialize translation hook
  const { t } = useTranslation();
  const deviceType = useDeviceType();
  const isMobile = deviceType === DeviceTypes.MOBILE;

  return (
    <div>
      {!isMobile && (
        <Table
          className="table table-custom-style doc-delivery-pref mb-4"
          header={[
            {
              text: (
                <div data-testid="document">{t('settings.label.document')}</div>
              ),
            },
            {
              text: (
                <div data-testid="electronically">
                  {t('settings.label.electronically')}
                </div>
              ),
            },
            {
              text: (
                <div data-testid="usMail">{t('settings.label.usMail')}</div>
              ),
            },
          ]}
          // Mapping over updatevalue array
          rows={updatevalue?.map((item: any, i: any) => [
            {
              text: (
                <div data-testid={dataIdDynamic(item?.document)}>
                  {item?.document}
                </div>
              ),
            },
            // Electronically radio button
            {
              text: (
                <Input
                  dataTestId={`electronic${i}`}
                  type="radio"
                  id="Electronic"
                  className="form-check-input mt-0"
                  name={`Electronic${i}`}
                  value={`Electronic${i}`}
                  checked={item?.electronically}
                  onChange={() =>
                    getDocumentValue(
                      {
                        electronically: !item?.electronically,
                        usMail: !item?.usMail,
                      },
                      item
                    )
                  }
                />
              ),
            },
            // US Mail radio button
            {
              text: (
                <Input
                  dataTestId={`usMail${i}`}
                  className="form-check-input mt-0"
                  type="radio"
                  id="usMail"
                  name={`Electronic${i}`}
                  value={`usMail${i}`}
                  checked={item?.usMail}
                  onChange={() =>
                    getDocumentValue(
                      {
                        usMail: !item?.usMail,
                        electronically: !item?.electronically,
                      },
                      item
                    )
                  }
                />
              ),
            },
          ])}
        />
      )}
      {isMobile && (
        <div className="accordion-table mb-4">
          <Accordion
            initialActiveIndex={0}
            id="doc_pref_delivery_table"
            items={updatevalue?.map((item: any, i: any) => ({
              title: (
                <div data-testid={dataIdDynamic(item?.document)}>
                  {item?.document}
                </div>
              ),
              id: 'doc_pref_delivery_table_data',
              content: (
                <div>
                  <div className="mb-4">
                    <Input
                      dataTestId={`electronic${i}`}
                      type="radio"
                      id={`Electronic${i}`}
                      className="form-check-input mt-0"
                      name={`Electronic${i}`}
                      value={`Electronic${i}`}
                      checked={item?.electronically}
                      onChange={() =>
                        getDocumentValue(
                          {
                            electronically: !item?.electronically,
                            usMail: !item?.usMail,
                          },
                          item
                        )
                      }
                    />
                    <label
                      className="check-label-text check-label-pad"
                      htmlFor={`Electronic${i}`}
                    >
                      {t('settings.label.electronically')}
                    </label>
                  </div>
                  <div className="mb-4">
                    <Input
                      dataTestId={`usMail${i}`}
                      className="form-check-input mt-0"
                      type="radio"
                      id={`usMail${i}`}
                      name={`USMail${i}`}
                      value={`usMail${i}`}
                      checked={item?.usMail}
                      onChange={() =>
                        getDocumentValue(
                          {
                            usMail: !item?.usMail,
                            electronically: !item?.electronically,
                          },
                          item
                        )
                      }
                    />
                    <label
                      className="check-label-text check-label-pad"
                      htmlFor={`usMail${i}`}
                    >
                      {t('settings.label.usMail')}
                    </label>
                  </div>
                </div>
              ),
            }))}
          />
        </div>
      )}
    </div>
  );
}

export default DocumentTable;
