import { NumberFormat } from './constants/Regex';

export const toSentancePascalCase = (text: string) => {
  return text
    ?.split(' ')
    ?.map((word) => toPascalCase(word))
    ?.join(' ');
};
export const toPascalCase = (str: string) => {
  if (!str) {
    return str;
  }
  return str?.length > 0
    ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
    : str;
};

export const dataIdDynamic = (item: string) => {
  return `${item?.charAt(0).toLowerCase() + item?.slice(1)}`.replace(/\s/g, '');
};

export function equalsIgnoringCase(text: string, other: string) {
  return text.localeCompare(other, undefined, { sensitivity: 'base' }) === 0;
}

//formating phone number'1234567890' to '(123) 456-7890'
export function formatePhoneNo(text: string): string {
  let input = "";
  if(text?.length){
     input = text.replace(NumberFormat, '');
      if (input.length > 3 && input.length <= 6)
        input = `(${input.slice(0, 3)}) ${input.slice(3)}`;
      else if (input.length > 6)
        input = `(${input.slice(0, 3)}) ${input.slice(3, 6)}-${input.slice(6, 10)}`;
  } 
  return input;  
}
