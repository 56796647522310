import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { GlobalContext } from 'context';
import ProxyApproval from './ProxyApproval';
import ProxyPending from './ProxyPending';
import {
  Tabs,
  TabsItem,
  Headings,
} from '@sentaraui/optimahealth_web';

const ProxyTabs = () => {
  const { proxyData } = useContext(GlobalContext);
  // Initialize translation
  const { t } = useTranslation();

  return (
    <>
      <div className="heading-one-semibold pb-4">
        <Headings
          level={1}
          text={t('proxy.label.mydesignatedRepresentatives')}
          dataTestId={'my_designated_representatives'}
        />
      </div>
      <div className="border border-1 border-clr mb-5">
        <div className="dashboard-tabs-wrapper">
          <div className="homeAddress">
            <Tabs
              activeTab={'Approved'}
              tabConditions={{
                Approved: true,
                Pending: true,
              }}
            >
              <TabsItem
                content={
                  <div className="p-3" data-testid="Approved">
                    <ProxyApproval proxyData={proxyData} />
                  </div>
                }
                tabId="Approved"
                tabName={t('proxy.label.approved')}
              />
              <TabsItem
                content={
                  <div className="p-3" data-testid="Pending">
                    <ProxyPending proxyData={proxyData} />
                  </div>
                }
                tabId="Pending"
                tabName={t('proxy.label.pending')}
              />
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProxyTabs;
