import React from 'react';
import { t } from 'i18next';
import {ProfileHeaderContent, UserNameProps} from "templates"
import { toPascalCase } from 'common';



function UserName({ firstName, lastName }: UserNameProps) {
  const CapitalizeFirstName = toPascalCase(firstName);
  const CapitalizeLastName = toPascalCase(lastName);
  return (
    <div className='mb-3 mb-md-0 mb-lg-0' data-testid="username_component">
      <ProfileHeaderContent
        header={t('name')}
        contentOne={`${CapitalizeFirstName} ${CapitalizeLastName}`}
        dataTestIdOne="profileName"
        contentClassNameOne='mb-0 profile_name'
        
        
      />
    </div>
  );
}

export default UserName;
