import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  HeaderLogo,
  ContactUsLink,
  SearchLink,
  HamburgerMenuLink,
  FooterDisclaimer
} from 'organisms';
import { CommonForgotUsername } from '@platform/web-forgot-username';
import {
  useRequestUsername,
  useForgotUserName,
} from '@sentara/sentara-api-hooks-core'
import {ForgotUserNameHelper} from "pages"
import {
  Link
} from '@sentaraui/optimahealth_web';

const ForgotUsername = () => {
  const { t } = useTranslation();

  const { requestUsername } = useRequestUsername();
  const { forgotUserName } = useForgotUserName();

  const data = ForgotUserNameHelper();

  return (
    <div className="d-flex flex-column h-100">
      {/* Skip to Main Content link */}
      <Link to="#main-content" className="skip-link">
        {t('accessibility.message.skipToMainContent')}
      </Link>
      <div>
        <header>
          <div className="container-fluid">
            <nav className="d-flex align-items-center justify-content-between pt-3 mb-4">
              <div>
                <HeaderLogo />
              </div>
              <div>
                <div className="d-flex flex-no-wrap align-items-center">
                  <div className="d-none d-xl-block d-lg-block d-md-none ms-4">
                    <ContactUsLink showIcon={false} />
                  </div>
                  <div className="d-lg-none d-md-block d-sm-block">
                    <SearchLink />
                  </div>
                  <div className="d-lg-none d-md-block d-sm-block">
                    <HamburgerMenuLink showLogout={false} />
                  </div>
                </div>
              </div>
            </nav>
          </div>
          <hr className="border-w-2" />
        </header>
        {/* Breadcrumb */}
        <CommonForgotUsername
          {...data}
          requestUsernameHook={requestUsername}
          forgotUserNameHook={forgotUserName}
        />
        {/* Breadcrumb */}

        <hr className="d-block d-lg-none d-sm-block d-md-block" />
      </div>
      <footer className="mt-auto">
        <FooterDisclaimer />
      </footer>
    </div>
  );
};

export default ForgotUsername;