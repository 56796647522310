import React from "react";

interface IamLandingProps {
  BannerImg: string;
  LogoCommon: string;
  SuccessContent: any;
}

const IAMAuthSuccess: React.FC<IamLandingProps> = ({ BannerImg, LogoCommon, SuccessContent }) => {
  return (
    <div className="d-flex flex-column h-100">
      <div className="iam_landing_mob_bg my-auto">
        <div className="container-fluid">
          <div className="iam_wrapper">
            <div className="row">
              <div className="col-lg-7 col-md-5 col-sm-12 col-12 d-none d-lg-block d-md-block">
                <img src={BannerImg} alt="" className="img-fluid security_banner" />
              </div>
              <div className="col-lg-5 col-md-7 col-sm-12 col-12">
                <div className="iam_landing_rht h-100">
                  <div className="d-flex flex-column justify-content-center h-100">
                    <div className="mb-3">
                      <img src={LogoCommon} alt="" className="img-fluid logo-common" />
                    </div>
                    {SuccessContent}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IAMAuthSuccess;

