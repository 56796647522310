import React from 'react';
import { useTranslation } from 'react-i18next';

// Define the interface for the props passed to PasswordStrengthMeter component
interface PasswordMeter {
  inputStrengthClass: string;
}

// Define the PasswordStrengthMeter component
const PasswordStrengthMeter: React.FC<PasswordMeter> = ({
  inputStrengthClass,
}) => {
  // Initialize localization using react-i18next
  const { t } = useTranslation();

  return (
    <>
      {/* Container for the password strength bar */}
      <div className="strength-bar-container">
        {/* Password strength bar */}
        <div
          data-testid="passwordStrenghtValidator"
          className={inputStrengthClass}
        ></div>
      </div>
      {/* Container for password strength labels */}
      <div className="container_bar redhat_medium">
        {/* Weak password label */}
        <div data-testid="passwordStrenghtValidatorWeak">
          {t('settings.label.weak')}
        </div>
        {/* Medium password label */}
        <div data-testid="passwordStrenghtValidatorMedium">
          {t('settings.label.medium')}
        </div>
        {/* Strong password label with recommended note */}
        <div data-testid="passwordStrenghtValidatorStrong">
          {t('settings.label.strong')} ({t('settings.label.recommended')})
        </div>
      </div>
    </>
  );
};

export default PasswordStrengthMeter;
