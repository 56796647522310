import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CheckIconBig, signIn } from 'common';


interface ActivationSuccessProps {
  guest?: boolean; 
  plantypes?: string;
}

function ActivationSuccess({ guest, plantypes }: ActivationSuccessProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  let searcUrl = window.location.search;

  let newParams = new URLSearchParams(searcUrl);

  let proxyId = newParams.get('proxy');

  const onSubmit = async () => {
    if(proxyId){
      navigate(`${signIn}?proxy=${proxyId}`);
    }
    else{
      navigate(signIn);
    }
  };



  return (
    <>
      <div className="row justify-content-center">
        <div className="col-xl-4 col-lg-8 col-md-8 col-sm-12 col-12 text-center">
          <div className="mb-3 pb-1">
            <CheckIconBig />
          </div>
          <div
            className="literata_regular font-fourty fw-500 mb-3 pb-1"
            data-testid="activationSuccess"
          >
            {t('activation.messages.activationSuccess')}
          </div>
          <div className="mb-4" data-testid="activationSuccessMessage">
            {t('activation.messages.activationSuccessMessageOne')}
            <br aria-hidden="true" data-foucusable="false" />
            {t('activation.messages.activationSuccessMessageTwo')}
          </div>
          <div className="pt-2 pb-2 mb-4">
            <button
              onClick={onSubmit}
              data-testid={'continueButton'}
              type="button"
              className="primary_btn"
            >
              {t('activation.messages.activationguestSign')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ActivationSuccess;
