import React from 'react';
import { useTranslation } from 'react-i18next';
import securityBanner from '../../assets/images/security_banner.png';
import logoCommonImg from '../../assets/images/logo-common.png';
import AuthSuccess from '../../templates/AuthSuccess';
import { Link } from '@sentaraui/optimahealth_web';

const IAMAuthSuccess = () => {
  //Initialize translation hook
  const { t } = useTranslation();
  const AuthBannerImg = securityBanner;
  const AuthLogoCommon = logoCommonImg;
  const AuthSuccessContent = (
    <>
    <h1>{t('authSuccessContent.content.title')}</h1>
      <p>
        {t('authSuccessContent.content.claimingDuo')}
      </p>
      <p>
        {t('authSuccessContent.content.welcomeEmail')}({t('authSuccessContent.content.sentFrom')}{' '}
        <Link to="mailto:SOAR@sentara.com" className="darkblue-color">
          {t('authSuccessContent.content.emailId')}
        </Link>
        ).
      </p>
      <p>
        {t('authSuccessContent.content.additionalInfo')}
      </p>
      <p>
        <i>
         {t('authSuccessContent.content.additionalAccess')}
        </i>
      </p>
    </>
  );

  return <AuthSuccess BannerImg={AuthBannerImg} LogoCommon={AuthLogoCommon} SuccessContent={AuthSuccessContent} />;
};

export default IAMAuthSuccess;