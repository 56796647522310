import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  termAndCondition,
  languagesAssistance,
  footerNoticeOfNonDiscrimination,
  Navigate
} from 'common';
import { GlobalContext } from 'context';
import { useNavigate } from 'react-router-dom';
import { FooterLinks } from '@sentaraui/optimahealth_web';

const Footer = () => {
  const { t } = useTranslation();
  const { getFeature } = useContext(GlobalContext);
  const navigate = useNavigate();

  // Redirect to Oracle contact us page
  const redirectToContact = (e: Event) => {
  // Prevent the default action of the event
  e.preventDefault();

  // Check if ContactusSalesForce property of getFeature is falsy
  if(!getFeature?.ContactusSalesForce) {
    // If it's falsy, find the form with id 'contactUS' and submit it
    const form = document.getElementById('contactUS') as HTMLFormElement;
    form.submit();
  } else {
    // If it's truthy, navigate to homeContactUs
    navigate(Navigate.homeContactUs);
  }
};
  // generate footer links
  return (
    <FooterLinks
      links={[
        {
          dataTestId: 'footerTermsOfUse',
          label: t('footer.label.termsOfUse'),
          url: termAndCondition,
          target: '_blank',
        },
        {
          dataTestId: 'footerLangAssist',
          label: t('footer.label.languagesAssistance'),
          url: languagesAssistance,
          target: '_blank',
        },
        {
          dataTestId: 'footerNoticeOfNonDiscrimination',
          label: t('footer.label.noticeOfNonDiscrimination'),
          url: footerNoticeOfNonDiscrimination,
          target: '_blank',
        },
        ...(getFeature?.ContactUs ? [{
          dataTestId: 'footerContactUs',
          label: t('footer.label.contactUs'),
          url: '#',
          onClick : (e:any) => redirectToContact(e)
        }] : []),
      ]}
    />
  );
};

export default Footer;
