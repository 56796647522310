import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import EmailPrompt from './EmailPrompt';
import {
  useMyHomeTiles,
  useMySSOUrl,
  useUpdateCSR,
  useGetProxyDataFromMember,
  usePreferenceDetails,
} from '@sentara/sentara-api-hooks-core';
import { GlobalContext } from 'context';
import { useNavigate } from 'react-router-dom';
import {
  ApiWrapper,
  getProxyDetails,
  ExpiryOption_Until_Disenrollment,
  Proxy,
  ProfileUpdate,
  SHOW_PREFERENCE_POPUP,
  backToDashboardLink,
  MemberProxyData,
  caregiverEndPoint,
  toPascalCase,
  setLocalStorageBoolean,
  getLocalStorageBoolean,
  setSessionStorageBoolean,
  getSessionStorageBoolean,
  GenerateTiles,
  getDecryptedValue,
  setEncryptedValue,
  convertToDate,
  isGuest,
  apiResponseSafeCheck,
  formatISODateToMDY,
  errorhandler,
  Navigate,
  DeviceTypes,
  useDeviceType,
} from 'common';
import HomeTiles from './HomeTiles';
import {
  ModalOverlay,
  Button,
  Headings,
  Tabs,
  TabsItem,
  Loader,
} from '@sentaraui/optimahealth_web';
import { PageBox, CSRProxyConfirm, Paragraph } from '@sentara/caregiver';
import { ProfileInformations } from '../../templates/profile/interface';
import { tr } from 'date-fns/locale';
import { PaperlessPreferencePopup } from 'templates';

interface Tile {
  name: string;
  icon: React.JSX.Element;
  id: string;
}

interface MemberProxyProps {
  MemberFirstName: string;
  MemberLastName: string;
  ExpirationDate: Date;
  ID: string;
  ExpiryOption: string;
  InitiatedDate: Date;
  MemberId: string;
  CurrentStatus: string;
}

const Dashboard = () => {
  const {
    getFeature,
    isDesignatedRepresentative,
    memberProxyData,
    setDesignatedDropdownLink,
    proxyUpdate,
    viewModal,
    setProxyUpdate,
    confirmProxy,
    loginMemberId,
    setMemberProxyData,
    loginProxyId,
    setIsBacktoDashBoard,
    setIsDesignatedRepresentative,
    getAuthToken,
    proxyMemberId,
  } = useContext(GlobalContext);
  const { MyHomeTilesType } = useMyHomeTiles();
  const { updateCSRPhi } = useUpdateCSR();
  const { GetProxyDataFromMember } = useGetProxyDataFromMember();
  const navigate = useNavigate();
  const deviceType = useDeviceType();
  const isDesktop = deviceType === DeviceTypes.DESKTOP;
  const [allTiles, setAllTiles] = useState<Tile[]>([]);
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showApprove, setShowApprove] = useState<boolean>(false);
  const [allMemberProxy, setAllMemberProxy] = useState<MemberProxyProps[]>([]);
  const [pendingMemberProxy, setPendingMemberProxy] = useState<MemberProxyProps[]>([]);
  const [selectedMemberProxy, setSelectedMemberProxy] =
    useState<MemberProxyProps>({} as MemberProxyProps);
  const [selectedPendingProxy, setSelectedPendingProxy] =
    useState<MemberProxyProps>({} as MemberProxyProps);
  const [localDesignative, setLocalDesignative] = useState<boolean>(false);
  const profileUpdate = getLocalStorageBoolean(ProfileUpdate);
  const DesignatedDropdownLink: boolean = getLocalStorageBoolean(
    Proxy?.DesignatedDropdownLink
  );
 

  const { data, callApi }: any = ApiWrapper(MyHomeTilesType);
  const {
    data: updateCSRPhiData,
    apiError,
    callApi: callUpdateCSRphi,
  } = ApiWrapper(updateCSRPhi);
  const {
    data: getProxyData,
    apiError: getProxyDataError,
    callApi: callGetProxyData,
  } = ApiWrapper(GetProxyDataFromMember);
  // CallBack for CSRProxy Form Confirm or Cancel from pending Tab
  const handleCallbackApprove = (type: string) => {
    if (type === getProxyDetails.cancel) {
      setShowApprove(false);
      setSelectedPendingProxy({} as MemberProxyProps);
    } else if (type === getProxyDetails.success) {
      fetchData();
    }
  };

  const updateProps = {
    callBack: handleCallbackApprove,
    KEY: selectedPendingProxy.ID,
    baseURL: process.env.REACT_APP_BASE_URL || '',
    caregiverBaseURL: caregiverEndPoint,
    authToken: getAuthToken || '',
    OcpApimSubscriptionKey: process.env.REACT_APP_SUBSCRIPTION_KEY || '',
  };

  useEffect(() => {
    (async () => {
      const guestValue: boolean = await isGuest();
      if ((getFeature && !guestValue) || proxyMemberId !== '') {
        callApi();
      }
    })();
  }, [getFeature]);

  /*** Data  for All Member and Pending tabs*/
  useEffect(() => {
    (async () => {
      let MemberProxyDataSession = await getDecryptedValue(MemberProxyData);
      let memberSession = MemberProxyDataSession ?? '';
      let MemberProxyStore = apiResponseSafeCheck(memberSession)
        ? JSON.parse(memberSession)
        : '';
      if (Object.keys(MemberProxyStore).length > 0) {
        setAllMemberProxy(
          MemberProxyStore?.Result?.filter(
            (item: { CurrentStatus: string }) =>
              item.CurrentStatus === getProxyDetails.confirmed
          )
        );
        setPendingMemberProxy(
          MemberProxyStore?.Result?.filter(
            (item: { CurrentStatus: string }) =>
              item.CurrentStatus === getProxyDetails.PENDING_FOR_APPROVAL
          )
        );
      } else {
        if (memberProxyData?.Result) {
          setAllMemberProxy(
            memberProxyData?.Result?.filter(
              (item: { CurrentStatus: string }) =>
                item.CurrentStatus === getProxyDetails.confirmed
            )
          );
          setPendingMemberProxy(
            memberProxyData?.Result?.filter(
              (item: { CurrentStatus: string }) =>
                item.CurrentStatus === getProxyDetails.PENDING_FOR_APPROVAL
            )
          );
        }
      }
    })();
  }, [memberProxyData]);

  /***Generate Tiles and display tiles in dashboard based on launchDarkly*/
  useEffect(() => {
    if (data) {
      const getItems = GenerateTiles(data, getFeature, t);
      setAllTiles(getItems);
    }
  }, [data]);

  function popupClose() {
    localStorage.setItem(ProfileUpdate, 'false');
    setIsOpen(false);
  }
  useEffect(() => {
    if (profileUpdate) {
      setIsOpen(false);
    }
    document.title = t('pageTitle.label.Dashboard');
  }, [profileUpdate]);

  const clickCare = () => {
    getAPIUrl('HelathSparqOneSSOUrl');
  };

  const { SSOUrlType } = useMySSOUrl();
  // Function to get and open the API URL in a new tab
  const getAPIUrl = async (type: string) => {
    setLoading(true);
    const res = await SSOUrlType(type); // Get SSO URL from API
    if (res?.data?.ssoUrl) {
      window.open(res?.data?.ssoUrl, '_blank'); // Open SSO URL in a new tab
    } else if (res?.errorCode) {
      errorhandler(res?.errorCode);
    }
    setLoading(false);
  };

  // CallBack for CSRProxy Form Confirm or Cancel from Proxy Request Popup
  const handleCallback = (type: string) => {
    if (type === getProxyDetails.canceled) {
      setProxyUpdate(false);
    } else if (type === getProxyDetails.success) {
      fetchData();
    }
  };

  /***Deny Click API call from Pending Tabs*/
  const denyApproval = async (memberProxyDetails: MemberProxyProps) => {
    setLoading(true);
    setAllMemberProxy([]);
    setPendingMemberProxy([]);
    memberProxyDetails.CurrentStatus = getProxyDetails.APPROVED;
    callUpdateCSRphi(btoa(JSON.stringify(memberProxyDetails)));
  };
  /***Refresh data in pending tab and All member after deny API call and handle error*/
  useEffect(() => {
    if (apiError) {
      setLoading(false);
    } else if (updateCSRPhiData) {
      fetchData();
    }
  }, [data, updateCSRPhiData]);

  /***API call to refresh data in Pending and All Member Tabs*/
  const fetchData = async () => {
    setEncryptedValue(MemberProxyData, JSON.stringify({}));
    const isGuestUser: boolean = await isGuest();
    let proxyId = isGuestUser ? atob(loginProxyId) : loginMemberId;
    setEncryptedValue(MemberProxyData, JSON.stringify({}));
    let data = {
      MemberId: proxyId,
    };
    callGetProxyData(btoa(JSON.stringify(data)));
  };

  useEffect(() => {
    if (getProxyDataError) {
      setLoading(false);
    } else if (getProxyData) {
      handleGetProxyDataResponse(getProxyData);
    }
  }, [getProxyDataError, getProxyData]);

  const handleGetProxyDataResponse = (response: string) => {
    let decodedData = JSON.parse(atob(response));
    let pendingProxyCount = decodedData?.Result?.filter(
      (item: any) =>
        item?.CurrentStatus === getProxyDetails.confirmed ||
        item?.CurrentStatus === getProxyDetails.PENDING_FOR_APPROVAL
    );
    if (pendingProxyCount.length > 0) {
      setLocalStorageBoolean(Proxy.DesignatedDropdownLink, true);
      setDesignatedDropdownLink(true);
      setMemberProxyData(decodedData);
      setEncryptedValue(MemberProxyData, JSON.stringify(decodedData));
    } else {
      setLocalStorageBoolean(Proxy.DesignatedDropdownLink, false);
      setDesignatedDropdownLink(false);
      setEncryptedValue(MemberProxyData, JSON.stringify({}));
      setMemberProxyData({});
    }
    setLoading(false);
  };
  useEffect(() => {
    if (isDesignatedRepresentative && !localDesignative) {
      setLocalDesignative(true);
    } else if (!isDesignatedRepresentative && localDesignative) {
      setLocalDesignative(false);
    }
  }, [isDesignatedRepresentative]);

  const [smsConsent, setSmsConsent] = useState<boolean>(false);
  const [emailConsent, setEmailConsents] = useState<boolean>(false);

  const { preferenceDetails } = usePreferenceDetails();

  const { data: profileResponse, callApi: callProfileApi } =
    ApiWrapper(preferenceDetails);

  useEffect(() => {
    (async () => {
      const guestValue: boolean = await isGuest();
      if (!guestValue && getFeature?.AccountSettings !== undefined) {
        profileAPI();
      }
    })();
  }, [loginMemberId, getFeature?.AccountSettings]);

  const profileAPI = async () => {
    let data = {
      memberId: btoa(loginMemberId),
    };
    callProfileApi(data, '', getFeature?.AccountSettings);
  };

 

  function preferencesPopupClose() {
    setSmsConsent(false);
    setEmailConsents(false);
    setSessionStorageBoolean(SHOW_PREFERENCE_POPUP, true);
  }

 

  useEffect(() => {
    if (profileResponse) {
      setSmsConsent(
        !(profileResponse as ProfileInformations)?.smsConsent?.optInConsent);
      setEmailConsents(
        !(profileResponse as ProfileInformations)?.paperlessConsent?.optInConsent);
    }
  }, [profileResponse]);

  return (
    <div>
      {loading && <Loader />}
      {/* CSRProxyConfirm Form when confirm  is clicked from Proxy Request Popup */}
      {proxyUpdate && isDesignatedRepresentative && !viewModal && (
        <div className="proxy_form">
          <CSRProxyConfirm
            KEY={confirmProxy[0]?.ID}
            callBack={handleCallback}
            baseURL={process.env.REACT_APP_BASE_URL || ''}
            caregiverBaseURL={caregiverEndPoint}
            authToken={getAuthToken || ''}
            OcpApimSubscriptionKey={
              process.env.REACT_APP_SUBSCRIPTION_KEY || ''
            }
          />
        </div>
      )}
      {!isDesktop &&
        !(DesignatedDropdownLink && isDesignatedRepresentative) &&
        proxyMemberId !== '' && (
          <button
            data-testid="navLinkProfile"
            className="btn-links link-text arrow-back mb-3 ms-1"
            onClick={(e) => {
              e.preventDefault();
              setIsBacktoDashBoard(true);
              navigate(Navigate.dashboard);
              localStorage.setItem(Proxy.MemberId, '');
              setIsDesignatedRepresentative(true);
              if (window.location.pathname.indexOf('dashboard') !== -1) {
                setLocalStorageBoolean(backToDashboardLink, false);
              }
            }}
          >
            {t('header.label.backtoDashboard')}
          </button>
        )}
      {!proxyUpdate && !showApprove && (
        <div className="dashboard mb-4">
          <div className="hero-section d-flex">
            <div className="hero-img">
              <div className="hero-container d-flex flex-column">
                {getFeature?.FindADoctor && (
                  <div className="inner-cont d-flex flex-column">
                    <div className="heading-two">
                      <Headings level={2} text={t('home.label.title')} />
                    </div>
                    <p className="mb-0">{t('home.label.shortBlurb')}</p>
                    <Button
                      dataTestId={'findCareButton'}
                      children={t('home.label.findCare')}
                      variant={'primary_btn btn_block_mob'}
                      onClick={() => clickCare()}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Tiles will not be shown for designated Representative */}
      {(!localDesignative || proxyMemberId !== "") && getFeature?.HomeTiles && (
        <div className="grid-cont d-flex">
          <div
            className="left-border-white"
            aria-hidden="true"
            data-focusable="false"
          ></div>
          <HomeTiles allTiles={allTiles} />
          <div
            className="bottom-border-white"
            aria-hidden="true"
            data-focusable="false"
          ></div>
        </div>
      )}
      {/* All Member and Pending Tabs*/}
      {localDesignative &&
        !(proxyUpdate && isDesignatedRepresentative && !viewModal) &&
        !showApprove &&
        (allMemberProxy.length > 0 || pendingMemberProxy.length > 0) &&
        proxyMemberId === '' && (
          <div className="dashboard-tabs-wrapper mt-3 ">
            <div className="homeAddress">
              <Tabs
                activeTab={
                  allMemberProxy.length ? 'all_members' : 'pending_requests'
                }
                tabConditions={{
                  all_members: !!allMemberProxy.length,
                  pending_requests: !!pendingMemberProxy.length,
                }}
              >
                <TabsItem
                  content={
                    <div className="py-2" data-testid="allMembers">
                      <div className="pending-request-list-wrapper mb-3">
                        {allMemberProxy.map((row: MemberProxyProps, i: any) => {
                          return [
                            <div
                              className="d-flex flex-column flex-lg-row flex-md-row flex-sm-row justify-content-between pending-request-list"
                              key={row.ID}
                            >
                              <div className="d-flex align-items-center ms-2">
                                <input
                                  type="radio"
                                  id={'user_1'}
                                  className="form-check-input mt-0 me-2"
                                  data-testid={'pendingUser_1'}
                                  name={'members'}
                                  value={''}
                                  onChange={() => {
                                    setSelectedMemberProxy(row);
                                  }}
                                />
                                <div>
                                  <div className="redhat_bold">
                                    {toPascalCase(row.MemberFirstName) +
                                      ' ' +
                                      toPascalCase(row.MemberLastName)}
                                  </div>
                                  <div className="redhat_regular">
                                    {t('proxy.label.expirationDate') +
                                      (row.ExpiryOption ===
                                      ExpiryOption_Until_Disenrollment
                                        ? t('proxy.label.disEnrollment')
                                        : convertToDate(row?.ExpirationDate))}
                                  </div>
                                </div>
                              </div>
                              <Button
                                dataTestId={'viewInformationButton'}
                                children={t('proxy.label.viewInformation')}
                                variant={
                                  selectedMemberProxy?.ID === row.ID
                                    ? 'secondary_btn'
                                    : 'disabled_btn'
                                }
                                type="button"
                                onClick={() => {
                                  if (
                                    selectedMemberProxy?.ID !== undefined &&
                                    selectedMemberProxy?.ID === row.ID
                                  ) {
                                    setEncryptedValue(
                                      Proxy.memberProxy,
                                      JSON.stringify(selectedMemberProxy)
                                    );
                                    setEncryptedValue(
                                      Proxy.MemberId,
                                      selectedMemberProxy.MemberId
                                    );
                                    window.location.reload();
                                    setLocalStorageBoolean(
                                      backToDashboardLink,
                                      true
                                    );
                                  }
                                }}
                              />
                            </div>,
                          ];
                        })}
                      </div>
                    </div>
                  }
                  tabId="all_members"
                  tabName={t('proxy.label.allMembers')}
                />
                <TabsItem
                  content={
                    <div className="py-2" data-testid="pendingRequests">
                      <div className="pending-request-list-wrapper mb-3">
                        {pendingMemberProxy.map(
                          (row: MemberProxyProps, i: any) => {
                            return [
                              <div
                                className="d-flex flex-column flex-lg-row flex-md-row flex-sm-row justify-content-between pending-request-list"
                                key={row.ID}
                              >
                                <div className="flex-1">
                                  <div className="redhat_bold">
                                    {toPascalCase(row.MemberFirstName) +
                                      ' ' +
                                      toPascalCase(row.MemberLastName)}
                                  </div>
                                  <div className="redhat_regular">
                                    {t('proxy.label.requestDate') +
                                      formatISODateToMDY(
                                        row?.InitiatedDate?.toString()
                                      )}
                                  </div>
                                </div>
                                <div className="d-flex flex-column flex-lg-row flex-md-row flex-sm-row gap-3 mb-3">
                                  <Button
                                    dataTestId={'denyButton'}
                                    children={t('proxy.label.deny')}
                                    variant={'secondary_btn btn-medium'}
                                    type="button"
                                    onClick={() => {
                                      denyApproval(row);
                                    }}
                                  />
                                  <Button
                                    dataTestId={'approveButton'}
                                    children={t('proxy.label.approve')}
                                    variant={'primary_btn btn-medium'}
                                    type="button"
                                    onClick={() => {
                                      setSelectedPendingProxy(row);
                                      setShowApprove(true);
                                    }}
                                  />
                                </div>
                              </div>,
                            ];
                          }
                        )}
                      </div>
                    </div>
                  }
                  tabId="pending_requests"
                  tabName={t('proxy.label.pendingRequests')}
                />
              </Tabs>
            </div>
          </div>
        )}
      {/* CSRProxyConfirm UI  when Approve  is clicked from pending Tab*/}
      {showApprove && (
        <div className="proxy_form">
          <PageBox border={false}>
            <CSRProxyConfirm {...updateProps} />
          </PageBox>
        </div>
      )}
      {getFeature?.UpdateEmailPrompt && isOpen && (
        <ModalOverlay
          isOpen
          onClose={popupClose}
          overlayInfo="Modal information"
        >
          <EmailPrompt setIsOpen={setIsOpen} />
        </ModalOverlay>
      )}
      {(!getSessionStorageBoolean(SHOW_PREFERENCE_POPUP)) && (profileResponse && (profileResponse as { isMemberEnrolled: boolean })?.isMemberEnrolled) && (smsConsent || emailConsent) ? (
        <PaperlessPreferencePopup
          smsConsent={smsConsent}
          emailConsent={emailConsent}
          closeModal={preferencesPopupClose}
        />
      ):null}
    </div>
  );
};
Dashboard.propTypes = {};

export default Dashboard;
