import { FooterDisclaimer } from 'organisms';
import { PROXY_EXPIRED_URL, getProxyDetails, caregiverEndPoint } from 'common';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ProxyHeader from './ProxyHeader';
import { PageBox, RegisterButton } from '@sentara/caregiver';


const ProxyRegister = () => {
  const navigate = useNavigate();
  let search = window.location.search;

  let newParams = new URLSearchParams(search);

  let keyValue = newParams.get(getProxyDetails.key);

  const originUrl = window.location.origin;

  const [proxyData, setProxyData] = useState<any>(null);

  const sessionData = sessionStorage.getItem(getProxyDetails.proxyAuthorizationData);
  const expirePage = PROXY_EXPIRED_URL;
  useEffect(() => {
    if (keyValue) {
      return;
    }
    if (sessionData) {
      const data = JSON.parse(atob(sessionData));
      setProxyData(data);
      if (data.CurrentStatus !== '2') {
        navigate(expirePage);
        return;
      }
    } else {
      navigate(expirePage);
    }
  }, []);

  const callBackFunction = (type: string) => {
    if (type === getProxyDetails.expiredMessage) {
      navigate(PROXY_EXPIRED_URL);
    }
  };

  return (
    <div>
      <ProxyHeader />
      <div className="proxy_form container proxy_form_disabled proxy_register_wrapper">
        <div data-testid="CSRProxy">
          <PageBox border={false}>
          <RegisterButton
              callBack={callBackFunction}
              optimaUrl={`${originUrl}/`}
              proxyData={proxyData}
              KEY={keyValue || ""}
              baseURL={process.env.REACT_APP_BASE_URL || ""}
              caregiverBaseURL={caregiverEndPoint}
              OcpApimSubscriptionKey={process.env.REACT_APP_SUBSCRIPTION_KEY || ""}
            />
          </PageBox>
        </div>
      </div>

      <footer className="mt-5">
        <FooterDisclaimer />
      </footer>
    </div>
  );
};

export default ProxyRegister;
