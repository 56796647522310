import { saveAs } from 'file-saver';



export const PDFConverter = (data: any, isFileEncode: boolean, fileName: string) => {
  let blob: Blob;
  // Convert the data to a blob
  if (isFileEncode) {
    const binary: string = atob(
     (data?.result ?? data)?.replace(/([^\r])\n/g, '$1\r\n')
    );
    const binaryLength: number = binary.length;
    const binaryBuffer: ArrayBuffer = new ArrayBuffer(binaryLength);
    const binaryView: Uint8Array = new Uint8Array(binaryBuffer);
    for (let i = 0; i < binaryLength; i++) {
      binaryView[i] = binary.charCodeAt(i);
    }
    // Create the blob object with content-type "application/pdf"
    blob = new Blob([binaryView], { type: 'application/pdf' });
  } else {
    blob = new Blob([data], { type: 'application/pdf' });
  }
  saveAs(blob, fileName);
  // Convert the blob to a data URL
  const url: string = URL.createObjectURL(blob);

  // Open the PDF in a new window
  window.open(url,'_blank');

  return blob;
};