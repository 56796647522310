import React from 'react';
import { useTranslation } from 'react-i18next';

function PasswordInfoOverlay() {
  const { t } = useTranslation();
  return (
    <div className="memoverlay-content">
      <h5
        className="memoverlay-title redhat_medium mb-2"
        data-testid="passwordTooltipHeader"
      >
        {t('activation.messages.passwordTooltipText')}
      </h5>
      <ul className="mb-2">
        <li data-testid="passwordTooltipHeaderOne">
          {t('activation.messages.passwordRequirementsOne')}
        </li>
        <li data-testid="passwordTooltipHeaderTwo">
          {t('activation.messages.passwordRequirementsSecond')}
        </li>
        <li data-testid="passwordTooltipHeaderThree">
          {t('activation.messages.passwordRequirementsThree')}
        </li>
        <li data-testid="passwordTooltipHeaderFour">
          {t('activation.messages.passwordRequirementsFour')}
        </li>
        <li data-testid="passwordTooltipHeaderFive">
          {t('activation.messages.passwordRequirementsFive')}
        </li>
      </ul>
      <p className="mb-2" data-testid="passwordTooltipHeaderSix">
        {t('activation.messages.passwordRequirementsSix')}
      </p>
    </div>
  );
}

export default PasswordInfoOverlay;
