import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuth } from './Auth'
import {signIn} from 'common'

interface RequireAuthProps {
  children : React.ReactNode;
}

export const RequireAuth = ({ children }: RequireAuthProps) => {
  const location = useLocation();
  const auth = useAuth();
  const from = location.pathname + location.search;

  if (!auth?.user) {
    let navigateTo = signIn;

    if (from !== '/') {
      navigateTo += `?redirect=${from}`;
    } else if (location.search.indexOf("proxy") !== -1) {
      navigateTo += location.search;
    }

    return <Navigate to={navigateTo} state={{ path: location.pathname }} />;
  }

  return <>{children}</>;
};