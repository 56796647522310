import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DocumentTable from './DocumentTable';

// Import specific components from external UI library
import {
  Button,
  Checkbox,
  ErrorMessage,
  Headings,
} from '@sentaraui/optimahealth_web';

export interface DocumentTableItem {
  document: string; // Replace with the actual type for 'document'
  electronically: boolean; // Replace with the actual type for 'electronically'
  usMail: boolean; // Replace with the actual type for 'usMail'
}

interface NotificationTableProps {
  checkSameAddress: (event: { target: { checked: boolean } }) => void;
  updatevalue: Array<any>; // You should replace `any` with more specific types;
  getDocumentValue: (value: DocumentTableItem, item: DocumentTableItem) => void;
  checkBoxError: boolean;
  termsAndConditionText: React.JSX.Element;
  checkboxValid: (event: { target: { checked: boolean } }) => void;
  variant: string;
  onAccountSaveChanges: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

// Define a functional component named NotificationTable
function NotificationTable({
  checkSameAddress,
  updatevalue,
  getDocumentValue,
  checkBoxError,
  termsAndConditionText,
  checkboxValid,
  variant,
  onAccountSaveChanges,
}: NotificationTableProps) {
  // Initialize translation hook
  const { t } = useTranslation();

  // Initialize navigation hook
  const navigate = useNavigate();

  // Render the component's JSX
  return (
    <div data-testid="notificationTitle">
      {/* Heading */}
      <Headings
        className="redhat_bold font-twentyfour fw-700 primary-color mb-4"
        level={2}
        text={t('settings.label.notificationTitle')}
      />

      {/* Connect message */}
      <div className="border border-clr p-3 p-lg-4 p-md-4 pb-0 mb-4">
        <div className="mb-3">{t('settings.message.connect')}</div>

        {/* Checkbox */}
        <div className="mb-4">
          <Checkbox
            id="allDependents"
            className="form-check-input mt-0"
            children={t('settings.label.allDependents')}
            type="checkbox"
            dataTestId="allDependents"
            onChange={checkSameAddress}
          />
        </div>

        {/* Delivery preferences */}
        <div data-testid="deliveryPreferences">
          <Headings
            className="redhat_bold font-eighteen primary-color fw-700 mb-4"
            level={2}
            text={t('settings.label.deliveryPreferences')}
          />
        </div>

        {/* Document table */}
        <DocumentTable
          updatevalue={updatevalue}
          getDocumentValue={getDocumentValue}
        />

        {/* Document disclaimer */}
        <div className="row mb-3">
          <div
            className="col-lg-9 col-md-12 col-sm-12 col-12"
            data-testid="documentDisclaimer"
          >
            <span className="redhat_bold pe-1">
              {t('settings.label.pleaseNote')}
            </span>
            {t('settings.message.documentDisclaimer')}
          </div>
        </div>

        {/* Checkbox for terms and conditions */}
        <div className="pos-rel">
          {checkBoxError && (
            <ErrorMessage
              dataTestId="acceptTerms"
              children={t('activation.errors.acceptTerms')}
            ></ErrorMessage>
          )}
          <div className="mb-4">
            <Checkbox
              id="termAndConditioncheck"
              className={
                checkBoxError
                  ? 'form-check-input mt-0 error-input-bdr'
                  : 'form-check-input mt-0'
              }
              children={termsAndConditionText}
              type="checkbox"
              dataTestId="termsandconditionCheckbox"
              onChange={checkboxValid}
            />
          </div>
        </div>

        {/* Save and cancel buttons */}
        <div className="row pt-1">
          <div className="col-lg-auto col-md-auto col-sm12 col-12 mb-4">
            <Button
              dataTestId={'saveChanges'}
              variant={variant}
              onClick={(e: any) => {
                e.preventDefault();
                onAccountSaveChanges(e);
              }}
            >{t('settings.label.saveChanges')}</Button>
          </div>
          <div className="col-lg-auto col-md-auto col-sm12 col-12 mb-4">
            <Button
              dataTestId={'cancelButton'}
              variant={'secondary_btn btn_block_mob'}
              onClick={(e: any) => {
                navigate('/dashboard');
              }}
            >{t('settings.label.cancel')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotificationTable;
