import React from 'react';
import { useTranslation } from 'react-i18next';
import {ProfileHeaderContent} from 'templates';

// Define the interface for the props passed to PasswordShowEmpty component
interface PasswordShowEmptyProps {
  isDisabled: boolean;
  handleChangeButton: () => void;
}

// Define the PasswordShowEmpty component
const PasswordShowEmpty: React.FC<PasswordShowEmptyProps> = ({
  isDisabled,
  handleChangeButton,
}) => {
  // Initialize localization using react-i18next
  const { t } = useTranslation();

  return (
    
    <>
      <ProfileHeaderContent
        header={t('settings.label.currentPassword')}
        contentOne={'*********'}
        dataTestIdOne="passwordShowInput"
        onClick={() => handleChangeButton()}
        showButton={true}
      />
    </>
  );
};

export default PasswordShowEmpty;
