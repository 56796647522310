import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceTypes, formatISODateToMDY, useDeviceType } from 'common';
import { useNavigate } from 'react-router-dom';
import { Accordion, Table, Button } from '@sentaraui/optimahealth_web';

// AuthorizationTable component
const AuthorizationTable: React.FC<any> = ({
  authorizationTable,
  setAuthorizationTable,
  memberId,
}) => {
  const { t } = useTranslation();
  const [sortField, setSortField] = useState('');
  const [order, setOrder] = useState('asc');
  const navigate = useNavigate();

  // Function to handle sorting change
  const handleSortingChange = (accessor: string) => {
    const sortOrder =
      accessor === sortField && order === 'asc' ? 'desc' : 'asc';
    setSortField(accessor);
    setOrder(sortOrder);
    handleSorting(accessor, sortOrder);
  };
  // Function to handle sorting of the authorization table
  const handleSorting = (sortField: string, sortOrder: string) => {
    if (sortField) {
      const sorted: Array<any> = [...authorizationTable].sort(
        (a: any, b: any) => {
          if (a[sortField] === null) {
            return 1;
          }
          if (b[sortField] === null) {
            return -1;
          }
          if (a[sortField] === null && b[sortField] === null) {
            return 0;
          }
          return (
            String(a[sortField]).localeCompare(String(b[sortField]), 'en', {
              numeric: true,
            }) * (sortOrder === 'asc' ? 1 : -1)
          );
        }
      );
      setAuthorizationTable(sorted);
    }
  };

  // Function to navigate to authorization details page
  const navigateToDetails = (item: { authorizationNumber: any }) => {
    setAuthorizationTable([]);
    navigate('/home/authorizations/authorizationDetails', {
      state: {
        memberId: memberId,
        authId: item.authorizationNumber,
      },
    });
  };
  const deviceType = useDeviceType();
  const isMobile = deviceType === DeviceTypes.MOBILE;

  const tableData = [
    {
      dataTestId: 'authNumberTab',
      button: {
        className:
          'sort-by white-color bg-transparent border-0 ps-0 m-0 not-print',
        onClick: () => handleSortingChange('authorizationNumber'),
        text: 'authorization.label.authorizationNumber',
      },
      span: {
        className: 'print-theader',
        text: 'authorization.label.authorizationNumber',
      },
    },
    {
      dataTestId: 'authEffectiveDateTab',
      button: {
        className:
          'sort-by white-color bg-transparent border-0 ps-0 m-0 not-print',
        onClick: () => handleSortingChange('effectiveDate'),
        text: 'authorization.label.effectiveDate',
      },
      span: {
        className: 'print-theader',
        text: 'authorization.label.effectiveDate',
      },
    },
    {
      dataTestId: 'authReferredToTab',
      button: {
        className:
          'sort-by white-color bg-transparent border-0 ps-0 m-0 not-print',
        onClick: () => handleSortingChange('referredToProviderName'),
        text: 'authorization.label.referredTo',
      },
      span: {
        className: 'print-theader',
        text: 'authorization.label.referredTo',
      },
    },
    {
      dataTestId: 'authRefferedByTab',
      button: {
        className:
          'sort-by white-color bg-transparent border-0 ps-0 m-0 not-print',
        onClick: () => handleSortingChange('referredBy'),
        text: 'authorization.label.referredBy',
      },
      span: {
        className: 'print-theader',
        text: 'authorization.label.referredBy',
      },
    },
    {
      dataTestId: 'authStatusTab',
      button: {
        className:
          'sort-by white-color bg-transparent border-0 ps-0 m-0 not-print',
        onClick: () => handleSortingChange('status'),
        text: 'authorization.label.status',
      },
      span: {
        className: 'print-theader',
        text: 'authorization.label.status',
      },
    },
  ];

  const jsonDataValue = () => {
    return (
      <>
        {tableData?.map((item) => (
          <th data-testid={item?.dataTestId} key={item?.dataTestId}>
            <Button
              variant={item?.button?.className}
              onClick={item?.button?.onClick}
            >
              {t(item?.button?.text)}
            </Button>
            <span aria-hidden="true" className={item?.span?.className}>
              {t(item?.span?.text)}
            </span>
          </th>
        ))}
      </>
    );
  };

  return (
    <>
      {!isMobile && (
        <div className="overflow-scroll">
          <Table
            className={
              'table table-responsive table-custom-style authorization-table black-color font-fourteen mb-4 print'
            }
            headerChildren={jsonDataValue()}
            rowsChildren={
              authorizationTable?.length > 0 ? (
                authorizationTable.map((item: any, i: any) => {
                  return (
                    <tr key={item.mId}>
                      <td
                        className="radio-center"
                        data-testid={`authorizationNumber${i}`}
                      >
                        <Button
                          variant="darkblue-color bg-transparent border-0 text-decoration-underline"
                          onClick={(e: { preventDefault: () => void }) => {
                            e.preventDefault();
                            navigateToDetails(item);
                          }}
                          data-testid={`authorizationNumber_${i}`}
                        >
                          {item.authorizationNumber}
                        </Button>
                      </td>

                      <td
                        className="radio-center"
                        data-testid={`effectiveDate${i}`}
                      >
                        {formatISODateToMDY(item.effectiveDate)}
                      </td>
                      <td
                        className="radio-center"
                        data-testid={`referredToProviderName${i}`}
                      >
                        {item.referredToProviderName}
                      </td>
                      <td
                        className="radio-center"
                        data-testid={`referredBy${i}`}
                      >
                        {item.referredBy}
                      </td>
                      <td className="radio-center" data-testid={`status${i}`}>
                        {item.status}
                      </td>
                    </tr>
                  );
                })) : 
                <tr>
                  <td colSpan={5} className="text-center">
                    {t('noResults')}
                  </td>
                </tr>  
            }
          >
            
          </Table>
        </div>
      )}
      {isMobile && (
        <>
          {authorizationTable?.length > 0 ? (
            <div className="accordion-table mb-4">
              <Accordion
                initialActiveIndex={0}
                items={
                  authorizationTable?.length > 0 &&
                  authorizationTable?.map((item: any, i: any) => ({
                    title: (
                      <div>
                        <div data-testid={`authNumberTab${i}`}>
                          <span className="font-sixteen">
                            {t('authorization.label.authorizationNumber')}
                          </span>
                        </div>
                        <div data-testid={`authorizationNumber${i}`}>
                          <Button
                            variant="darkblue-color bg-transparent border-0 text-decoration-underline"
                            onClick={(e: { preventDefault: () => void }) => {
                              e.preventDefault();
                              navigateToDetails(item);
                            }}
                            data-testid={`authorizationNumber_${i}`}
                          >
                            {item.authorizationNumber}
                          </Button>
                        </div>
                      </div>
                    ),
                    content: (
                      <div>
                        <div
                          data-testid={`authEffectiveDateTab${i}`}
                          className="redhat_bold"
                        >
                          {t('authorization.label.effectiveDate')}
                        </div>
                        <div data-testid={`effectiveDate${i}`} className="mb-3">
                          {formatISODateToMDY(item.effectiveDate)}
                        </div>
                        <div
                          data-testid={`authReferredToTab${i}`}
                          className="redhat_bold"
                        >
                          {t('authorization.label.referredTo')}
                        </div>
                        <div
                          data-testid={`referredToProviderName${i}`}
                          className="mb-3"
                        >
                          {item.referredToProviderName}
                        </div>
                        <div
                          data-testid={`authRefferedByTab${i}`}
                          className="redhat_bold"
                        >
                          {t('authorization.label.referredBy')}
                        </div>
                        <div data-testid={`referredBy${i}`} className="mb-3">
                          {item.referredBy}
                        </div>
                        <div
                          data-testid={`authStatusTab${i}`}
                          className="redhat_bold"
                        >
                          {t('authorization.label.status')}
                        </div>
                        <div data-testid={`status${i}`} className="mb-3">
                          {item.status}
                        </div>
                      </div>
                    ),
                  }))
                }
              />
            </div>
          ) : (
            <div className="mb-4">
              <div className="text-center">{t('noResults')}</div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default AuthorizationTable;