import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMySSOUrl } from '@sentara/sentara-api-hooks-core';
import { RightArrowIcon, errorhandler } from 'common';
import { Headings, Button, Paragraph } from '@sentaraui/optimahealth_web';
import { GlobalContext } from 'context';

function PharmacyClaims() {
  //  Initialize useTranslation hook
  const { t } = useTranslation();
  // Initialize SSO URL hook
  const { SSOUrlType } = useMySSOUrl();
  const { getFeature } = useContext(GlobalContext);

  // Function to get and open the API URL in a new tab
  const getAPIUrl = async () => {
    let data = getFeature?.PharmacyBenefitsManager  ? t('claims.message.PharmacyBenefitsManager'): t('claims.message.OptumRX');
       const response = await SSOUrlType(data); // Get SSO URL from API
      if (response && response?.data?.ssoUrl) {
        window.open(response.data.ssoUrl, '_blank'); // Open SSO URL in a new tab
      } else if (response?.errorCode) {
        errorhandler(response?.errorCode);
      }
    
  };

  return (
    <>
      <div className="heading-one-semibold mb-4">
        <Headings
          level={1}
          text={t('navigation.label.pharmacy')}
          dataTestId="pharmacyHeader"
        />
      </div>
      <div className="pharmacy_claims_wrapper mb-4">
      <Paragraph
          className="fw-500"
          data-testid="pharmacyClaimsText"
        >{t('claims.message.pharmacyMsg')}</Paragraph>
        <div className="d-flex align-items-center">
        <Button
            dataTestId={'continueNavigate'}
            onClick={() => {
              getAPIUrl();
            }}
            variant="link-text btn-links"
          >{t('activation.label.continue')}</Button>
          <span className='green-color-stroke' aria-hidden="true">
            <RightArrowIcon />
          </span>
        </div>
      </div>
    </>
  );
}

export default PharmacyClaims;
