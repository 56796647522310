import React, { useState, useEffect, useContext } from 'react';
import { t } from 'i18next';
import {
  Button,
  Input,
  Label,
  Paragraph,
  Checkbox,
  ErrorMessage,
  Loader,
  ModalOverlay,
  Headings,
  Link,
} from '@sentaraui/optimahealth_web';
import { ProfileInformations, PaperlessConsent } from 'templates';
import {
  errorhandler,
  sentaraHealthPlans
} from 'common';
import { useSaveEmail } from '@sentara/sentara-api-hooks-core';
import { GlobalContext } from '../../context';

interface PaperlessCommunicationsProps {
  onClickCancel: React.MouseEventHandler<HTMLButtonElement>;
  handleSuccess: (paperless: string) => void;
  state: ProfileInformations;
  setState: React.Dispatch<React.SetStateAction<ProfileInformations>>;
}

function PaperlessCommunications({
  onClickCancel,
  handleSuccess,
  state,
  setState,
}: PaperlessCommunicationsProps) {
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(
    state?.paperlessConsent?.optInConsent || false
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [paperlessConsentDetails, setPaperlessConsentDetails] =
    useState<PaperlessConsent>({} as PaperlessConsent);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [confirmSignUp, setConfirmSignUp] = useState<boolean>(false);
  const [showErrormessage, setShowErrorMessage] = useState<boolean>(false);
  const [checkboxValue, setCheckboxValue] = useState(
    state?.paperlessConsent?.optInConsent || false
  );

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxValue(event.target.checked);
    setShowErrorMessage(event.target.checked ? false : true);
  };

  const { getFeature } = useContext(GlobalContext);
  const { saveEmail } = useSaveEmail();
  useEffect(() => {
    setPaperlessConsentDetails(state?.paperlessConsent as PaperlessConsent ?? {optInConsent: false});
  }, [state]);

  const YesForReceiveTextMsg = (
    <>
      <strong>{t('account.label.buttonLableOne')}</strong>,{' '}
      {t('settings.label.wantPaperlessThisTime')}
    </>
  );
  const NoForReceiveTextMsg = (
    <>
      <strong>{t('account.label.buttonLableTwo')}</strong>,{' '}
      {t('settings.label.notPaperlessThisTime')}
    </>
  );

  const EOBListContent = (
    <>
      {t('setMyPreferences.description.paperlessIncludes')}{' '}
      <strong>{t('setMyPreferences.description.paperlessIncludesBold')}</strong>{' '}
      {t('setMyPreferences.description.paperlessAvailable')}      
    </>
  );

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const bottom =
      Math.round(e.currentTarget.scrollHeight - e.currentTarget.scrollTop) ===
      e.currentTarget.clientHeight;
    if (bottom) {
      setIsScrolledToBottom(true);
    }
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!checkboxValue && paperlessConsentDetails?.optInConsent) {
      setShowErrorMessage(true);
    } else {
      handleEmailConsent(
        event as unknown as React.MouseEvent<HTMLButtonElement>
      );
    }
  };

  const handleEmailConsent = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setLoading(true);
    const paperlessConsent = {
      optInConsent: paperlessConsentDetails?.optInConsent,
    };
    const response = await saveEmail(
      undefined,
      getFeature?.AccountSettings,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      paperlessConsent
    );
    if (response?.errorCode) {
      errorhandler(response?.errorCode);
    } else {
      if (paperlessConsentDetails?.optInConsent) {
        setConfirmSignUp(true);
      } else {
        closeConfirmSignUp(event);
      }
    }
    setLoading(false);
  };

  //To close the email update confirmation modelup
  function cancelEmailAlert() {
    setShowConfirmModal(false);
  }

  //To close the email paperless confirmation modelup and to display the success message
  function closeConfirmSignUp(e: React.MouseEvent<HTMLButtonElement>) {
    setConfirmSignUp(false);
    onClickCancel(e);
    setState({ ...state, paperlessConsent: paperlessConsentDetails });
  }

  return (
    <>
      {loading && <Loader />}
      <form onSubmit={onSubmit}>
        <div className="row">
          <Paragraph className={'mb-1'} children={EOBListContent} />

          <ul className="pref_choose_options d-flex mb-2  row">
            <li className="col-md-5 col-12">
              <div className="d-flex">
                <Input
                  id="emailNotificationYes"
                  className="form-check-input mt-0"
                  children={YesForReceiveTextMsg}
                  name="emailNotification"
                  type="radio"
                  dataTestId="emailNotificationYes"
                  checked={paperlessConsentDetails?.optInConsent}
                  onChange={() => {
                    setPaperlessConsentDetails({ optInConsent: true });
                    setCheckboxValue(false);
                    setShowErrorMessage(false);
                  }}
                />
                <Label
                  htmlFor="emailNotificationYes"
                  className="form-check-label pad_left_12 check-label-pad"
                  children={YesForReceiveTextMsg}
                />
              </div>
              <Paragraph
                className="font_12_normal"
                children={t('yesRadioContent')}
              />
            </li>
            <li className="col-md-6 col-12">
              <div className="d-flex">
                <Input
                  id="emailNotificationNo"
                  className="form-check-input mt-0"
                  children={NoForReceiveTextMsg}
                  name="emailNotification"
                  type="radio"
                  dataTestId="emailNotificationNo"
                  checked={!paperlessConsentDetails?.optInConsent}
                  onChange={() => {
                    setPaperlessConsentDetails({ optInConsent: false });
                    setShowConfirmModal(true);
                    setShowErrorMessage(false);
                  }}
                />
                <Label
                  htmlFor="phoneNotificationNo"
                  className="form-check-label pad_left_12 check-label-pad"
                  children={NoForReceiveTextMsg}
                />
              </div>
            </li>
          </ul>
        </div>

        {paperlessConsentDetails?.optInConsent ? (
          <>
            <Paragraph
              children={t('settings.label.paperlessAcknowledgement')}
            />
            <div
              className="phone_notify_msg_wrapper notification_border"
              data-testid="peperLessTermsandCondition"
              onScroll={handleScroll}
            >
              <div>
                <Paragraph className="mb-0">
                  {t('settings.label.paperlessTextarea')} {''}
                  <Link to={sentaraHealthPlans}>
                    ({t('settings.label.paperlessTextarea2')})
                  </Link>{' '}
                  {''}
                  {t('settings.label.paperlessTextarea3')}
                </Paragraph>
                <Paragraph className="mb-0">
                  {t('settings.label.paperlessTextarea4')}
                </Paragraph>
              </div>
            </div>

            <div className="d-flex pb-2 mt-3 pt-2">
              <div className="pos-rel">
                <Checkbox
                  id="termsAndCondition"
                  checked={checkboxValue}
                  className={`form-check-input mt-0 ${
                    showErrormessage ? 'error-input-bdr' : ''
                  }`}
                  children={t('settings.label.termsOfUse')}
                  onChange={handleCheckboxChange}
                  disabled={!isScrolledToBottom}
                  type="checkbox"
                  dataTestId="termsAndCondition"
                />
                {showErrormessage && (
                  <ErrorMessage
                    className="mb-0"
                    children={t('setMyPreferences.error.goPaperConsent')}
                  ></ErrorMessage>
                )}
              </div>
            </div>
          </>
        ) : null}

        <hr />
        <div className="pt-2 button_container">
          <Button
            dataTestId={'saveButton'}
            children={t('save')}
            variant={
              checkboxValue || !paperlessConsentDetails?.optInConsent
                ? 'primary_btn btn_block_mob'
                : 'primary_btn btn_block_mob disabled_btn'
            }
            type={'submit'}
          />
          <Button
            type="button"
            dataTestId={'cancelButtonPaperless'}
            children={t('activation.label.cancel')}
            variant={'secondary_btn btn_block_mob'}
            onClick={onClickCancel}
          />
        </div>
      </form>
      {showConfirmModal && (
        <ModalOverlay
          isOpen={showConfirmModal}
          onClose={cancelEmailAlert}
          overlayInfo="Modal information"
        >
          <div data-testid="amSurePopUp">
            <Headings level={2} text={t('settings.label.amSurePopUpTitle')} />
            {/* Description paragraphs */}
            <Paragraph className="mb-4 pb-2">
              {t('settings.label.iamSureContent')}
            </Paragraph>
            <div className="d-flex flex-lg-row flex-md-row flex-column gap-lg-3 gap-md-3 gap-4 mb-2">
              <Button
                dataTestId={'imSureButton'}
                children={t('settings.label.phoneAcknowledgementPopUpImSure')}
                variant={'primary_btn btn_block_mob'}
                type="submit"
                onClick={() => {
                  cancelEmailAlert();
                  setCheckboxValue(true);
                }}
              />
              <Button
                dataTestId={'signupButton'}
                children={t('settings.label.phoneAcknowledgementPopUpSignup')}
                variant={'secondary_btn'}
                onClick={() => {
                  setShowConfirmModal(false);
                  setPaperlessConsentDetails({ optInConsent: true });
                  setShowErrorMessage(false);
                }}
              />
            </div>
          </div>
        </ModalOverlay>
      )}
      {confirmSignUp && (
        <ModalOverlay
          isOpen={confirmSignUp}
          onClose={(e: React.MouseEvent<HTMLButtonElement>) => {
            closeConfirmSignUp(e);
          }}
          overlayInfo="Modal information"
        >
          <div data-testid="emailConfirmPopUp">
            <Headings level={2} text={t('settings.label.emailconfirm')} />
            {/* Description paragraphs */}
            <Paragraph className="mb-4 pb-2">
              {t('settings.label.checkMail')}
            </Paragraph>
            <div className="d-flex flex-lg-row flex-md-row flex-column gap-lg-3 gap-md-3 gap-4 mb-2">
              <Button
                dataTestId={'okButton'}
                children={t('settings.label.ok')}
                variant={'primary_btn btn_block_mob'}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  closeConfirmSignUp(e);
                }}
              />
            </div>
          </div>
        </ModalOverlay>
      )}
    </>
  );
}

export default PaperlessCommunications;
