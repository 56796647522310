export const ModalCustomStyle: ReactModal.Styles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 10,
  },
  content: {
    position: 'absolute',
    top: '40px',
    left: '40px',
    right: '40px',
    bottom: '40px',
    border: '0',
    background: 'transparent',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '0',
    outline: 'none',
    padding: '15px',
    inset: '0',
    display: 'flex',
  },
};

export const values = [
  {
    id: '1',
    name: 'Medicaid',
  },
  {
    id: '2',
    name: 'Medicare',
  },
  {
    id: '3',
    name: 'Employer Group/ Individual Product',
  },
  {
    id: '4',
    name: 'I don’t know ',
  },
];


export const ModalOverlayStyle={
    overlay: {
      backgroundColor: 'rgba(0,0,0,.5)',
      zIndex: 10,
    },
    content: {
      opacity: 1,
      backgroundColor: 'transparent',
      border: 0,
      inset: 0,
      padding: '0 0 0 20px',
      borderRadius: 0,
    },
}


